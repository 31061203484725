import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import _ from 'lodash';

export class AuthenticationService {
  public static getInstance() {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  private static instance: AuthenticationService;

  public userManager: UserManager;

  private constructor() {
    Log.logger = window.console;
    Log.level = Log.DEBUG;
    const settings = this.getSettings(undefined);
    this.handleSignout = this.handleSignout.bind(this);
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserSignedOut(this.handleSignout);
    this.userManager.events.addUserLoaded(this.handleUserLoaded);
    this.userManager.clearStaleState();
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect().then(() => {
      _.forIn(window.localStorage, (value: string, objKey: string) => {
        if (_.startsWith(objKey, 'oidc.')) {
          window.localStorage.removeItem(objKey);
        }
      });
    });
  }

  public forceLogin(): Promise<void> {
    const settings = this.getSettings('login');
    const um = new UserManager(settings);
    return um.signinRedirect();
  }

  private handleSignout() {
    if (localStorage.getItem('refreshToken') !== null) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.logout();
    }
  }

  private handleUserLoaded() {
    const authService = AuthenticationService.getInstance();
    authService.getUser().then((user) => {
      if (user != null) {
        const accessToken = 'Bearer ' + user.access_token;
        localStorage.setItem('accessToken', accessToken);
      }
    });
  }

  private getSettings(prompt: string | undefined): UserManagerSettings {
    const stitchUrl: string = process.env.REACT_APP_STITCH_URL ? process.env.REACT_APP_STITCH_URL : "";
    const settings: UserManagerSettings = {
      authority: stitchUrl,
      client_id: "ThreadWebLegacy",
      redirect_uri: window.location.origin + `/signin-callback.html`,
      silent_redirect_uri: window.location.origin + `/silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: window.location.origin + '/index.html',
      response_type: 'id_token token',
      scope: "openid profile ThreadApi",
      automaticSilentRenew: true,
      monitorSession: true,
      userStore: new WebStorageStateStore({ prefix: `oidc.${prompt || 'main'}`, store: window.localStorage }),
      prompt,
      revokeAccessTokenOnSignout: true
    };
    return settings;
  }
}
