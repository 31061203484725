import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';

import { AuthenticationService } from '../Security/AuthenticationService';

import { Button, Card, Row, Input, Heading } from '@raudabaugh/thread-ui'

import './Login.css';
import { Divider } from 'antd';

export default class Login extends React.PureComponent {
  static propTypes = {
    accessToken: PropTypes.string,
    setAuthTokens: PropTypes.func.isRequired
  };
  login = () => {

    this.setState({
      isLoginButtonDisabled: true
    });
    Utils.axios(Endpoints().auth, {
      data: {
        provider: "password",
        data: this.state.login.toLowerCase(),
        user_info: {
          register: false,
          password: this.state.password
        }
      },
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      return Utils.axios(Endpoints().auth, {
        data: {
          provider: 'realm',
          data: response.data.refresh_token.token,
          path: `/${response.data.refresh_token.token_data.identity}/beta`
        },
        cancelToken: this.cancelTokenSource.token
      });
    }).then((response) => {
      var request = JSON.parse(response.config.data);
      var refreshToken = request.data;
      var accessToken = response.data.access_token.token;
      var realmPath = request.path.replace(/\//g, '%2f');
      window.Intercom('update', {
        hide_default_launcher: false,
      })
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('realmPath', realmPath);
      localStorage.setItem('userName', '');
      localStorage.setItem('userAvatar', '');
      localStorage.setItem('userId', '');

      this.props.setAuthTokens(refreshToken, accessToken, realmPath, '', '', '');

      var redirectPath = !_.isEmpty(this.props.location.state) && !_.isEmpty(this.props.location.state.from)
        ? this.props.location.state.from : { pathname: '/' };

      this.setState({
        errorText: '',
        redirectPath: redirectPath
      });
    }).catch((error) => {
      Utils.handleAxiosError('Login', error).then((errorMessage) => {
        this.setState({
          errorText: errorMessage,
          isLoginButtonDisabled: false,
          stitchAccessToken: null,
          orgId: null
        });
        this.props.removeAuthTokens();
      }).catch(() => {
      });
      localStorage.removeItem('authProvider');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('realmPath');
      localStorage.removeItem('userName');
      localStorage.removeItem('userAvatar');
      localStorage.removeItem('userId');
    });
  };

  loginWithStitch = () => {
    window.location.href = process.env.REACT_APP_THREADWEB_URL;
  }

  handleLoginChange = (event) => {
    this.setState({
      login: event.target.value
    })
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value
    })
  };

  handleEnterKey = (event) => {
    if (this.isLoginFormEmpty() || this.state.isLoginButtonDisabled) {
      return true; // invalid form state
    }
    this.login();
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      stitchAccessToken: null,
      orgId: null,
      redirectPath: null,
      errorText: '',
      isLoginButtonDisabled: false
    };
    this.cancelTokenSource = axios.CancelToken.source();
  }

  componentWillReceiveProps() {
    this.setState({
      redirectPath: null
    })
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel();
  }

  isLoginFormEmpty() {
    return _.isEmpty(this.state.login) || _.isEmpty(this.state.password);
  }

  render() {
    if (this.state.redirectPath != null) {
      return <Redirect to={this.state.redirectPath} />
    }
      
    return (<div id="app">
      <div id="login-form">
        <Card bordered={false} style={{ width: 320 }}>
          <div className="input-fields-wrapper">
            <img alt="logo" src={'images/Logo.svg'} />
          </div>
          <form>
            <Heading level={3} margin="16px 0 0 10px">Use My Team Login</Heading>
            <Input
              className="input-field"
              onPressEnter={this.handleEnterKey}
              placeholder={`Team Name`}
              value={this.state.login}
              onChange={this.handleLoginChange}
            />
            <Input
              className="input-field"
              type="password"
              onPressEnter={this.handleEnterKey}
              placeholder={`Password`}
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
            <div className="error-message">
              {this.state.errorText}
            </div>
            <Row>
              <Button
                className="login-button"
                disabled={this.isLoginFormEmpty() || this.state.isLoginButtonDisabled}
                type="primary"
                onClick={() => this.login()}
              >
                Sign In
              </Button>
            </Row>
            <Divider>Or</Divider>
            <Row>
              <Button className="login-button"
                type="primary"
                disabled={this.state.isLoginButtonDisabled}
                onClick={this.loginWithStitch.bind(this)}>Use My Email Login</Button>
            </Row>
          </form>
        </Card>
      </div>
    </div>
    );
  }
}
