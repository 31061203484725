import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import {
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import appReducer from '../../reducers';

import AuthContainer from '../AuthContainer';

import './App.css';

var store = createStore(appReducer);

var theme = createMuiTheme({
  palette: {
    primary: blue
  }
});

export default function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <AuthContainer/>
      </MuiThemeProvider>
    </Provider>
  );
}
