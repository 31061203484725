class SVGPointUtils {
  static getOutRectLeftCoords(x, y, indent) {
    return `${x},${y - indent} ${x},${y + indent} ${x - indent},${y}`;
  }

  static getOutRectRightCoords(x, y, indent) {
    return `${x},${y - indent} ${x},${y + indent} ${x + indent},${y}`;
  }

  static getTriangleLeftCoords(x, y, indent) {
    return `${x - 0.5},${y - indent} ${x - 0.5},${y + indent} ${x - indent - 0.5},${y}`;
  }

  static getTriangleRightCoords(x, y, indent) {
    return `${x + 0.5},${y - indent} ${x + 0.5},${y + indent} ${x + indent + 0.5},${y}`;
  }
}

export default SVGPointUtils;