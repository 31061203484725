import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import Utils from '../../Utils/Utils';

import './StudentHeader.css';

StudentHeader.propTypes = {
  student: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string
  }),
  leftBottomNode: PropTypes.node.isRequired,
  onLeftBottomClick: PropTypes.func,
  rightTopNode: PropTypes.node,
  rightTopFreeText: PropTypes.bool,
  rightBottomNode: PropTypes.node
};

export default function StudentHeader(props) {
  var rightTopClasses = classNames('top', {
    'ellipsis-overflow': props.rightTopFreeText
  });

  var studentInitials = props.student != null ? Utils.getInitials(props.student.name) : '';

  return (
    <div className="student-header">
      {
        props.student.avatar != null ? (
          <img className="avatar"
            src={props.student.avatar}
            alt="Student's avatar" />
        ) : (
            <Avatar className="avatar">{studentInitials}</Avatar>
          )
      }
      <div className="description">
        <div className="profile-text">
          <div className="top ellipsis-overflow">{props.student != null ? props.student.name || '' : ''}</div>
          <div className="bottom ellipsis-overflow">
            <a className="bottom-wrapper" onClick={props.onLeftBottomClick}>
              {
                props.leftBottomNode !== '' ? (
                  <>
                    {props.leftBottomNode}
                    < KeyboardArrowRightIcon className="to-details" />
                  </>
                ) : (<div />)
              }
            </a>
          </div>
        </div>
        <div className="info">
          <div className={rightTopClasses}>{props.rightTopNode}</div>
          <div className="bottom ellipsis-overflow">
            {props.rightBottomNode}
          </div>
        </div>
      </div>
    </div>
  );
}
