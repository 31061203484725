export const getInitials = (name: string | null) => {
    if (name != null) {
        const parts = name.split(" ");
        let initials = "";
        for (const part of parts) {
            initials += part.substring(0, 1);
        }
        return initials;
    }
    else {
        return null;
    }
}