import * as React from 'react'
import { AuthenticationService } from './AuthenticationService'

export interface IRenewCallbackProps {
    onSuccess?: () => void
    onError?: (err: any) => void
}
export class RenewCallback extends React.Component<IRenewCallbackProps> {
    public componentDidMount() {
        const { onSuccess, onError } = this.props
        const authService = AuthenticationService.getInstance();

        authService.userManager.signinSilentCallback()
            .then(() => {
                if (onSuccess) {
                    onSuccess()
                }
            })
            .catch(err => {
                if (onError) {
                    onError(err)
                }
            })
    }
    public render() {
        return null;
    }
}
