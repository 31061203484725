import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';

import Error from '../Error/Error';
import StudentProfileView from './StudentProfileView';

import ReduxHelpers from '../../Utils/ReduxHelpers';
import Constants from '../../Utils/Constants';
import Utils from '../../Utils/Utils';
import { Endpoints } from '../../Utils/Endpoints';

class StudentProfile extends React.PureComponent {
  static propTypes = Constants.secureComponentPropTypes;

  init = () => {
    Utils.axios(Endpoints().studentPrograms, {
      urlParams: {
        studentId: this.props.match.params.studentId
      },
      accessToken: localStorage['accessToken'],
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      var programs = response.data.programs.map((program) => Object.assign({}, program, {
        lastRun: program.lastRun != null ? new Date(program.lastRun) : null
      }));

      this.setState({
        student: response.data.student,
        programs: programs,
        inProgress: false,
        errorMessage: null
      });
    }).catch((error) => {
      Utils.handleAxiosError('StudentProfile', error, this.props.removeAccessToken, this.props.removeAuthTokens)
        .then((errorMessage) => {
          this.setState({
            inProgress: false,
            errorMessage: errorMessage
          })
        }).catch(() => {
        });
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      student: null,
      programs: null,
      inProgress: true,
      errorMessage: null
    };
    this.cancelTokenSource = axios.CancelToken.source();
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel();
  }

  render() {
    return this.state.inProgress ? (
      <CircularProgress className="progress-bar"
        color="primary"
        size={80} />
    ) : this.state.errorMessage ? (
      <Error message={this.state.errorMessage} />
    ) : (
          <StudentProfileView student={this.state.student}
            programs={this.state.programs}
            initFunc={this.init}
            studentId={this.props.match.params.studentId} />
        );
  }
}

export default connect(ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(StudentProfile);