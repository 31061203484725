import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  Paper
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRightRounded';


import ReduxHelpers from '../../../Utils/ReduxHelpers';
import { Endpoints } from '../../../Utils/Endpoints';
import Utils from '../../../Utils/Utils';
import PointUtils from '../Utils';

import './ChartPointPopup.css';

class ChartPointPopup extends React.PureComponent {
  static propTypes = {
    chartType: PropTypes.oneOf(['default', 'task analysis', 'frequency', 'duration']).isRequired,
    position: PropTypes.exact({
      display: PropTypes.string.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired
    }).isRequired,
    data: PropTypes.shape({
      id: Utils.nonEmptyStringPropValidator,
      x: PropTypes.instanceOf(Date).isRequired,
      y: PropTypes.oneOfType([
        PropTypes.exact({
          numerator: PropTypes.number.isRequired,
          denominator: PropTypes.number.isRequired
        }),
        PropTypes.number
      ]).isRequired,
      studentId: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['pretest', 'posttest', 'probe', 'standard']).isRequired,
      note: PropTypes.string,
      creatorName: PropTypes.string,
      recType: PropTypes.string
    }).isRequired,
    updateParent: PropTypes.func.isRequired
  };

  enableNoteEditMode = (event) => {
    event.stopPropagation();
    this.setState({
      noteEditModeOn: true
    });
  };

  handlePopupClick = (event) => {
    event.stopPropagation();

    var state = {
      noteEditModeOn: false
    };

    if (this.state.note !== this.state.previousNote) {
      Object.assign(state, {
        previousNote: this.state.note
      });
      this.saveNote();
    }

    this.setState(state);
  };

  handleNoteChange = (event) => {
    this.setState({
      note: event.target.value
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      noteEditModeOn: false,
      previousNote: props.data.note || '',
      note: props.data.note || ''
    };
    this.cancelTokenSource = axios.CancelToken.source();
  }

  componentWillReceiveProps(nextProps) {
    var state = {
      previousNote: nextProps.data.note || ''
    };
    if (!this.state.noteEditModeOn) {
      Object.assign(state, {
        note: state.previousNote
      });
    }
    this.setState(state);
  }

  componentWillUnmount() {
    if (this.state.note !== this.state.previousNote) {
      this.saveNote();
    }
  }

  /**
   * @todo @bug If a user leaves the Student Progress page, and saving request is not finished, we'll get an
   * "unable to setState to unmounted component" error. Most likely, this error takes place for `EditProgramDialog`
   * component and download file buttons too. To mimic a slow requests, wrap axios call with
   * `setTimeout(() => {}, 5000)`. [aLuk 3.Sep.18]
   */
  saveNote() {
    this.cancelTokenSource.cancel();
    this.cancelTokenSource = axios.CancelToken.source();

    var body = {
      note: this.state.note.trim() !== '' ? this.state.note : null
    };
    if (this.props.chartType !== 'frequency' && this.props.chartType !== 'duration') {
      Object.assign(body, {
        type: 'default',
        programRunId: this.props.data.id
      });
    } else {
      Object.assign(body, {
        type: 'behavior',
        behaviorPhaseId: this.props.data.id,
        date: this.props.data.x
      });
    }

    Utils.axios(Endpoints().note, {
      data: body,
      urlParams: {
        studentId: this.props.studentId
      },
      accessToken: localStorage['accessToken'],
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      if (response.data.success) {
        this.props.updateParent(false);
      }
    }).catch((error) => {
      Utils.handleAxiosError('ChartPointPopup', error, this.props.removeAccessToken,
        this.props.removeAuthTokens).catch(() => {
        });
    });
  }

  /**
   * @todo Consider using dynamic height for this popup. If there is no note, we can display popup with empty note area.
   * If there is a note or we add it using textarea, we can dynamically stretch note area unless it achieve maximum
   * height. To make it work we need to adjust popup position calculation algorithm in `StudentProgress` component.
   * [aLuk 4.Nov.17]
   */
  render() {
    var completedAtTime = this.props.data.x.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });

    var completedAtDate = `${
      this.props.data.x.toLocaleDateString('en-US', { month: 'short' })
      }. ${this.props.data.x.getDate()}, ${this.props.data.x.getFullYear()}`;

    var isBehaviorChart = this.props.chartType === 'frequency' || this.props.chartType === 'duration';

    return (
      <Paper id="progress-chart-point-popup"
        style={this.props.position}
        onClick={this.handlePopupClick}>
        {
          isBehaviorChart ? (
            <div className="header-block">
              <div className="bottom">
                {this.props.chartType === 'duration' ? Utils.secondsToTimeString(this.props.data.y) : this.props.data.y} Total
              </div>
            </div>
          ) : (
              <div className="header-block">
                <div className="top">{this.props.data.y.numerator}/{this.props.data.y.denominator} Trials Correct</div>
                <div className="bottom">
                  {Math.round(this.props.data.y.numerator / this.props.data.y.denominator * 100)}% Correct
              </div>
              </div>
            )
        }
        {
          !isBehaviorChart ? (
            <div className="row-wrapper">
              <div className="row">
                <div className="left">
                  <div className="top">{this.props.data.creatorName || ''}</div>
                  <div className="bottom">{this.props.data.recType || ''}</div>
                </div>
                <div className="right">
                  <div className="top">{completedAtTime}</div>
                  <div className="bottom">{completedAtDate}</div>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          !isBehaviorChart ? (
            <div className="row single-row">
              <div className="left">Data type</div>
              <div className="right">
                <span className="right-inner">
                  {PointUtils.getPagePointShape(this.props.data.type, this.props.data.note)}
                  {/* <KeyboardArrowRightIcon className="to-details" /> */}
                </span>
              </div>
            </div>
          ) : null
        }
        {
          !isBehaviorChart ? (
            <div className="row single-row">
              <div className="left">Score</div>
              <div className="right">
                <div className="right-inner">
                  <span className="label">{this.props.data.y.numerator}/{this.props.data.y.denominator}</span>
                  {/* <KeyboardArrowRightIcon className="to-details" /> */}
                </div>
              </div>
            </div>
          ) : null
        }
        {/* <div className="row">
          <Button color="primary"
            fullWidth
            variant="raised">Delete</Button>
        </div> */}
        <div className="row single-row">
          <div className="left">Notes</div>
          <div className="right">
            <span className="right-inner" onClick={this.enableNoteEditMode}>
              <span className="label">Add</span>
              <KeyboardArrowRightIcon className="to-details" />
            </span>
          </div>
        </div>
        <div className="row note-wrapper">
          <div className="note">
            {
              this.state.noteEditModeOn ? (
                <textarea className="note-textarea"
                  value={this.state.note}
                  onChange={this.handleNoteChange}
                  onClick={handleNoteTextareaClick} />
              ) : (
                  <span>{this.state.note}</span>
                )
            }
          </div>
        </div>
      </Paper>
    );
  }
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function handleNoteTextareaClick(event) {
  event.stopPropagation();
}

export default connect(ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(ChartPointPopup);
