import PropTypes from 'prop-types';

export default {
  secureComponentPropTypes: {
    accessToken: PropTypes.string.isRequired,
    realmPath: PropTypes.string.isRequired,
    removeAccessToken: PropTypes.func.isRequired,
    removeAuthTokens: PropTypes.func.isRequired
  },

  durationChartLabels: {
    hour: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h'],
    halfAnHour: ['0h', '0.5h', '1h', '1.5h', '2h', '2.5h', '3h', '3.5h', '4h', '4.5h', '5h'],
    tenMinutes: ['0m', '10m', '20m', '30m', '40m', '50m', '60m', '70m', '80m', '90m', '100m'],
    fiveMinutes: ['0m', '5m', '10m', '15m', '20m', '25m', '30m', '35m', '40m', '45m', '50m'],
    minute: ['0m', '1m', '2m', '3m', '4m', '5m', '6m', '7m', '8m', '9m', '10m'],
    halfAnMinute: ['0m', '0.5m', '1m', '1.5m', '2m', '2.5m', '3m', '3.5m', '4m', '4.5m', '5m'],
    tenSeconds: ['0s', '10s', '20s', '30s', '40s', '50s', '60s', '70s', '80s', '90s', '100s'],
    fiveSeconds: ['0s', '5s', '10s', '15s', '20s', '25s', '30s', '35s', '40s', '45s', '50s']
  },

  frequencyChartLabels: {
    hundred: ['0', '100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'],
    ten: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
    five: ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
    two: ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20'],
    one: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
  }
}
