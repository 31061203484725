import React from 'react';
import classNames from 'classnames';
import Chartist from 'chartist';
import _ from 'lodash';

import PointUtils from './SVGPointUtils';

class Utils {
  static NATIVE_RIGHT_CHART_MARGIN = 15; // Right space between right border of `.ct-grids` block and the right border of `.chart-block`.

  static getPhaseTitleStyles(index, arrayLength, pointsNumber, nativeChartOffset, customChartOffset, chartLinesSpace,
                             phaseBarIndent, shrinkLastBlock, minFirstBlockWidth) {
    var style = {
      width: 0
    };
    if (index === 0 || (shrinkLastBlock && index === arrayLength - 1)) {
      style.width = chartLinesSpace * (pointsNumber * 2 - 1) - phaseBarIndent;
      if (index === 0 && (!shrinkLastBlock || arrayLength > 1)) {
        style.marginLeft = nativeChartOffset + customChartOffset + phaseBarIndent / 2;
        if (minFirstBlockWidth != null && style.width < minFirstBlockWidth) {
          style.marginLeft += style.width - minFirstBlockWidth;
          style.width = minFirstBlockWidth;
        }
      }
    } else {
      style.width = chartLinesSpace * pointsNumber * 2 - phaseBarIndent;
    }

    return style;
  }

  static getYAxisTitle(chartType, stepBarName) {
    if (chartType === 'default' || chartType === 'task analysis') {
      return 'Accuracy';
    }
    if (chartType === 'frequency') {
      return 'Occurrences';
    }
    if (stepBarName === 'hour' || stepBarName === 'halfAnHour') {
      return 'Hours';
    }
    if (stepBarName === 'tenSeconds' || stepBarName === 'fiveSeconds') {
      return 'Seconds';
    }

    return 'Minutes';
  }

  static getPagePointShape(type, note) {
    var classes;

    if (_.isEmpty(note)) {
      if (type === 'pretest' || type === 'posttest' || type === 'probe') {
        classes = `chart-point-example selected ct-dot ct-${type}`;

        return (
          <svg xmlns="http://www.w3.org/2000/svg"
               className={classes}>
            <polygon points={PointUtils.getOutRectLeftCoords(12, 12, 11)} className="ct-out-rect"/>
            <polygon points={PointUtils.getOutRectRightCoords(12, 12, 11)} className="ct-out-rect"/>
            <polygon points={PointUtils.getTriangleLeftCoords(12, 12, 6)} className="ct-triangle-left"/>
            <polygon points={PointUtils.getTriangleRightCoords(12, 12, 6)} className="ct-triangle-right"/>
          </svg>
        );
      } else {
        return (
          <svg xmlns="http://www.w3.org/2000/svg"
               className="chart-point-example">
            <circle cx="12" cy="12" r="9" className="selected ct-dot ct-standard"/>
          </svg>
        )
      }
    } else {
      if (type === 'pretest' || type === 'posttest' || type === 'probe') {
        classes = `chart-point-example selected ct-dot ct-outlined ct-${type}`;

        return (
          <svg xmlns="http://www.w3.org/2000/svg"
               className={classes}>
            <polygon points={PointUtils.getOutRectLeftCoords(12, 12, 12)} className="ct-out-rect"/>
            <polygon points={PointUtils.getOutRectRightCoords(12, 12, 12)} className="ct-out-rect"/>
            <polygon points={PointUtils.getTriangleLeftCoords(12, 12, 9)} className="ct-border ct-border-rect"/>
            <polygon points={PointUtils.getTriangleRightCoords(12, 12, 9)} className="ct-border ct-border-rect"/>
            <polygon points={PointUtils.getTriangleLeftCoords(12, 12, 5)} className="ct-triangle-left"/>
            <polygon points={PointUtils.getTriangleRightCoords(12, 12, 5)} className="ct-triangle-right"/>
          </svg>
        );
      } else {
        return (
          <svg xmlns="http://www.w3.org/2000/svg"
               className="chart-point-example selected ct-dot ct-standard ct-outlined">
            <circle cx="12" cy="12" r="9" className="ct-out-circle"/>
            <circle cx="12" cy="12" r="9" className="ct-border"/>
            <circle cx="12" cy="12" r="6" className="ct-in-circle"/>
          </svg>
        )
      }
    }
  }

  static getPointShape(dataPoint, data, viewType = 'web', pointClasses = '') {
    var sizes,
      pointShape;
    if (!dataPoint.note) {
      if (dataPoint.type === 'pretest'
        || dataPoint.type === 'posttest'
        || dataPoint.type === 'probe') {

        sizes = viewType === 'web' ? [11, 6] : [8, 4];
        pointClasses = classNames(pointClasses, 'ct-dot', `ct-${dataPoint.type}`);
        pointShape = new Chartist.Svg('svg', null, pointClasses);
        new Chartist.Svg('polygon', {
          points: [PointUtils.getOutRectLeftCoords(data.x, data.y, sizes[0])]
        }, 'ct-out-rect', pointShape);
        new Chartist.Svg('polygon', {
          points: [PointUtils.getOutRectRightCoords(data.x, data.y, sizes[0])]
        }, 'ct-out-rect', pointShape);
        new Chartist.Svg('polygon', {
          points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[1])]
        }, 'ct-triangle-left', pointShape);
        new Chartist.Svg('polygon', {
          points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[1])]
        }, 'ct-triangle-right', pointShape);

        return pointShape;
      }

      sizes = viewType === 'web' ? [9] : [6];
      pointClasses = classNames(pointClasses, 'ct-dot', 'ct-standard');
      return new Chartist.Svg('circle', {
        cx: [data.x], cy: [data.y], r: sizes
      }, pointClasses);
    }

    if (dataPoint.type === 'pretest'
      || dataPoint.type === 'posttest'
      || dataPoint.type === 'probe') {

      sizes = viewType === 'web' ? [12, 9, 5] : [9, 5, 2];
      pointClasses = classNames(pointClasses, 'ct-dot', 'ct-outlined', `ct-${dataPoint.type}`);
      pointShape = new Chartist.Svg('svg', null, pointClasses);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getOutRectLeftCoords(data.x, data.y, sizes[0])]
      }, 'ct-out-rect', pointShape);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getOutRectRightCoords(data.x, data.y, sizes[0])]
      }, 'ct-out-rect', pointShape);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[1])]
      }, 'ct-border ct-border-rect', pointShape);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[1])]
      }, 'ct-border ct-border-rect', pointShape);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getTriangleLeftCoords(data.x, data.y, sizes[2])]
      }, 'ct-triangle-left', pointShape);
      new Chartist.Svg('polygon', {
        points: [PointUtils.getTriangleRightCoords(data.x, data.y, sizes[2])]
      }, 'ct-triangle-right', pointShape);

      return pointShape;
    }

    sizes = viewType === 'web' ? [9, 6] : [5, 3];
    pointClasses = classNames(pointClasses, 'ct-dot', 'ct-standard', 'ct-outlined');
    pointShape = new Chartist.Svg('svg', null, pointClasses);
    new Chartist.Svg('circle', {
      cx: [data.x], cy: [data.y], r: [sizes[0]]
    }, 'ct-out-circle', pointShape);
    new Chartist.Svg('circle', {
      cx: [data.x], cy: [data.y], r: [sizes[0]]
    }, 'ct-border', pointShape);
    new Chartist.Svg('circle', {
      cx: [data.x], cy: [data.y], r: [sizes[1]]
    }, 'ct-in-circle', pointShape);

    return pointShape;
  }

  static getChartWidth(labelArrayLength, chartLinesSpace, nativeChartOffset, customChartOffset) {
    return labelArrayLength * chartLinesSpace + Utils.NATIVE_RIGHT_CHART_MARGIN + nativeChartOffset + customChartOffset;
  }

  static getPrintChartLabel(date) {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  }

  static getBehaviorSteps(phases, chartType) {
    function getFrequencySteps(value) {
      if (value > 100) {
        return {
          divisor: 10,
          stepBarName: 'hundred'
        }
      }
      if (value > 50) {
        return {
          divisor: 1,
          stepBarName: 'ten'
        }
      }
      if (value > 20) {
        return {
          divisor: 0.5,
          stepBarName: 'five'
        }
      }
      if (value > 10) {
        return {
          divisor: 0.2,
          stepBarName: 'two'
        }
      }
      return {
        divisor: 0.1,
        stepBarName: 'one'
      }
    }

    function getDurationSteps(value) {
      if (value > 18000) {
        return {
          divisor: 360,
          stepBarName: 'hour'
        }
      }
      if (value > 6000) {
        return {
          divisor: 180,
          stepBarName: 'halfAnHour'
        }
      }
      if (value > 3000) {
        return {
          divisor: 60,
          stepBarName: 'tenMinutes'
        }
      }
      if (value > 600) {
        return {
          divisor: 30,
          stepBarName: 'fiveMinutes'
        }
      }
      if (value > 300) {
        return {
          divisor: 6,
          stepBarName: 'minute'
        }
      }
      if (value > 100) {
        return {
          divisor: 3,
          stepBarName: 'halfAnMinute'
        }
      }
      if (value > 50) {
        return {
          divisor: 1,
          stepBarName: 'tenSeconds'
        }
      }
      return {
        divisor: 0.5,
        stepBarName: 'fiveSeconds'
      }
    }

    var maxValue = phases.reduce((maxPhaseValue, phase) => {
      return phase.points.reduce((maxPointsValue, point) => {
        return maxPointsValue > point.y ? maxPointsValue : point.y;
      }, maxPhaseValue);
    }, 0);

    return chartType === 'frequency' ? getFrequencySteps(maxValue) : getDurationSteps(maxValue);
  }
}

export default Utils;