import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

import './PageNavigation.css';

PageNavigation.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default function PageNavigation(props) {
  return (
    <div className="page-navigation">
      <KeyboardArrowLeftIcon className="arrow-left-icon"/>
      <Link to={props.link}>{props.text}</Link>
    </div>
  );
}
