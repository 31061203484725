import { Avatar, Card, Col, Dropdown, Icon, Menu, MenuItem, Row, IClickParam, Heading } from '@raudabaugh/thread-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { AuthenticationService } from '../Security/AuthenticationService';
import { getInitials } from './Initials';
import { SideMenuOptionEnum } from './SideMenu';
import { RouteComponentProps } from 'react-router';
import { IAuthDispatchProps, IAuthStateProps } from '../../Utils/ReduxProps'
import ReduxHelpers from '../../Utils/ReduxHelpers';
import Utils from '../../Utils/Utils';
import { Endpoints } from '../../Utils/Endpoints';

interface IMatchParams {
    studentId: string;
}

export interface INavigationBarBase extends RouteComponentProps<IMatchParams> {
    refreshToken: string
    onToggleMenu: () => void
    onSelect: (option: SideMenuOptionEnum | string) => void;
}

type INavigationBarProps = INavigationBarBase & IAuthStateProps & IAuthDispatchProps;

export enum avatarSelectEnum {
    VIEW = 'VIEW',
    SIGN_OUT = 'Sign Out',
}

class NavigationBar extends React.PureComponent<INavigationBarProps> {
    constructor(props: INavigationBarProps) {
        super(props);
        this.handleSupportClick = this.handleSupportClick.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleAvatarSelect = this.handleAvatarSelect.bind(this);
    }

    public render() {
        const avatarMenu = <Menu
            onClick={this.handleAvatarSelect}
        >
            <MenuItem key={avatarSelectEnum.SIGN_OUT} >Sign Out</MenuItem>
            <MenuItem key={avatarSelectEnum.VIEW}>View Profile</MenuItem>
        </Menu>
        return (
            <Card variation={1} margin="0" padding="4px 12px 4px 12px">
                <Row gutter={12} type="flex" align="middle">
                    <Row grow={1}>
                        <Col lg={0} xl={0} xxl={0} padding="12px">
                            <Icon color="default" variation={10} size="1.6em" type="fa-bars" onClick={this.handleToggleMenu} />
                        </Col>
                    </Row>
                    <Col grow={0} padding="12px" >
                        <Icon color="default" variation={10} size="1.4em" type="fa-question-circle far" onClick={this.handleSupportClick} />
                    </Col>
                    <Col grow={0} margin="12px 16px">
                        <Dropdown trigger={['click']} overlay={avatarMenu}>
                            <Avatar alt={this.props.userName} size={40} src={this.props.userAvatar}>{getInitials(this.props.userName)}</Avatar>
                        </Dropdown>
                    </Col>
                    <Col xs={0} sm={5} md={4} lg={3}>
                        <Heading level={6}>{this.props.userName}</Heading>
                        <Heading level={7}>{localStorage['orgName']}</Heading>
                    </Col>
                </Row>
            </Card>
        );
    }

    private logout = () => {
        localStorage.removeItem('authProvider');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('realmPath');

        const authService = AuthenticationService.getInstance();
        authService.logout();
    }

    private handleAvatarSelect(event: IClickParam) {
        if (event!.key === avatarSelectEnum.SIGN_OUT) {
            this.logout();
        } else {
            const profileUrl = process.env.REACT_APP_THREADWEB_URL + '/people/staff/' + this.props.userId;
            window.location.href = profileUrl;
        }
    }

    private handleToggleMenu(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        this.props.onToggleMenu();
    }

    private handleSupportClick() {
        (window as any).Intercom('show');
    }
}

export default connect<IAuthStateProps, IAuthDispatchProps, INavigationBarProps>(
    ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(NavigationBar);