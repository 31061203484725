import React from 'react';
import PropTypes from 'prop-types';

import './Error.css';

Error.propTypes = {
  message: PropTypes.string.isRequired
};

export default function Error(props) {
  return (
    <div id="error">
      <div>Oops! Something went wrong!</div>
      <div className="message">{props.message}</div>
    </div>
  );
}