import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Icon from '@material-ui/core/Icon';

import Page from '../Page/Page';

import StudentProgressUtils from '../../Utils';
import PrintUtils from '../Utils';
import Utils from '../../../../Utils/Utils';

import './TargetsList.css';

const LEFT_COL_WIDTH_RATIO = 0.25;
const RIGHT_COL_WIDTH_RATIO = 1 - LEFT_COL_WIDTH_RATIO;
const LEFT_COL_MARGIN = 40;
const HEADER_HEIGHT = 30;
const CRITERION_LIST_TOP_MARGIN = 5;
const LEFT_ROW_HEIGHT = 30;
const LEFT_BLOCK_TOP_MARGIN = 25;
const LEFT_BLOCK_BOTTOM_PADDING = 20;
const BORDER_THICKNESS = 2;
const LEFT_BLOCK_FONT_SIZE = 20;
const RIGHT_BLOCK_FONT_SIZE = 18;
const RIGHT_ROW_HEIGHT = 25;
const RIGHT_ROW_PADDINGS = 10;
const RIGHT_ROW_INDENTS = 124;

TargetsList.propTypes = {
  student: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  schoolYearNode: PropTypes.node,
  targets: PropTypes.exact({
    criterionRules: PropTypes.arrayOf(PropTypes.exact({
      pointsAnalyzed: PropTypes.number.isRequired,
      minPercentage: PropTypes.number.isRequired
    }).isRequired).isRequired,
    shortTermObjective: PropTypes.string.isRequired,
    longTermObjective: PropTypes.string.isRequired,
    targetsList: PropTypes.arrayOf(PropTypes.exact({
      targetDescription: Utils.nonEmptyStringPropValidator,
      date: PropTypes.instanceOf(Date)
    }).isRequired).isRequired
  }).isRequired,
  pageSize: PropTypes.exact({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired
};

export default function TargetsList(props) {
  var valuablePageWidth = props.pageSize.width - LEFT_COL_MARGIN;
  var leftBlockWidth = valuablePageWidth * LEFT_COL_WIDTH_RATIO;
  var rightBlockWidth = valuablePageWidth * RIGHT_COL_WIDTH_RATIO - RIGHT_ROW_INDENTS;

  var shortTermObjective = props.targets.shortTermObjective;
  var longTermObjective = props.targets.longTermObjective;

  var pages,
    pagesMetadata = [],
    totalHeight,
    beginIndex = 0,
    pageNumber = 0,
    criterionNodes;

  var error = PrintUtils.getErrorNode(props.pageSize.height, 'Targets List');

  if (!error) {
    totalHeight = HEADER_HEIGHT + CRITERION_LIST_TOP_MARGIN + LEFT_ROW_HEIGHT * props.targets.criterionRules.length;
    pagesMetadata.push({
      shouldRenderMasteryBlock: true
    });

    totalHeight = estimateLeftBlock(shortTermObjective, leftBlockWidth, totalHeight, props.pageSize.height,
      pagesMetadata, 'shouldRenderShortTermBlock');

    estimateLeftBlock(longTermObjective, leftBlockWidth, totalHeight, props.pageSize.height, pagesMetadata,
      'shouldRenderLongTermBlock');

    totalHeight = HEADER_HEIGHT + BORDER_THICKNESS;
    props.targets.targetsList.forEach((targetObject, index) => {
      var rowHeight = PrintUtils.getRowNumber(targetObject.targetDescription, rightBlockWidth, RIGHT_BLOCK_FONT_SIZE)
        * RIGHT_ROW_HEIGHT + RIGHT_ROW_PADDINGS + BORDER_THICKNESS;
      totalHeight += rowHeight;

      if (totalHeight > props.pageSize.height) {
        saveMetadataIndexes(pagesMetadata, pageNumber, beginIndex, index);
        pageNumber++;
        beginIndex = index;
        totalHeight = HEADER_HEIGHT + BORDER_THICKNESS + rowHeight;
      }
    });

    saveMetadataIndexes(pagesMetadata, pageNumber, beginIndex, props.targets.targetsList.length);

    criterionNodes = props.targets.criterionRules.map((criterion, index) => {
      return (
        <li key={index}>{Math.round(criterion.minPercentage * 100)}% x {criterion.pointsAnalyzed}</li>
      );
    });

    pages = pagesMetadata.map((pageMetadata, metadataIndex) => {
      var targetNodes = [];
      if (pageMetadata.beginIndex != null && pageMetadata.endIndex != null) {
        for (var index = pageMetadata.beginIndex; index < pageMetadata.endIndex; index++) {
          targetNodes.push(
            <div key={index} className="row">
              <Icon className="circle-icon">adjust</Icon>
              <span>{props.targets.targetsList[index].targetDescription}</span>
              <span className="date">{
                props.targets.targetsList[index].date != null
                  ? StudentProgressUtils.getPrintChartLabel(props.targets.targetsList[index].date) : ''
              }</span>
            </div>
          )
        }
      }

      return (
        <Page key={metadataIndex}
              student={props.student}
              rightTopNode={props.schoolYearNode}>
          <div className="left">
            {pageMetadata.shouldRenderMasteryBlock ? (
              <div className="block">
                <header>
                  Mastery Criterion
                </header>
                <div className="body">
                  <ul>
                    {criterionNodes}
                  </ul>
                </div>
              </div>
            ) : null}
            {pageMetadata.shouldRenderShortTermBlock ? (
              <div className="block">
                <header>
                  Short Term Objective
                </header>
                <div className="body">
                  {shortTermObjective}
                </div>
              </div>
            ) : null}
            {pageMetadata.shouldRenderLongTermBlock ? (
              <div className="block">
                <header>
                  Long Term Objective
                </header>
                <div className="body">
                  {longTermObjective}
                </div>
              </div>
            ) : null}
          </div>
          <div className="right">
            <header>Mastered Date</header>
            {targetNodes}
          </div>
        </Page>
      );
    });
  }

  return (
    <div id="student-progress-targets-list-print">
      {error ? (
        <Page student={props.student}
              rightTopNode={props.schoolYearNode}>
          {error}
        </Page>
      ) : pages}
    </div>
  );
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function saveMetadataIndexes(pagesMetadata, pageNumber, beginIndex, endIndex) {
  if (pagesMetadata.length >= pageNumber + 1) {
    pagesMetadata[pageNumber].beginIndex = beginIndex;
    pagesMetadata[pageNumber].endIndex = endIndex;
  } else {
    pagesMetadata.push({
      beginIndex: beginIndex,
      endIndex: endIndex
    });
  }
}

function estimateLeftBlock(value, leftBlockWidth, total, pageHeight, pagesMetadata, blockKey) {
  var stringHeight = PrintUtils.getRowNumber(value, leftBlockWidth, LEFT_BLOCK_FONT_SIZE);
  total += LEFT_BLOCK_BOTTOM_PADDING + BORDER_THICKNESS + LEFT_BLOCK_TOP_MARGIN + HEADER_HEIGHT
    + stringHeight * LEFT_ROW_HEIGHT;
  if (total <= pageHeight) {
    _.last(pagesMetadata)[blockKey] = true;
  } else {
    total = HEADER_HEIGHT + stringHeight * LEFT_ROW_HEIGHT;
    pagesMetadata.push({
      [blockKey]: true
    });
  }

  return total;
}
