import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import {
  setAccessToken,
  setAuthTokens
} from '../actions';

import Login from './Login/Login';
import UserSpace from './UserSpace/UserSpace';

import { Endpoints } from '../Utils/Endpoints';
import Utils from '../Utils/Utils';

import { RenewCallback } from './Security/RenewCallback';
import { SigninCallback } from './Security/SigninCallback';

const HandleSigninCallback = () => (
  // tslint:disable-next-line:jsx-no-lambda
  <Route render={({ history }) => (
    <SigninCallback
      onSuccess={() => { history.push("/"); }}
      onError={() => { history.push("/"); }}
    />
  )} />
)

class AuthContainer extends React.PureComponent {
  static propTypes = {
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    setAccessToken: PropTypes.func.isRequired,
    setAuthTokens: PropTypes.func.isRequired,
    removeAuthTokens: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
  }

  componentDidMount() {
    this.refreshAccessToken();
  }

  componentDidUpdate() {
    this.refreshAccessToken();
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel();
  }

  refreshAccessToken() {
    if (this.props.refreshToken != null && this.props.refreshToken !== '' && this.props.realmPath != null && this.props.accessToken == null) {
      Utils.axios(Endpoints().auth, {
        data: {
          provider: 'realm',
          data: this.props.refreshToken,
          path: this.props.realmPath
        },
        cancelToken: this.cancelTokenSource.token
      }).then((response) => {
        var accessToken = response.data.access_token.token;
        localStorage.setItem('accessToken', accessToken);
        this.props.setAccessToken(accessToken);
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          if (!_.isEmpty(error.response) && !_.isEmpty(error.response.data) && _.isObject(error.response.data)) {
            // refresh token expired
            if (error.response.data.status !== 403 || error.response.data.code !== 614) {
              Utils.printErrorMessage('AuthContainer', error.message, error.response.data);
            } else {
              this.props.removeAuthTokens();
            }

            return error.response.data.title;
          }

          Utils.printErrorMessage('AuthContainer', error.message);
        }
      });
    }
  }

  render() {
    return this.props.refreshToken != null && this.props.realmPath != null && this.props.accessToken == null ? null : (
      <Router>
        <Switch>
          <Route path="/login"
            render={(routeProps) => (
              <Login setAuthTokens={this.props.setAuthTokens}
                {...routeProps} />
            )} />
          <Route exact={true} path="/signin-callback.html" component={HandleSigninCallback} />
          <Route exact={true} path="/silent-renew.html" component={RenewCallback} />
          <Route path="/"
            render={(routeProps) => (
              <UserSpace
                accessToken={this.props.accessToken}
                refreshToken={this.props.refreshToken}
                removeAuthTokens={this.props.removeAuthTokens}
                {...routeProps} />
            )} />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    accessToken: state.accessToken,
    refreshToken: state.refreshToken,
    realmPath: state.realmPath,
    userName: state.userName,
    userAvatar: state.userAvatar,
    userId: state.userId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAccessToken: (accessToken) => {
      dispatch(setAccessToken(accessToken))
    },
    setAuthTokens: (refreshToken, accessToken, realmPath, userName, userAvatar, userId) => {
      dispatch(setAuthTokens(refreshToken, accessToken, realmPath, userName, userAvatar, userId))
    },
    removeAuthTokens: () => {
      dispatch(setAuthTokens(null, null, null, null, null, null))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
