import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Paper from '@material-ui/core/Paper';

import './ListPanel.css';

ListPanel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  footer: PropTypes.string,
  pageHeaderHeight: PropTypes.number.isRequired
};

export default function ListPanel(props) {
  const count = React.Children.count(props.children);
  const items = React.Children.map(props.children, (child, i) => {
    const itemClasses = classNames({
      'item-last': i === count - 1 && !props.footer
    });

    return React.cloneElement(child, {
      className: itemClasses
    });
  });

  return (
    <Paper id={props.id}
           className={classNames(props.className, 'list-panel')}
           style={{maxHeight: `calc(100vh - ${props.pageHeaderHeight}px`}}>
      <div className="list-header-wrapper">
        <div className="list-header">
          {props.header}
        </div>
      </div>
      <ul>
        {items}
      </ul>
      <div className="footer">
        {props.footer}
      </div>
    </Paper>
  );
}
