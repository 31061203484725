import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import {
  AppBar,
  Button,
  Toolbar
} from '@material-ui/core';
import { Col, Row, Overlay, Drawer, Grid } from '@raudabaugh/thread-ui';

import logo from './ThreadLogoWhite-1x.png';

import ChangePassword from '../Security/ChangePassword';
import StudentsList from '../StudentsList/StudentsList';
import StudentProfile from '../StudentProfile/StudentProfile';
import StudentProgress from '../StudentProgress/StudentProgress';
import NavigationBar from '../PageNavigation/NavigationBar';
import { SideMenu } from '../PageNavigation/SideMenu';

import ReduxHelpers from '../../Utils/ReduxHelpers';
import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';

import './UserSpace.css';

class UserSpace extends React.PureComponent {
  static propTypes = {
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    removeAuthTokens: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { menuOpen: false }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
  }

  logout = () => {
    var revokeAccessToken = Utils.axios(Endpoints().logout, {
      accessToken: localStorage['accessToken'],
      data: {
        token: this.props.accessToken
      }
    });

    var revokeRefreshToken = Utils.axios(Endpoints().logout, {
      accessToken: this.props.refreshToken,
      data: {
        token: this.props.refreshToken
      }
    });

    axios.all([revokeAccessToken, revokeRefreshToken]).catch((error) => {
      if (!_.isEmpty(error.response) && !_.isEmpty(error.response.data) && _.isObject(error.response.data)) {
        Utils.printErrorMessage('UserSpace', error.message, error.response.data);
      } else {
        Utils.printErrorMessage('UserSpace', error.message);
      }
    });

    localStorage.removeItem('authProvider');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('realmPath');

    this.props.removeAuthTokens();
  };

  render() {
    return this.props.refreshToken == null ? (
      <Redirect to={{
        pathname: '/login',
        state: {
          from: this.props.location
        }
      }} />
    ) : (
        <div id="app">
          {this.props.userId !== '' ?
            <Grid type="flex" height="90vh" width="100%" onClick={this.handleCloseMenu}>
              <Row margin='0' type='flex' grow={1}>
                <Col span={4}>
                  <div className="side-menu">
                    <Drawer lg={true} xl={true} xxl={true} mask={false} margin="0" padding="0" height="auto" placement="left" visible={this.state.menuOpen} closable={false}>
                      <SideMenu
                        onCloseMenu={this.handleCloseMenu.bind(this)}
                        onSelect={this.handleMenuSelect.bind(this, this.props)}
                      />
                      {this.state.menuOpen && <Overlay zIndex={-1} color='default' variation={7} opacity={.5} width='100vw' />}
                    </Drawer>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                  <Grid type="flex" height="100vh" >
                    <div className="navigation-bar">
                      <NavigationBar
                        {...this.props}
                        onToggleMenu={this.handleToggleMenu}
                        onSelect={this.handleMenuSelect.bind(this, this.props)}
                      />
                    </div>
                    <div className="main">
                      <Switch>
                        <Route path="/student/:studentId/program/:programId"
                          component={StudentProgress} />
                        <Route path="/student/:studentId"
                          component={StudentProfile} />
                        <Route path="/"
                          component={StudentsList} />
                      </Switch>
                    </div>
                  </Grid>
                </Col>
              </Row>
            </Grid>

            :
            <>
              <AppBar position="fixed"
                component="header">
                <Toolbar className="header-body">
                  <Link className="logo-link"
                    to="/">
                    <img className="logo-image"
                      src={logo}
                      alt="logo" />
                  </Link>
                  <Button className="logout-btn"
                    color="inherit"
                    variant="outlined"
                    onClick={this.logout}>Log out</Button>
                </Toolbar>
              </AppBar>
              <main>
                <Switch>
                  <Route path="/changepassword"
                    component={ChangePassword} />
                  <Route path="/student/:studentId/program/:programId"
                    component={StudentProgress} />
                  <Route path="/student/:studentId"
                    component={StudentProfile} />
                  <Route path="/"
                    component={StudentsList} />
                </Switch>
              </main>
            </>
          }
        </div>
      );
  }

  handleToggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleCloseMenu() {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  }

  handleMenuSelect(context, option) {
    window.location.href = option;
  }

}

export default connect(ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(UserSpace);