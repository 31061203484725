import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import StudentHeader from '../../../StudentHeader/StudentHeader';

import './Page.css';

Page.propTypes = {
  className: PropTypes.string,
  student: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  rightTopNode: PropTypes.node,
  children: PropTypes.node
};

export default function Page(props) {
  return (
    <div className={classNames(props.className, 'print-page')}>
      <StudentHeader student={_.omit(props.student, 'title')}
                     leftBottomNode={<span>{props.student.title}</span>}
                     rightTopNode={props.rightTopNode}/>
      <div className="student-progress-print-body">
        {props.children}
      </div>
    </div>
  )
}