import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import classNames from 'classnames';
import ThreadUtils from 'thread-web-utils';

import {
  Icon,
  Paper
} from '@material-ui/core';

import PageNavigation from '../PageNavigation/PageNavigation';
import StudentHeader from '../StudentHeader/StudentHeader';
import ChartPointPopup from './ChartPointPopup/ChartPointPopup';
import ChartPhasePopup from './ChartPhasePopup/ChartPhasePopup';
import SkillsForm from '../EditProgramDialog/SkillsForm';
import NavProgramsList from './NavProgramsList/NavProgramsList';
import Print from './Print/Print';

import Constants from '../../Utils/Constants';
import Utils from '../../Utils/Utils';
import StudentProgressUtils from './Utils';

import 'chartist/dist/chartist.css'
import './StudentProgress.css';
import './StudentProgressScreen.css';

const dotMargin = 15;
const pageMargin = 10;

const PAGE_HEADER_HEIGHT = 64;
const VERTICAL_CHART_LINES_SPACE = 40;
const NATIVE_CHART_OFFSET = 10;
const CUSTOM_CHART_OFFSET = 10;

const chartDataProps = {
  labels: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ])).isRequired,
  series: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired).isRequired
};

const chartDataInfoProps = {
  id: Utils.nonEmptyStringPropValidator,
  x: PropTypes.instanceOf(Date).isRequired,
  note: PropTypes.exact({
    body: Utils.nonEmptyStringPropValidator,
    date: PropTypes.instanceOf(Date).isRequired
  })
};

const phasesProps = {
  id: Utils.nonEmptyStringPropValidator,
  pointsNumber: PropTypes.number.isRequired,
  range: Utils.nonEmptyStringPropValidator,
  labelOverride: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string.isRequired
};

const phasesNonBehaviorProps = {
  title: PropTypes.string.isRequired,
  trialsNum: PropTypes.number,
  criterionRules: PropTypes.arrayOf(PropTypes.exact({
    pointsAnalyzed: PropTypes.number.isRequired,
    minPercentage: PropTypes.number.isRequired
  }).isRequired).isRequired,
  procedure: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  reinforcementSchedule: PropTypes.string.isRequired,
  reinforcementType: PropTypes.string.isRequired
};

export default class StudentProgressView extends React.PureComponent {
  static propTypes = {
    programId: Utils.nonEmptyStringPropValidator,
    chartType: PropTypes.oneOf(['default', 'task analysis', 'frequency', 'duration']).isRequired,
    student: PropTypes.exact({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      title: PropTypes.string.isRequired
    }).isRequired,
    programs: PropTypes.arrayOf(PropTypes.exact({
      id: Utils.nonEmptyStringPropValidator,
      title: PropTypes.string.isRequired
    }).isRequired).isRequired,
    chartData: PropTypes.oneOfType([
      PropTypes.exact(Object.assign({}, chartDataProps, {
        stepBarName: PropTypes.oneOf(_.concat(_.keys(Constants.frequencyChartLabels),
          _.keys(Constants.durationChartLabels))).isRequired,
        info: PropTypes.arrayOf(PropTypes.exact(Object.assign({}, chartDataInfoProps, {
          y: PropTypes.number.isRequired,
          type: PropTypes.oneOf(['standard']).isRequired,
        }))).isRequired
      })),
      PropTypes.exact(Object.assign({}, chartDataProps, {
        info: PropTypes.arrayOf(PropTypes.exact(Object.assign({}, chartDataInfoProps, {
          y: PropTypes.exact({
            numerator: PropTypes.number.isRequired,
            denominator: PropTypes.number.isRequired
          }).isRequired,
          type: PropTypes.oneOf(['pretest', 'posttest', 'probe', 'standard']).isRequired,
          creatorName: PropTypes.string.isRequired,
          recType: PropTypes.string.isRequired
        }))).isRequired
      }))
    ]).isRequired,
    targets: PropTypes.exact({
      shortTermObjective: PropTypes.string.isRequired,
      longTermObjective: PropTypes.string.isRequired,
      targetsList: PropTypes.arrayOf(PropTypes.exact({
        targetDescription: Utils.nonEmptyStringPropValidator,
        date: PropTypes.instanceOf(Date)
      }).isRequired).isRequired
    }),
    phases: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.exact(Object.assign({}, phasesProps, {
        info: Utils.nonEmptyStringPropValidator,
        target: PropTypes.string.isRequired
      })).isRequired),
      PropTypes.arrayOf(PropTypes.exact(Object.assign({}, phasesProps, phasesNonBehaviorProps, {
        discriminativeStimulus: PropTypes.string.isRequired,
        lto: PropTypes.string.isRequired,
        currentTargets: PropTypes.arrayOf(PropTypes.exact({
          id: Utils.nonEmptyStringPropValidator,
          description: Utils.nonEmptyStringPropValidator,
          type: PropTypes.string
        }).isRequired).isRequired,
        masteredTargets: PropTypes.arrayOf(PropTypes.exact({
          id: Utils.nonEmptyStringPropValidator,
          description: Utils.nonEmptyStringPropValidator,
          type: PropTypes.string
        }).isRequired).isRequired,
        futureTargets: PropTypes.arrayOf(PropTypes.exact({
          id: Utils.nonEmptyStringPropValidator,
          description: Utils.nonEmptyStringPropValidator,
          type: PropTypes.string
        }).isRequired).isRequired,
        isLastPhase: PropTypes.bool.isRequired
      })).isRequired),
      PropTypes.arrayOf(PropTypes.exact(Object.assign({}, phasesProps, phasesNonBehaviorProps, {
        tasks: PropTypes.arrayOf(PropTypes.exact({
          id: Utils.nonEmptyStringPropValidator,
          taskDescription: Utils.nonEmptyStringPropValidator,
          isActive: PropTypes.bool.isRequired
        }).isRequired)
      })).isRequired)
    ]).isRequired,
    isFullUpdate: PropTypes.bool.isRequired,
    initFunc: PropTypes.func.isRequired
  };

  phaseHeaderNodes = [];

  handleChartClick = (event) => {
    event.stopPropagation();
    var chartPoint = getChartPoint(event.target);
    var state = {};

    if (chartPoint) {
      var pointIndexStr = _(chartPoint.classList).find((className) => {
        return className.indexOf('ct-num-') === 0;
      }).substring(7);
      var pointIndex = parseInt(pointIndexStr, 10);

      var popupRect = {
        width: 300,
        height: this.props.chartType === 'frequency' || this.props.chartType === 'duration' ? 319 : 532
      };
      var pageRect = getNodeRectangle(this.wrapper);

      var dotSize = chartPoint.getBoundingClientRect();
      var scrollTop = $('html').scrollTop();
      var dotCenter = {
        x: dotSize.left + dotSize.width / 2,
        y: scrollTop + dotSize.top + dotSize.height / 2 - PAGE_HEADER_HEIGHT
      };

      var top = dotCenter.y - popupRect.height / 2;
      var left = dotCenter.x - popupRect.width - dotMargin;

      if (top < pageMargin) {
        top = pageMargin;
      } else if (top + popupRect.height > pageRect.height - pageMargin) {
        top = pageRect.height - popupRect.height - pageMargin;
      }

      if (dotCenter.x < popupRect.width + dotMargin + pageMargin) {
        left = dotCenter.x + dotMargin;
      }

      var newPosition = {
        display: 'block',
        top: top,
        left: left
      };
      if (this.state.pointPopup == null || !_.isEqual(this.state.pointPopup.position, newPosition)) {
        Object.assign(state, {
          pointPopup: {
            position: newPosition,
            index: pointIndex
          }
        });
      }
    } else {
      Object.assign(state, {
        pointPopup: null
      });
    }

    Object.assign(state, {
      phasePopupPosition: null
    });
    this.setState(state);
  };

  handlePageClick = () => {
    this.setState({
      pointPopup: null,
      phasePopupPosition: null,
      selectedPhaseIndex: null
    });
  };

  chartScrollHandler = () => {
    this.setState({
      pointPopup: null,
      phasePopupPosition: null
    });
  };

  handleDialogOpen = () => {
    this.setState({
      phasePopupPosition: null,
      isDialogOpen: true
    });
  };

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      pointPopup: null,
      phasePopupPosition: null,
      selectedPhaseIndex: getSelectedPhaseIndexInitialValue(props.phases),
      isDialogOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFullUpdate) {
      this.setState({
        selectedPhaseIndex: getSelectedPhaseIndexInitialValue(nextProps.phases)
      })
    }
  }

  componentDidMount() {
    this.updateScrollPosition();
  }

  componentDidUpdate() {
    this.updateScrollPosition();
  }

  updateScrollPosition() {
    if (this.props.isFullUpdate && this.chart) {
      this.chart.scrollLeft = StudentProgressUtils.getChartWidth(this.props.chartData.labels.length,
        VERTICAL_CHART_LINES_SPACE, NATIVE_CHART_OFFSET, CUSTOM_CHART_OFFSET);
    }
  }

  showPhasePopup(index) {
    const popupWidth = 420;
    var phaseHeaderRect = getNodeRectangle(this.phaseHeaderNodes[index]);

    var left = phaseHeaderRect.left - popupWidth;

    if (left < pageMargin) {
      left = pageMargin;
    }

    return {
      phasePopupPosition: {
        display: 'block',
        top: 260,
        left: left
      }
    }
  };

  chartDrawHandler(data) {
    if (data.type === 'point') {
      var isEvenIndex = data.index % 2 === 0;
      var ctNumClass = `ct-num-${data.index}`;
      if (!isEvenIndex) {
        data.element.addClass(`ct-empty ${ctNumClass}`);
      } else {
        var pointClasses = {
          [ctNumClass]: true
        },
          pointShape;

        if (this.state.selectedPhaseIndex != null) {
          var pointsBeforeSelectionArray = this.props.phases.slice(0, this.state.selectedPhaseIndex);
          var pointsBeforeSelection = pointsBeforeSelectionArray.reduce((sum, element) => {
            return sum + element.pointsNumber;
          }, 0);
          var pointsIncludingSelection = pointsBeforeSelection
            + this.props.phases[this.state.selectedPhaseIndex].pointsNumber;

          var isDotSelected = data.index >= pointsBeforeSelection * 2 && data.index < pointsIncludingSelection * 2;
          pointClasses = classNames(pointClasses, {
            'selected': isDotSelected,
            'dim': !isDotSelected
          });
        }

        pointShape = StudentProgressUtils.getPointShape(this.props.chartData.info[data.index], data, 'web',
          pointClasses);
        data.element.replace(pointShape);
      }
    }
    if (data.type === 'grid') {
      if (data.axis.ticks[data.index] === '') {
        data.element.addClass('ct-separator');
      }
    }
    if (data.type === 'line') {
      var pathGroup = data.path.splitByCommand('m');
      var lineGroup = new Chartist.Svg('svg');

      pathGroup.forEach((path, index) => {
        var lineClasses = classNames('ct-line', getSelectionClasses(this.state.selectedPhaseIndex, index));
        new Chartist.Svg('path', {
          d: path.stringify()
        }, lineClasses, lineGroup);
      });

      data.element.replace(lineGroup);
    }
  }

  print() {
    window.print();
  };

  selectPhase(index, e) {
    e.stopPropagation();

    var state = {
      pointPopup: null
    };
    if (this.state.selectedPhaseIndex !== index) {
      Object.assign(state, {
        selectedPhaseIndex: index
      }, this.showPhasePopup(index));
    } else if (this.state.phasePopupPosition == null) {
      Object.assign(state, this.showPhasePopup(index));
    } else {
      Object.assign(state, {
        phasePopupPosition: null
      });
    }

    this.setState(state);
  }

  renderChartSections() {
    return this.props.phases.map((phase, index) => {
      var style = StudentProgressUtils.getPhaseTitleStyles(index, this.props.phases.length,
        phase.pointsNumber, NATIVE_CHART_OFFSET, CUSTOM_CHART_OFFSET, VERTICAL_CHART_LINES_SPACE, 6, true);

      var headerClasses = classNames('header', getSelectionClasses(this.state.selectedPhaseIndex, index));
      var activeTasks = ThreadUtils.joinTasksWithRanges(phase.tasks);
      return (
        <div key={index}
          className={headerClasses}
          style={style}
          onClick={this.selectPhase.bind(this, index)}
          ref={(el) => {
            this.phaseHeaderNodes[index] = el;
          }}>
          {
            this.props.chartType === 'frequency' || this.props.chartType === 'duration' ? (
              <div className="title ellipsis-overflow">
                <Icon className="circle-icon top-title-element">adjust</Icon>
                <div className="top-title-element">&#160;{phase.info}</div>
              </div>
            ) : this.props.chartType === 'task analysis' ? (
              <div className="title ellipsis-overflow">
                {
                  activeTasks !== '' ? (
                    <div className="top-title-element">Active steps {activeTasks}.</div>
                  ) : (
                      <div className="top-title-element">No active steps.</div>
                    )
                }
              </div>
            ) : (
                  <div className="title ellipsis-overflow">
                    <Icon className="circle-icon top-title-element">adjust</Icon>
                    <div className="top-title-element">&#160;{
                      ThreadUtils.getCommaSeparatedString(phase.currentTargets.map(target => target.description))
                    }</div>
                  </div>
                )
          }
        </div>
      );
    });
  }

  renderYAxisBar(yAxisTitle) {
    var labels = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];

    if (this.props.chartType === 'frequency') {
      labels = Constants.frequencyChartLabels[this.props.chartData.stepBarName];
    } else if (this.props.chartType === 'duration') {
      labels = Constants.durationChartLabels[this.props.chartData.stepBarName];
    }

    return (
      <div className="y-axis">
        <div>{labels[10]}</div>
        <div />
        <div>{labels[8]}</div>
        <div />
        <div>{labels[6]}</div>
        <div />
        <div>{labels[4]}</div>
        <div />
        <div>{labels[2]}</div>
        <div />
        <div>{labels[0]}</div>
        <div className="title">{yAxisTitle}</div>
      </div>
    )
  }

  render() {
    const chartWidth = StudentProgressUtils.getChartWidth(this.props.chartData.labels.length,
      VERTICAL_CHART_LINES_SPACE, NATIVE_CHART_OFFSET, CUSTOM_CHART_OFFSET);

    var options = {
      width: chartWidth,
      height: 400,
      axisY: {
        type: Chartist.FixedScaleAxis,
        high: 100,
        low: 0,
        showGrid: false,
        showLabel: false,
        offset: CUSTOM_CHART_OFFSET
      },
      lineSmooth: Chartist.Interpolation.none({
        fillHoles: false
      })
    };

    var listener = {
      draw: this.chartDrawHandler.bind(this)
    };

    var rightTopNode = (
      <NavProgramsList programList={this.props.programs}
        studentId={this.props.student.id}
        program={{
          id: this.props.programId,
          title: this.props.student.title
        }} />
    );

    var chartData = _.omit(this.props.chartData, 'info');
    chartData.labels = chartData.labels.map((date) => {
      return date != null && date !== '' ? `${date.getMonth() + 1}/${date.getDate()}` : date;
    });

    var printChartData = Object.assign({}, this.props.chartData);
    printChartData.labels = printChartData.labels.map((date) => {
      return date != null && date !== '' ? StudentProgressUtils.getPrintChartLabel(date) : date;
    });
    printChartData.info = printChartData.info.map((infoObject) => {
      return infoObject != null ? _.pick(infoObject, 'x', 'type', 'note', 'creatorName') : infoObject;
    });

    var yAxisTitle = StudentProgressUtils.getYAxisTitle(this.props.chartType, this.props.chartData.stepBarName);

    var printPhases = this.props.phases.map((phase) => {
      var objectives;

      if (this.props.chartType === 'frequency' || this.props.chartType === 'duration') {
        objectives = !_.isEmpty(phase.target) ? [phase.target] : [];
      } else if (this.props.chartType === 'task analysis') {
        objectives = phase.tasks.map((task, index) => {
          return task.isActive ? `${index + 1}. ${task.taskDescription}`
            : `${index + 1}. ${task.taskDescription} (inactive)`;
        });
      } else {
        objectives = phase.currentTargets.map(target => target.description);
      }

      var result = _.pick(phase, 'pointsNumber', 'range', 'labelOverride', 'additionalInfo');

      return Object.assign(result, {
        objectives: objectives
      });
    });

    var chartPhasePopupData;
    if (this.state.selectedPhaseIndex != null && this.props.phases[this.state.selectedPhaseIndex] != null
      && this.props.chartType !== 'frequency' && this.props.chartType !== 'duration') {
      chartPhasePopupData = _.omit(this.props.phases[this.state.selectedPhaseIndex], 'id', 'pointsNumber', 'range',
        'labelOverride', 'tasks', 'discriminativeStimulus', 'lto');
      if (this.props.chartType === 'default') {
        chartPhasePopupData.currentTargets = chartPhasePopupData.currentTargets.map(target => target.description);
        chartPhasePopupData.masteredTargets = chartPhasePopupData.masteredTargets.map(target => target.description);
        chartPhasePopupData.futureTargets = chartPhasePopupData.futureTargets.map(target => target.description);
      }
    }

    var lastCriterionRules = _.pick(_.last(this.props.phases), 'criterionRules');
    if (_.isEmpty(lastCriterionRules)) {
      lastCriterionRules = {
        criterionRules: []
      }
    }

    var targets = this.props.targets != null ? Object.assign(lastCriterionRules, this.props.targets) : undefined;
    var chartPointPopupData = this.state.pointPopup != null
      ? Object.assign({}, this.props.chartData.info[this.state.pointPopup.index], {
        note: this.props.chartData.info[this.state.pointPopup.index].note != null
          ? this.props.chartData.info[this.state.pointPopup.index].note.body : null
      }) : null;
    var studentHeaderStudent = {
      name: this.props.student.name + "'s Chart",
      avatar: this.props.student.avatar
    };

    return (
      <div id="student-progress-wrapper"
        ref={(el) => {
          this.wrapper = el;
        }}
        onClick={this.handlePageClick}>
        <Print chartType={this.props.chartType}
          student={_.omit(this.props.student, 'id', 'avatar')}
          chartData={printChartData}
          targets={targets}
          phases={printPhases} />
        <div id="student-progress-screen"
          className="student-progress content">
          {
            this.state.pointPopup != null ? (
              <ChartPointPopup chartType={this.props.chartType}
                position={this.state.pointPopup.position}
                data={chartPointPopupData}
                studentId={this.props.student.id}
                updateParent={this.props.initFunc} />
            ) : null
          }
          {
            this.props.chartType !== 'frequency' && this.props.chartType !== 'duration'
              && this.state.phasePopupPosition != null ? (
                <ChartPhasePopup position={this.state.phasePopupPosition}
                  data={chartPhasePopupData}
                  openDialog={this.handleDialogOpen} />
              ) : null
          }
          {
            !_.isEmpty(this.props.student.id) ? (
              <PageNavigation text={this.props.student.name + "'s Programs"}
                link={`/student/${this.props.student.id}`} />
            ) : null
          }
          <StudentHeader student={studentHeaderStudent}
            leftBottomNode={<span>Print Chart</span>}
            onLeftBottomClick={this.print}
            rightTopNode={rightTopNode} />
          <div className="chart-block-wrapper">
            {
              _.isEmpty(this.props.chartData.labels) ? (
                <div className="no-points-message">There are no points to display.</div>
              ) : (
                  <Paper className="chart-block"
                    onClick={this.handleChartClick}
                    onScroll={this.chartScrollHandler}>
                    <div className="chart"
                      ref={(el) => {
                        this.chart = el;
                      }}>
                      <div className="headers"
                        style={{ width: chartWidth }}>
                        {this.renderChartSections()}
                      </div>
                      <ChartistGraph className="progress-chart"
                        type="Line"
                        data={chartData}
                        listener={listener}
                        options={options} />
                    </div>
                    {this.renderYAxisBar(yAxisTitle)}
                    <div className="x-axis-title">Date</div>
                  </Paper>
                )
            }
          </div>
        </div>
      </div>
    );
  }
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function getChartPoint(el) {
  if (_.indexOf(el.classList, 'ct-dot') !== -1) {
    return el;
  }

  var $parents = $(el).parents('.ct-dot');
  if ($parents.length === 1) {
    return $parents[0];
  }

  return null;
}

function getSelectionClasses(selectedPhaseIndex, currentIndex) {
  return {
    'selected': selectedPhaseIndex === currentIndex,
    'dim': selectedPhaseIndex != null && selectedPhaseIndex !== currentIndex
  }
}

function getNodeRectangle(pageNode) {
  var $pageNode = $(pageNode);
  return {
    top: $pageNode.offset().top,
    left: $pageNode.offset().left,
    height: $pageNode.height(),
    width: $pageNode.width()
  };
}

function getSelectedPhaseIndexInitialValue(phases) {
  return phases.length > 0 ? phases.length - 1 : null;
}
