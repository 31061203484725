
// Use for DTT, INTERVAL, and TASK_ANALYSIS type
export interface Phase {
    // Required - All Types
    id: string
    title: string
    prompt: PhasePromptLegacyEnum

    // Required - DTT
    // Doesn't Apply - TASK_ANALYSIS, INTERVAL
    errorless: boolean
    variableITCount: boolean

    // Required - DTT, TASK_ANALYSIS
    // Optional - INTERVAL
    criterionRules: Criterion[]

    // Required - DTT, INTERVAL
    // Doesn't Apply - TASK_ANALYSIS
    totalITCount?: number

    // Required - DTT
    // Optional - INTERVAL
    // Doesn't Apply - TASK_ANALYSIS
    targets: Target[]

    // Required - TASK_ANALYSIS
    // Doesn't Apply - DTT, INTERVAL
    tasks: Task[]

    // Required - INTERVAL
    // Doesn't Apply - DTT, TASK_ANALYSIS
    sampleTime?: number
    defaultResult?: ITResultEnum

    // Optional - All Types
    programDescription?: string
    definition?: string
    sto?: string
    lto?: string
    baseline?: string
    collectionFrequency?: string
    additionalInfo?: string
    reinforcementSchedule?: PhaseReinforcementScheduleEnum
    reinforcement?: number
    reinforcementType?: string
    discriminativeStimulus?: string
}

// Use for FREQUENCY and DURATION type
export interface BehaviorPhase {
    // Required - All Types
    id: string
    name: string
    definition: string

    // Optional - All Types
    shortName?: string
    programDescription?: string
    criterionForMastery?: string
    target?: string
    baseline?: string
    collectionFrequency?: string
    additionalInfo?: string
    createdAt?: Date
    modifiedAt?: Date
    program?: any
    typeRaw?: ProgramTypeEnum
}

export interface Criterion {
    pointsAnalyzed: number
    minPercentage: number
}

export interface Target {
    id: string
    description: string
    type: TargetTypeEnum
}

export interface Task {
    id: string
    taskDescription: string
    isActive: boolean
}

export enum PhaseReinforcementScheduleEnum {
    NONE = 'none',
    FIXED_RATIO = "fixed_ratio",
    VARIABLE_RATIO = "variable_ratio",
    FIXED_INTERVAL = "fixed_interval",
    VARIABLE_INTERVAL = "variable_interval",
    CONTINUOUS = "continuous",
}

export function PhaseReinforcementScheduleEnumLabel(schedule: PhaseReinforcementScheduleEnum): string {
    switch (schedule) {
        case "fixed_ratio": return 'Fixed Ratio (FR)'
        case "variable_ratio": return 'Variable Ratio (VR)'
        case "fixed_interval": return 'Fixed Interval (FI)'
        case "variable_interval": return 'Variable Interval (VI)'
        case "continuous": return 'Continuous'
        default: return 'Choose a reinforcement schedule'
    }
}

export enum TargetTypeEnum {
    CURRENT = "CURRENT",
    FUTURE = "FUTURE",
    MASTERED = "MASTERED"
}

export enum ITResultEnum {
    MINUS = "MINUS",
    NONE = "NONE",
    NOT_APPLICABLE = "NOT_APPLICABLE",
    NOT_YET_IMPLEMENTED = "NOT_YET_IMPLEMENTED",
    PLUS = "PLUS",
}

export function ITResultEnumLabel(result: ITResultEnum): string {
    switch (result) {
        case "MINUS": return '-'
        case "PLUS": return '+'
        case "NOT_APPLICABLE": return 'N/A'
        default: return ''
    }
}

export enum PhasePromptLegacyEnum {
    AUDITORY = "Auditory",
    DEMONSTRATION = "Demonstration",
    FULL_PHYSICAL = "Full physical",
    FULL_VERBAL = "Full verbal",
    GESTURAL = "Gestural",
    INDEPENDENT = "Independent",
    MODEL = "Model",
    NONE = "None",
    NOT_YET_IMPLEMENTED = "Not yet implemented",
    PARTIAL_PHYSICAL = "Partial physical",
    PARTIAL_VERBAL = "Partial verbal",
    PARTIAL_VOCAL_VERBAL = "Partial vocal verbal",
    POSITIONAL = "Positional",
    TEXTUAL = "Textual",
    VOCAL_VERBAL = "Vocal verbal",
}

export enum ProgramTypeEnum {
    DTT = "DTT",
    TASK_ANALYSIS = "TASK_ANALYSIS",
    FREQUENCY = "FREQUENCY",
    DURATION = "DURATION",
    INTERVAL = "INTERVAL",
    NONE = "NONE",
    NOT_YET_IMPLEMENTED = "NOT_YET_IMPLEMENTED",
}
