export const setAccessToken = (accessToken) => {
  return {
    type: 'SET_ACCESS_TOKEN',
    accessToken: accessToken
  }
};

export const setAuthTokens = (refreshToken, accessToken, realmPath, userName, userAvatar, userId) => {
  return {
    type: 'SET_AUTH_TOKENS',
    refreshToken: refreshToken,
    accessToken: accessToken,
    realmPath: realmPath,
    userName: userName,
    userAvatar: userAvatar,
    userId: userId
  }
};