function appReducer(state, action) {
  if (state == null) {
    return {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      realmPath: localStorage.getItem('realmPath'),
      userName: localStorage.getItem('userName'),
      userAvatar: localStorage.getItem('userAvatar'),
      userId: localStorage.getItem('userId')
    }
  }

  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return Object.assign({}, state, {
        accessToken: action.accessToken
      });
    case 'SET_AUTH_TOKENS':
      return Object.assign({}, state, {
        refreshToken: action.refreshToken,
        accessToken: action.accessToken,
        realmPath: action.realmPath,
        userName: action.userName,
        userAvatar: action.userAvatar,
        userId: action.userId
      });
    default:
      return state;
  }
}

export default appReducer;