function getMainUrl() {
  const loggedIntoStitch = localStorage.getItem('authProvider') === 'stitch';
  if (loggedIntoStitch) {
    return process.env.REACT_APP_EMAIL_REST_API_URL
  } else {
    return process.env.REACT_APP_TEAM_REST_API_URL
  }
}

function getAuthUrl() {
  return process.env.REACT_APP_TEAM_AUTH_URL
}

var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function Endpoints() {
  const mainUrl = getMainUrl();
  const authUrl = getAuthUrl();
  return {
    auth: {
      url: `${authUrl}/auth`,
      method: 'post',
      isSecure: false
    },
    logout: {
      url: `${authUrl}/auth/revoke`,
      method: 'post',
      isSecure: true
    },
    students: {
      url: `${mainUrl}/api/students`,
      method: 'get',
      isSecure: true
    },
    studentPrograms: {
      url: `${mainUrl}/api/student-programs/:studentId`,
      params: {
        timezone: timeZone
      },
      method: 'get',
      isSecure: true
    },
    studentProgress: {
      url: `${mainUrl}/api/student-progress/:studentId/:programId`,
      params: {
        timezone: timeZone
      },
      method: 'get',
      isSecure: true
    },
    downloadDocx: {
      url: `${mainUrl}/api/download-docx/:studentId`,
      headers: {
        'Content-Type': 'application/docx',
      },
      params: {
        timezone: timeZone,
        startDate: null,
        endDate: null
      },
      responseType: 'blob',
      method: 'get',
      isSecure: true
    },
    downloadSessionXlsx: {
      url: `${mainUrl}/api/download-session`,
      headers: {
        'Content-Type': 'application/xlsx',
      },
      params: {
        timezone: timeZone
      },
      responseType: 'blob',
      method: 'get',
      isSecure: true
    },
    downloadXlsx: {
      url: `${mainUrl}/api/download-xlsx/:studentId`,
      headers: {
        'Content-Type': 'application/xlsx',
      },
      params: {
        timezone: timeZone
      },
      responseType: 'blob',
      method: 'get',
      isSecure: true
    },
    note: {
      url: `${mainUrl}/api/note/:studentId`,
      params: {
        timezone: timeZone
      },
      method: 'put',
      isSecure: true
    },
    updatePhase: {
      url: `${mainUrl}/api/update-phase/:studentId/:phaseId`,
      method: 'put',
      isSecure: true
    },
    createPhase: {
      url: `${mainUrl}/api/create-phase/:studentId/:programId`,
      method: 'post',
      isSecure: true
    },
    updateBehavior: {
      url: `${mainUrl}/api/update-behavior/:studentId/:behaviorPhaseId`,
      method: 'put',
      isSecure: true
    },
    changePassword: {
      url: `${mainUrl}/api/change-password/:path`,
      method: 'post',
      isSecure: true
    }
  }
}