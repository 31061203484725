import React from 'react';
import _ from 'lodash';

const CANVAS = document.createElement('canvas');

class Utils {
  static getErrorNode(height, componentName, heightLimit, centimeters) {
    heightLimit = heightLimit || 227;
    centimeters = centimeters || 9;
    if (height < heightLimit) {
      return (
        <div className="error">
          <div>The paper is too small to display the {componentName}.</div>
          <div>Please, choose a paper that has a height greater than {centimeters} centimeters.</div>
        </div>
      )
    }

    return null;
  }

  static getRowNumber(string, blockWidth, fontSize) {
    const font = `400 ${fontSize || 17}px "Helvetica Neue", sans-serif`;

    var context = CANVAS.getContext('2d');
    context.font = font;

    var wordsArray = Utils.split(string),
      rows = 0,
      counter,
      test,
      testWidth;

    while (!_.isEmpty(wordsArray)) {
      counter = 0;
      test = wordsArray[counter].substring(1);
      testWidth = context.measureText(test).width;

      do {
        counter++;

        if (counter < wordsArray.length) {
          test += wordsArray[counter];
          testWidth = context.measureText(test).width;
        }
      } while (testWidth < blockWidth && counter < wordsArray.length);

      rows++;
      wordsArray.splice(0, counter);
    }

    return rows;
  }

  static split(string) {
    var wordsArray = string.split(' ').filter((str) => !_.isEmpty(str)),
      result = [];

    wordsArray.forEach((word) => {
      var parts,
        part,
        index;

      if (word.indexOf('-') === -1) {
        result.push(` ${word}`);
      } else {
        parts = [];

        while (word !== '') {
          if (word[0] !== '-' || (word[0] === '-' && word.length > 1 && word[1] !== '-')) {
            index = word.indexOf('-', 1);
            if (index === -1) {
              part = word;
              word = '';
            } else {
              part = word.substring(0, index + 1);
              word = word.substring(index + 1);
            }
            parts.push(part);
          } else {
            part = '-';
            parts.push(part);
            word = word.substring(1);
          }
        }

        parts[0] = ' ' + parts[0];
        result.push(parts);
      }
    });

    return _.flatten(result);
  }
}

export default Utils;