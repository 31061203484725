import { Button, Card, Checkbox, Col, Form, FormItem, Grid, Heading, Image, Tag, Dropdown, InputTextArea, Input, List, ListItem, Menu, MenuItem, Row, Switch, Tooltip, RadioGroup, Radio, Modal, InputNumber, Select } from '@raudabaugh/thread-ui';
import * as React from 'react';
import {
    Icon,
} from '@material-ui/core';
import {
    TargetIcon,
    MasteryIcon,
} from '../Icons';
import _ from 'lodash';
import { PhasePromptLegacyEnum, ITResultEnum, Criterion, Task, Target, TargetTypeEnum, PhaseReinforcementScheduleEnum, PhaseReinforcementScheduleEnumLabel, ProgramTypeEnum, ITResultEnumLabel } from './../ApiTypes'

import ReduxHelpers from '../../Utils/ReduxHelpers';
import { IAuthDispatchProps, IAuthStateProps } from '../../Utils/ReduxProps'
import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';
import { connect } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import * as uuidv4 from 'uuid/v4';

export interface ISkillsFormPropsBase {
    updateParent: (boolean: boolean) => void,
    formType: ProgramTypeEnum,
    onClose: () => void,
    open: boolean,
    isLastPhase: boolean
    studentId: string,
    studentName: string,
    phaseId?: string,
    programId?: string,
    values?: any
}
type ISkillsFormProps = ISkillsFormPropsBase & IAuthStateProps & IAuthDispatchProps;

interface ISkillsFormState {
    programName: string
    procedure: string
    discriminativeStimulus: string
    prompt: PhasePromptLegacyEnum
    target: string
    targetsList: Target[]
    criterionsList: Criterion[]
    criterion: string
    reinforcementType: string
    seconds: string
    lto: string
    sto: string
    shortName: string
    baseline: string
    collectionFrequency: string
    definition: string
    criterionForMastery: string
    additionalInfo: string
    editName: boolean
    totalITCount: number
    sampleTime: number
    reinforcementSchedule: PhaseReinforcementScheduleEnum
    errorless: boolean
    variableITCount: boolean
    tasks: Task[],
    defaultResult: ITResultEnum
    reinforcement: number
    saveAlertOpen: boolean
    cancelAlertOpen: boolean
    reinforcementInterval: string

}

class SkillsForm extends React.Component<ISkillsFormProps, ISkillsFormState> {
    private cancelTokenSource: CancelTokenSource
    private inputRef: React.RefObject<HTMLElement> = React.createRef();
    constructor(props: ISkillsFormProps) {
        super(props);
        this.state = this.buildStateFromProps(props);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.closeSaveAlert = this.closeSaveAlert.bind(this);
        this.closeCancelAlert = this.closeCancelAlert.bind(this);
        this.closeCancelAlertAndDialog = this.closeCancelAlertAndDialog.bind(this);
        this.cancelTokenSource = axios.CancelToken.source();
        this.handleSelectPrompt = this.handleSelectPrompt.bind(this);
        this.updateCriterionList = this.updateCriterionList.bind(this);
        this.updateTargetList = this.updateTargetList.bind(this);
        this.updateTasksList = this.updateTasksList.bind(this);
        this.save = this.save.bind(this);
        this.handleCriterionChange = this.handleCriterionChange.bind(this);
        this.handleReinforcementSchedule = this.handleReinforcementSchedule.bind(this);
        this.inputRef = React.createRef();
    }
    public componentWillReceiveProps(nextProps: ISkillsFormProps) {
        this.setState(this.buildStateFromProps(nextProps));
    }

    public inputFocus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }
    }
    public render() {

        const discreteTrial = this.props.formType === ProgramTypeEnum.DTT;
        const taskAnalysis = this.props.formType === ProgramTypeEnum.TASK_ANALYSIS;
        const interval = this.props.formType === ProgramTypeEnum.INTERVAL;
        const disableSave = !this.isValid();

        let targets = this.state.targetsList.map((target, index) => {
            return (
                <ListItem key={index}>
                    <Row type='flex' align='middle' grow={3} justify='space-between'>
                        <TargetIcon className="icon" />
                        <Col span={10}>
                            <Input onPressEnter={this.updateTargetList.bind(this)} size='small' placeholder='' value={target.description} onChange={this.handleTargetsChange.bind(this, index, 'description')} />
                        </Col>
                        <Col grow={1} margin='0 12px'>
                            <RadioGroup
                                value={target.type}
                                onChange={this.handleTargetsChange.bind(this, index, 'type')}>
                                <Radio value={TargetTypeEnum.CURRENT}>Current</Radio>
                                <Radio value={TargetTypeEnum.MASTERED}>Mastered</Radio>
                                <Radio value={TargetTypeEnum.FUTURE}>Future</Radio>
                            </RadioGroup>
                        </Col>
                        <Icon color='error' onClick={this.removeTarget.bind(this, index)}>clear</Icon>&nbsp;
                    </Row>
                </ListItem>
            )
        });

        let intervalMinutesOrSeconds = (<Menu
            onClick={(value) => this.setState({ reinforcementInterval: value.key })}
            selectedKeys={[this.state.reinforcementInterval]}
        >
            <MenuItem key={'Minutes'}>Minutes</MenuItem>
            <MenuItem key={'Seconds'}>Seconds</MenuItem>
        </Menu>)

        let seconds = (<Menu
            onClick={(value) => this.setState({ seconds: value.key })}
            selectedKeys={[this.state.seconds]}
        >
            <MenuItem key={'Minutes'}>Minutes</MenuItem>
            <MenuItem key={'Seconds'}>Seconds</MenuItem>
        </Menu>)

        let criterions = this.state.criterionsList.map((criterion, index: number) => {
            let fiftyToOneHundred = (<Menu
                onClick={this.handleCriterionChange.bind(this, 'minPercentage', index)}
                selectedKeys={[this.state.criterionsList[index].minPercentage.toString()]}
            >
                <MenuItem key={'100'} >100</MenuItem>
                <MenuItem key={'95'} >95</MenuItem>
                <MenuItem key={'90'} >90</MenuItem>
                <MenuItem key={'85'} >85</MenuItem>
                <MenuItem key={'80'} >80</MenuItem>
                <MenuItem key={'75'} >75</MenuItem>
                <MenuItem key={'70'} >70</MenuItem>
                <MenuItem key={'65'} >65</MenuItem>
                <MenuItem key={'60'} >60</MenuItem>
                <MenuItem key={'55'} >55</MenuItem>
                <MenuItem key={'50'} >50</MenuItem>
            </Menu>)

            let oneToTen = (
                <Menu
                    onClick={this.handleCriterionChange.bind(this, 'pointsAnalyzed', index)}
                    selectedKeys={[this.state.criterionsList[index].pointsAnalyzed.toString()]}
                >
                    <MenuItem key={'1'} >1</MenuItem>
                    <MenuItem key={'2'} >2</MenuItem>
                    <MenuItem key={'3'} >3</MenuItem>
                    <MenuItem key={'4'} >4</MenuItem>
                    <MenuItem key={'5'} >5</MenuItem>
                    <MenuItem key={'6'} >6</MenuItem>
                    <MenuItem key={'7'} >7</MenuItem>
                    <MenuItem key={'8'} >8</MenuItem>
                    <MenuItem key={'9'} >9</MenuItem>
                    <MenuItem key={'10'} >10</MenuItem>
                    <MenuItem key={'11'} >11</MenuItem>
                    <MenuItem key={'12'} >12</MenuItem>
                    <MenuItem key={'13'} >13</MenuItem>
                    <MenuItem key={'14'} >14</MenuItem>
                    <MenuItem key={'15'} >15</MenuItem>
                    <MenuItem key={'16'} >16</MenuItem>
                    <MenuItem key={'17'} >17</MenuItem>
                    <MenuItem key={'18'} >18</MenuItem>
                    <MenuItem key={'19'} >19</MenuItem>
                    <MenuItem key={'20'} >20</MenuItem>
                    <MenuItem key={'21'} >21</MenuItem>
                    <MenuItem key={'22'} >22</MenuItem>
                    <MenuItem key={'23'} >23</MenuItem>
                    <MenuItem key={'24'} >24</MenuItem>
                    <MenuItem key={'25'} >25</MenuItem>
                </Menu>)
            if (index !== this.state.criterionsList.length - 1) {
                return (
                    <ListItem key={index}>
                        <Row type='flex' align='middle' grow={1}>
                            <MasteryIcon className="icon" />
                            <Col grow={1}>
                                <Heading level={6} margin='0'>{this.state.criterionsList[index].pointsAnalyzed} {this.state.criterionsList[index].pointsAnalyzed === 1 ? 'score of' : 'scores of'} {this.state.criterionsList[index].minPercentage}%</Heading>
                            </Col>
                            <Icon color='error' onClick={this.removeCriterion.bind(this, criterion)}>clear</Icon> &nbsp;
                        </Row>
                    </ListItem>)
            } else {
                return (
                    <ListItem key={index}>
                        <Row type='flex' align='middle' grow={1}>
                            <MasteryIcon className="icon" />
                            <Col>
                                <Dropdown trigger={['click']} overlay={oneToTen}>
                                    <Tag>{this.state.criterionsList[index].pointsAnalyzed}</Tag>
                                </Dropdown>
                            </Col>
                            <Heading level={6} margin='0'>{this.state.criterionsList[index].pointsAnalyzed === 1 ? 'score of' : 'scores of'}</Heading>
                            <Col padding={'0 0 0 6px'} grow={1}>
                                <Dropdown trigger={['click']} overlay={fiftyToOneHundred}>
                                    <Tag>{this.state.criterionsList[index].minPercentage}</Tag>
                                </Dropdown>%
                            </Col>
                            <Icon color='error' onClick={this.removeCriterion.bind(this, criterion)}>clear</Icon> &nbsp;
                        </Row>
                    </ListItem>
                )
            }
        });

        let ratio = this.state.reinforcementSchedule === PhaseReinforcementScheduleEnum.FIXED_RATIO || this.state.reinforcementSchedule === PhaseReinforcementScheduleEnum.VARIABLE_RATIO;

        const defaultTrialResultOptions: ITResultEnum[] = [ITResultEnum.NOT_APPLICABLE, ITResultEnum.PLUS, ITResultEnum.MINUS]
        const reinforcementScheduleOptions: PhaseReinforcementScheduleEnum[] = [PhaseReinforcementScheduleEnum.NONE, PhaseReinforcementScheduleEnum.FIXED_RATIO, PhaseReinforcementScheduleEnum.VARIABLE_RATIO, PhaseReinforcementScheduleEnum.FIXED_INTERVAL, PhaseReinforcementScheduleEnum.VARIABLE_INTERVAL, PhaseReinforcementScheduleEnum.CONTINUOUS]
        return (

            <Modal
                visible={this.props.open}
                onCancel={this.handleDialogClose}
                width={'80%'}
                destroyOnClose={true}
                afterClose={() => this.setState({ editName: false })}
                title={<>
                    <Grid onClick={() => this.setState({ editName: !this.state.editName }, this.inputFocus)}>
                        <Row type='flex' justify='space-between'>
                            <Col grow={0} margin="0 16px 0 0">
                                <Row type='flex' justify='center'>
                                    <Image src={Utils.programTypeImage(this.props.formType)} />
                                </Row>
                                <Row type='flex' justify='center'>
                                    <Heading level={6}>{this.formatProgramType()}</Heading>
                                </Row>
                            </Col>
                            <Col grow={1}>
                                <Row type='flex' justify='center'>
                                    <Heading level={3} margin="8px 0 0 0">{this.props.studentName}</Heading>
                                </Row>
                                <Row type='flex' justify='center'>
                                    {this.state.editName ?
                                        <Input innerRef={this.inputRef} placeholder='' onPressEnter={() => this.setState({ editName: !this.state.editName })} onChange={this.handleSimpleInputChange.bind(this, 'programName')} value={this.state.programName} />
                                        :
                                        <Heading level={1} color={this.state.programName === '' ? 'error' : undefined} margin='-8px 0 0 0'>{this.state.programName === '' ? 'Enter Program Name Here' : this.state.programName}</Heading>}
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </>}
                footer={
                    <Row type='flex' justify='space-between'>
                        <Col margin='8px 0'>
                            <Button
                                type="danger"
                                onClick={this.handleCancel}
                            >
                                Cancel
                                </Button>
                        </Col>
                        <Col margin='8px 0'>
                            <Button
                                type="primary"
                                onClick={this.handleSave}
                                disabled={disableSave}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Card padding={'4px'} color='default' onClick={() => this.setState({ editName: false })}>
                    <Row>
                        <Col margin='8px'>
                            <Form>
                                {(discreteTrial || interval) && !this.state.variableITCount && <FormItem label='Number of Trials:'>
                                    <Row>
                                        <InputNumber placeholder='Number of Trials' value={this.state.totalITCount} onChange={this.handleNumberOfTrials.bind(this)} />
                                    </Row>
                                </FormItem>}
                                {discreteTrial && <FormItem label='Unlimited Trials:'>
                                    <Row>
                                        <Switch checked={this.state.variableITCount} onChange={() => this.setState({ variableITCount: !this.state.variableITCount })} />
                                    </Row>
                                </FormItem>}
                                {interval && <FormItem label='Default Trial Result:'>
                                    <Select dropdownMatchSelectWidth value={this.state.defaultResult} onChange={(value: any) => this.setState({ defaultResult: value })}>
                                        {defaultTrialResultOptions.map((result) =>
                                            <Select.Option key={result} >{ITResultEnumLabel(result)}</Select.Option>
                                        )}
                                    </Select>
                                </FormItem>}
                                {interval && <FormItem>
                                    <Heading level={5} weight="medium" color={this.state.sampleTime > 0 ? undefined : 'error'}>Length of Each Interval: </Heading>
                                    <Row type='flex' align='middle'>
                                        <InputNumber placeholder={''} value={this.state.sampleTime > 0 ? this.state.sampleTime : undefined} onChange={this.handleSecondsCount.bind(this)} />
                                        <Col margin='0 0 0 5px'>
                                            <Dropdown trigger={['click']} overlay={seconds}>
                                                <Tag>{this.state.seconds}</Tag>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </FormItem>}
                                <FormItem>
                                    <Tooltip title={this.validCriterionList()}>
                                        <Heading level={5} weight="medium" color={this.validCriterionList() === "" ? undefined : 'error'}>Criterion For Mastery: </Heading>
                                    </Tooltip>
                                    <Grid color='default' variation={1}>
                                        <List size="small">
                                            {criterions}
                                        </List>
                                    </Grid>
                                    <Col margin='4px 0 0 0'>
                                        <Button onClick={this.updateCriterionList} >Add Criterion</Button>
                                    </Col>
                                </FormItem>
                                <FormItem label={'Definition'}>
                                    <InputTextArea value={this.state.definition} onChange={this.handleSimpleInputChange.bind(this, 'definition')} />
                                </FormItem>
                                <FormItem label={'Procedure'}>
                                    <InputTextArea value={this.state.procedure} onChange={this.handleSimpleInputChange.bind(this, 'procedure')} />
                                </FormItem>
                                <FormItem label={this.state.errorless ? `Least Intrusive Prompt` : `Prompt`}>
                                    <Select dropdownMatchSelectWidth value={this.state.prompt} onChange={this.handleSelectPrompt}>
                                        {Object.keys(PhasePromptLegacyEnum).filter(prompt =>
                                            prompt !== 'NONE' && prompt !== 'NOT_YET_IMPLEMENTED'
                                        ).map((prompt) =>
                                            <Select.Option key={PhasePromptLegacyEnum[prompt]} >{PhasePromptLegacyEnum[prompt]}</Select.Option>
                                        )}
                                    </Select>
                                </FormItem>

                                {discreteTrial && <FormItem label={'Errorless Teaching: '}>
                                    <Row type='flex' align='middle'>
                                        <Col margin='0 0 0 5px'>
                                            <Switch children={<Col>{this.state.errorless}</Col>} defaultChecked={this.state.errorless} onChange={() => this.setState({ errorless: !this.state.errorless })} />
                                        </Col>
                                    </Row>
                                </FormItem>}
                                <FormItem label='Additional Condition Information'>
                                    <Input value={this.state.additionalInfo} placeholder="" onChange={this.handleSimpleInputChange.bind(this, 'additionalInfo')} />
                                </FormItem>
                                {taskAnalysis && <FormItem>

                                    <Tooltip title={this.validTasks()}>
                                        <Heading level={5} weight="medium" color={this.validTasks() === "" ? undefined : 'error'}>Tasks: </Heading>
                                    </Tooltip>
                                    <Grid color='default' variation={2}>
                                        <List size="small">
                                            {this.state.tasks.map((task, index) => (
                                                <ListItem key={index}>
                                                    <Row justify='space-between' key={index} type='flex' align='middle' grow={1}>
                                                        <Col padding='0 12px'>{index + 1}.</Col>
                                                        <Col grow={3}>
                                                            <Input size='small' key={index} onPressEnter={this.updateTasksList.bind(this)} placeholder='' value={task.taskDescription} onChange={this.updateTask.bind(this, index, 'taskDescription')} />
                                                        </Col> &nbsp;
                                                        <Col grow={1}>
                                                            &nbsp;<Checkbox onChange={this.updateTask.bind(this, index, 'isActive')} checked={task.isActive} />
                                                        </Col>
                                                        <Col span={1}>{index > 0 && <Icon color='error' onClick={this.removeTask.bind(this, index)}>clear</Icon>}</Col>
                                                    </Row>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Col margin='5px 0'><Button onClick={this.updateTasksList} >Add Task</Button></Col>
                                </FormItem>}
                                {(discreteTrial || interval) && <FormItem>

                                    <Tooltip title={this.validTargetList()}>
                                        <Heading level={5} weight="medium" color={interval || this.validTargetList() === "" ? undefined : 'error'}>Targets: </Heading>
                                    </Tooltip>
                                    <Grid color='default' variation={2}>
                                        <List>
                                            {targets}
                                        </List>
                                    </Grid>
                                    <Col margin='5px 0'><Button onClick={this.updateTargetList} >Add Target</Button></Col>
                                </FormItem>}
                                <FormItem label='Discriminative Stimulus'>
                                    <InputTextArea value={this.state.discriminativeStimulus} onChange={this.handleSimpleInputChange.bind(this, 'discriminativeStimulus')} />
                                </FormItem>
                                <FormItem label={`Reinforcement Schedule`}>
                                    <Select dropdownMatchSelectWidth value={this.state.reinforcementSchedule} onChange={this.handleReinforcementSchedule}>
                                        {reinforcementScheduleOptions.map((schedule) =>
                                            <Select.Option key={schedule} >{PhaseReinforcementScheduleEnumLabel(schedule)}</Select.Option>
                                        )}
                                    </Select>
                                </FormItem>
                                {this.state.reinforcementSchedule !== PhaseReinforcementScheduleEnum.CONTINUOUS && this.state.reinforcementSchedule !== PhaseReinforcementScheduleEnum.NONE &&
                                    <FormItem label={ratio ? 'Ratio' : 'Reinforcement Interval'}>
                                        <Row type='flex' align='middle'>
                                            {ratio ? <InputNumber placeholder='' value={this.state.reinforcement} onChange={(value) => this.setState({ reinforcement: value! })} />
                                                : <>
                                                    <InputNumber placeholder='' value={this.state.reinforcement} onChange={(value) => this.setState({ reinforcement: value! })} />
                                                    <Dropdown trigger={['click']} overlay={intervalMinutesOrSeconds}>
                                                        <Button>{this.state.reinforcementInterval}</Button>
                                                    </Dropdown>
                                                </>
                                            }
                                        </Row>
                                    </FormItem>
                                }
                                <FormItem label='Type of Reinforcement'>
                                    <InputTextArea value={this.state.reinforcementType} onChange={this.handleSimpleInputChange.bind(this, 'reinforcementType')} />
                                </FormItem>
                                <FormItem label={'Baseline Date/Score'}>
                                    <Input value={this.state.baseline} placeholder="" onChange={this.handleSimpleInputChange.bind(this, 'baseline')} />
                                </FormItem>
                                <FormItem label={'Frequency of Data Collection'}>
                                    <Input value={this.state.collectionFrequency} placeholder="" onChange={this.handleSimpleInputChange.bind(this, 'collectionFrequency')} />
                                </FormItem>
                                <FormItem label='Short Term Objective'>
                                    <Input value={this.state.sto} placeholder="" onChange={this.handleSimpleInputChange.bind(this, 'sto')} />
                                </FormItem>
                                <FormItem label='Long Term Objective'>
                                    <Input value={this.state.lto} placeholder="" onChange={this.handleSimpleInputChange.bind(this, 'lto')} />
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                </Card>
                <Modal
                    visible={this.state.saveAlertOpen}
                    onCancel={this.closeSaveAlert}
                    title={'Would you like to create a Phase Change Line?'}
                    footer={
                        <>
                            <Button type="primary"
                                onClick={this.save.bind(this, true)}>Yes</Button>
                            <Button type="primary"
                                onClick={this.save.bind(this, false)}>No</Button>
                            <Button type="ghost"
                                onClick={this.closeSaveAlert}>Cancel</Button>
                        </>
                    }
                >
                </Modal>
                <Modal visible={this.state.cancelAlertOpen}
                    onCancel={this.closeCancelAlert}
                    title={'Discard all changes and close Edit Program dialog?'}
                    footer={<>
                        <Button type="primary"
                            onClick={this.closeCancelAlertAndDialog}>Yes</Button>
                        <Button type="ghost"
                            onClick={this.closeCancelAlert}>No</Button>
                    </>}>
                </Modal>
            </Modal>
        );
    }

    private isValid() {
        const discreteTrial = this.props.formType === ProgramTypeEnum.DTT;
        const taskAnalysis = this.props.formType === ProgramTypeEnum.TASK_ANALYSIS;
        const interval = this.props.formType === ProgramTypeEnum.INTERVAL;
        if (this.state.programName === '') {
            // Name is required
            return false;
        }
        if (discreteTrial) {
            // At least one target must be specified
            if (this.validTargetList() !== '') {
                // No targets
                return false;
            }
        }
        if (discreteTrial || taskAnalysis) {
            // At least one criterion must be specified
            if (this.validCriterionList() !== '') {
                // No criterion
                return false;
            }
        }
        if (taskAnalysis) {
            if (this.validTasks() !== '') {
                return false
            }
        }

        if (interval) {
            if (this.state.sampleTime <= 0) {
                return false
            }
        }

        if (discreteTrial || interval) {
            if (this.state.totalITCount <= 0) {
                return false
            }
        }
        return true;
    }

    private formatProgramType() {
        switch (this.props.formType) {
            case ProgramTypeEnum.DTT:
                return "Discrete Trial";

            case ProgramTypeEnum.TASK_ANALYSIS:
                return "Task Analysis";

            default:
                return this.props.formType.substring(0, 1) +
                    this.props.formType.substring(1).toLowerCase();
        }
    }

    private validCriterionList() {
        if (this.state.criterionsList.length < 1 && (this.props.formType === ProgramTypeEnum.DTT || this.props.formType === ProgramTypeEnum.TASK_ANALYSIS)) {
            return "Must have at least one criterion";
        }
        return "";
    }


    private descriptionCheck(element) {
        return element.description === '';
    }

    private validTargetList() {
        if (this.state.targetsList.length < 1 && this.props.formType === ProgramTypeEnum.DTT) {
            return "Must have at least one target";
        }
        return "";
    }

    private validTasks() {
        if (this.state.tasks[0].taskDescription === '') {
            return "Must have a task description";
        }
        return "";
    }


    private handleDialogClose(event) {
        this.stopPropagation(event);

        this.setState({
            cancelAlertOpen: true
        });
    };

    private handleCancel() {
        this.setState({
            cancelAlertOpen: true
        });
    };

    private handleSave() {
        if (!this.props.programId) {
            this.save(true, undefined);
        } else if (this.props.isLastPhase) {
            this.setState({
                saveAlertOpen: true
            });
        } else {
            this.save(false, undefined);
        }
    };

    private closeSaveAlert(event) {
        this.stopPropagation(event);

        this.setState({
            saveAlertOpen: false
        });
    };

    private closeCancelAlert(event) {
        this.stopPropagation(event);

        this.setState({
            cancelAlertOpen: false
        });
    };

    private closeCancelAlertAndDialog(event) {
        this.stopPropagation(event);

        this.closeCancelAlert(undefined);
        this.props.onClose();
    };

    private handleCriterionChange(fieldName, index, event) {
        let savedTarget = parseInt(event.key);
        let criterionsList = this.state.criterionsList;
        this.setState(() => {
            if (savedTarget && (fieldName === 'pointsAnalyzed' && savedTarget <= 10) || (fieldName === 'minPercentage' && savedTarget <= 100)) {
                criterionsList[index][fieldName] = savedTarget;
            } else if (savedTarget && fieldName === 'pointsAnalyzed') {
                criterionsList[index][fieldName] = 10;
            } else if (savedTarget && fieldName === 'minPercentage') {
                criterionsList[index][fieldName] = 100;
            } else {
                criterionsList[index][fieldName] = 0;
            }
            return {
                criterionsList: criterionsList
            }
        })
    }
    private updateTargetList() {
        this.setState({ targetsList: this.state.targetsList.concat({ id: uuidv4(), description: '', type: TargetTypeEnum.CURRENT }) })
    }
    private updateTasksList() {
        this.setState({ tasks: this.state.tasks.concat({ id: uuidv4(), taskDescription: '', isActive: true }) })
    }
    private updateCriterionList() {
        this.setState({ criterionsList: this.state.criterionsList.concat({ pointsAnalyzed: 1, minPercentage: 100 }) })
    }
    private handleSimpleInputChange(field: string, event) {
        this.setState({ [field]: event.target.value } as ISkillsFormState);
    }
    private handleTargetsChange(index, action, event) {
        let value = event.target.value;
        this.setState((prevState) => {
            let targetsList;
            targetsList = _.cloneDeep(prevState.targetsList);
            targetsList[index][action] = value;

            targetsList = Object.assign({}, prevState, {
                targetsList: targetsList
            })
            return {
                targetsList: targetsList.targetsList
            };
        })
    }
    private removeCriterion(criterion) {
        this.setState((prevState) => {
            let index = _.findIndex(prevState.criterionsList, criterion);
            let criterionsList = _.cloneDeep(prevState.criterionsList);

            criterionsList.splice(index, 1);

            return {
                criterionsList: criterionsList
            }
        });
    }
    private removeTarget(index) {
        this.setState((prevState) => {
            let targetList = _.cloneDeep(prevState.targetsList);

            targetList.splice(index, 1);

            return {
                targetsList: targetList
            }
        });
    }
    private handleSelectPrompt(prompt) {
        this.setState({ prompt: prompt })
    }

    private handleReinforcementSchedule(reinforcement) {
        this.setState({ reinforcementSchedule: reinforcement })
    }

    private handleNumberOfTrials(value: number | undefined) {
        value === undefined ? this.setState({ totalITCount: 20 })
            : this.setState({ totalITCount: value })
    }

    private handleSecondsCount(value: number | undefined) {
        value === undefined ? this.setState({ sampleTime: 0 })
            : this.setState({ sampleTime: value });
    }

    private updateTask(index, action, event) {
        let value = event.target.value;
        this.setState((prevState) => {
            let task;
            task = _.cloneDeep(prevState.tasks);
            task[index][action] = action === 'isActive' ? !task[index].isActive : value;

            task = Object.assign({}, prevState, {
                tasks: task
            })
            return {
                tasks: task.tasks
            };
        })
    }
    private removeTask(index) {
        this.setState((prevState) => {
            let tasksList = _.cloneDeep(prevState.tasks);
            tasksList.splice(index, 1);
            return {
                tasks: tasksList
            }
        });
    }

    private save(shouldCreateNewPhase, event) {

        this.stopPropagation(event);

        this.cancelTokenSource.cancel();
        this.cancelTokenSource = axios.CancelToken.source();

        const discreteTrial = this.props.formType === ProgramTypeEnum.DTT;
        const taskAnalysis = this.props.formType === ProgramTypeEnum.TASK_ANALYSIS;
        const interval = this.props.formType === ProgramTypeEnum.INTERVAL;
        let body = {
            id: this.props.phaseId ? this.props.phaseId : uuidv4(),
            title: this.state.programName,
            prompt: this.state.prompt,
            errorless: discreteTrial ? this.state.errorless : false,
            variableITCount: discreteTrial ? this.state.variableITCount : false,
            criterionRules: this.state.criterionsList || null,
            totalITCount: discreteTrial || interval ? this.state.totalITCount : undefined,
            targets: discreteTrial || interval ? this.state.targetsList.filter((value) => !this.descriptionCheck(value)) : [],
            tasks: taskAnalysis ? this.state.tasks : [],
            sampleTime: interval ?
                this.state.seconds === 'Seconds' ?
                    this.state.sampleTime : this.state.sampleTime * 60 : undefined,
            defaultResult: interval ? this.state.defaultResult : undefined,
            programDescription: this.state.procedure || undefined,
            definition: this.state.definition || undefined,
            sto: this.state.sto || undefined,
            lto: this.state.lto || undefined,
            baseline: this.state.baseline || undefined,
            collectionFrequency: this.state.collectionFrequency || undefined,
            additionalInfo: this.state.additionalInfo || undefined,
            reinforcementSchedule: this.state.reinforcementSchedule || undefined,
            reinforcement: this.state.reinforcement,
            reinforcementType: this.state.reinforcementType || undefined,
            discriminativeStimulus: this.state.discriminativeStimulus || undefined,
            type: this.props.formType || null,
            studentId: this.props.studentId,
        }
        let request = shouldCreateNewPhase ? Utils.axios(Endpoints().createPhase, {
            data: body,
            urlParams: {
                programId: this.props.programId,
                studentId: this.props.studentId
            },
            accessToken: localStorage['accessToken'],
            cancelToken: this.cancelTokenSource.token
        }) : Utils.axios(Endpoints().updatePhase, {
            data: body,
            urlParams: {
                phaseId: this.props.phaseId,
                studentId: this.props.studentId
            },
            accessToken: localStorage['accessToken'],
            cancelToken: this.cancelTokenSource.token
        });

        request.then((response) => {
            if (response.data.success) {
                this.props.updateParent(false);
            }
        }).catch((error) => {
            Utils.handleAxiosError('SkillsForm', error, this.props.removeAccessToken,
                this.props.removeAuthTokens).catch(() => {
                });
        });
        this.props.onClose();
    }

    private buildStateFromProps(props: ISkillsFormProps) {
        if (props.values) {
            return {
                programName: props.values.programName,
                procedure: props.values.procedure,
                discriminativeStimulus: props.values.discriminativeStimulus,
                prompt: props.values.prompt,
                targetsList: props.values.masteredTargets.map(
                    (master) => { let newMaster = master; newMaster.type = TargetTypeEnum.MASTERED; return newMaster }).concat(
                        props.values.currentTargets.map(
                            (current) => { let newCurrent = current; newCurrent.type = TargetTypeEnum.CURRENT; return newCurrent }).concat(
                                props.values.futureTargets.map(
                                    (future) => { let newFuture = future; newFuture.type = TargetTypeEnum.FUTURE; return newFuture }
                                )
                            )
                    ),
                criterion: '',
                criterionsList: props.values.criterions,
                reinforcement: props.values.reinforcement || 0,
                reinforcementType: props.values.reinforcementType,
                errorless: props.values.errorless !== undefined ? props.values.errorless : false,
                tasks: props.values.tasks,
                sto: props.values.sto || '',
                additionalInfo: props.values.additionalInfo || '',
                reinforcementSchedule: props.values.reinforcementSchedule || PhaseReinforcementScheduleEnum.NONE,
                criterionForMastery: props.values.criterionForMastery || '',
                target: props.values.target || '',
                shortName: props.values.shortName || '',
                definition: props.values.definition || '',
                defaultResult: props.values.defaultResult || ITResultEnum.NOT_APPLICABLE,
                variableITCount: props.values.variableITCount || false,
                totalITCount: props.values.totalITCount || 20,
                lto: props.values.lto || '',
                baseline: props.values.baseline || '',
                seconds: 'Seconds',
                collectionFrequency: props.values.collectionFrequency || '',
                editName: false,
                sampleTime: this.props.values.sampleTime ?
                    this.props.values.sampleTime % 60 === 0 ?
                        this.props.values.sampleTime / 60 :
                        this.props.values.sampleTime : 0,
                saveAlertOpen: false,
                cancelAlertOpen: false,
                reinforcementInterval: 'Seconds',
            }
        }
        else {
            return {
                programName: '',
                procedure: '',
                criterionsList: [{ pointsAnalyzed: 1, minPercentage: 100 }, { pointsAnalyzed: 2, minPercentage: 90 }],
                discriminativeStimulus: '',
                prompt: PhasePromptLegacyEnum.INDEPENDENT,
                target: '',
                criterion: '',
                targetsList: [{ id: uuidv4(), description: '', type: TargetTypeEnum.CURRENT }],
                reinforcementType: '',
                lto: '',
                shortName: '',
                baseline: '',
                collectionFrequency: '',
                definition: '',
                criterionForMastery: '',
                seconds: 'Seconds',
                additionalInfo: '',
                editName: false,
                totalITCount: 20,
                sampleTime: 0,
                reinforcementSchedule: PhaseReinforcementScheduleEnum.NONE,
                sto: '',
                errorless: false,
                variableITCount: false,
                tasks: [{ id: uuidv4(), taskDescription: '', isActive: true }],
                defaultResult: ITResultEnum.NOT_APPLICABLE,
                reinforcement: 0,
                saveAlertOpen: false,
                cancelAlertOpen: false,
                reinforcementInterval: 'Seconds',
            };
        }
    }

    private stopPropagation(event) {
        if (event) {
            event.stopPropagation();
        }
    }

}

export default connect<IAuthStateProps, IAuthDispatchProps, ISkillsFormProps>(
    ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(SkillsForm);
