import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ThreadUtils from 'thread-web-utils';

import {
  Avatar,
  Button,
  Icon,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { AuthenticationService } from '../Security/AuthenticationService';
import { RoleEnum } from '../PageNavigation/RoleEnum';

import ListPanel from '../ListPanel/ListPanel';

import Utils from '../../Utils/Utils';
import { Endpoints } from '../../Utils/Endpoints';

import StudentsListDialog from './StudentsListDialog'
import './StudentsList.css';

const Spinner = (
  <svg className="spinner"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid">
    <g transform="rotate(0 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9166666666666666s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(30 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8333333333333334s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(60 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.75s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(90 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6666666666666666s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5833333333333334s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(150 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4166666666666667s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(210 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3333333333333333s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.25s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(270 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.16666666666666666s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(300 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.08333333333333333s"
          repeatCount="indefinite" />
      </rect>
    </g>
    <g transform="rotate(330 50 50)">
      <rect x={47}
        y={24}
        rx={9.4}
        ry={4.8}
        width={6}
        height={12}
        fill="#00000042">
        <animate attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite" />
      </rect>
    </g>
  </svg>
);

export default class StudentsListView extends React.PureComponent {
  static propTypes = {
    studentList: PropTypes.arrayOf(PropTypes.exact({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatar: PropTypes.string,
      caregivers: PropTypes.arrayOf(PropTypes.string).isRequired,
      attemptedTrialsNum: PropTypes.number.isRequired
    })).isRequired,
    downloadFile: PropTypes.func.isRequired,
    reportGenerationInProgress: PropTypes.exact({
      type: PropTypes.string.isRequired,
      studentId: PropTypes.string
    })
  };

  componentWillMount() {
    const provider = localStorage['authProvider'];
    if (provider == 'stitch') {
      const authService = AuthenticationService.getInstance();
      authService.getUser().then((user) => {
        if (user !== null) {
          this.setState({
            currentUserRole: Array.isArray(user.profile.role) ? this.getStaffRole(user.profile.role) : user.profile.role,
            currentOrgId: user.profile.default_group
          })
        } else {
          this.setState({ currentUserRole: RoleEnum.ADMIN, currentOrgId: null })
        }
      });
    } else {
      this.setState({ currentUserRole: RoleEnum.ADMIN, currentOrgId: null });
    }
  }

  listRowClickHandler = (event) => {
    if (this.props.reportGenerationInProgress != null) {
      event.preventDefault();
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      dialogStudentId: null,
      currentUserRole: null,
      currentOrgId: null,
      openSessionReportDialog: false,
      orders: [
        {
          field: 'name',
          order: 'asc'
        },
        {
          field: 'attemptedTrialsNum',
          order: 'asc'
        }
      ]
    }
  }

  setDialogStudent(studentId, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState({
      dialogStudentId: studentId
    })
  };

  setSessionReportDialogOpen(open, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState({
      openSessionReportDialog: open
    })
  }

  getStaffRole(roles) {
    if (roles.includes(RoleEnum.ADMIN)) {
      return RoleEnum.ADMIN;
    } else if (roles.includes(RoleEnum.SUPERVISOR)) {
      return RoleEnum.SUPERVISOR;
    }
    return RoleEnum.INSTRUCTOR;
  }

  isReportButtonDisabled(type, studentId) {
    return this.props.reportGenerationInProgress.type !== type
      || this.props.reportGenerationInProgress.studentId !== studentId;
  }

  shouldShowBillingReport() {
    if (this.state.currentOrgId == null) {
      return true;
    }
    const orgsList = process.env.REACT_APP_SHOW_MOBILE_SESSION_NOTES.split(" ");
    if (orgsList.indexOf(this.state.currentOrgId) >= 0) {
      return true
    } else {
      return false
    }
  }

  sort(field) {
    this.setState((prevState) => {
      return Utils.sortListPanelItems(prevState.orders, field);
    });
  };

  render() {
    var students = Utils.orderItems(this.props.studentList, this.state.orders).map((student) => {
      var downloadXlsxFileUrl = Utils.replaceUrlParams(Endpoints().downloadXlsx.url, {
        studentId: student.id
      });

      return (<li key={student.id}>
        <Link className="link"
          to={`/student/${student.id}`}
          onClick={this.listRowClickHandler}>
          {
            student.avatar != null ? (
              <img className="avatar"
                src={student.avatar}
                alt="Student's avatar" />
            ) : (
                <Avatar className="avatar">{Utils.getInitials(student.name)}</Avatar>
              )
          }
          <div className="description third-width">
            <div className="top-desc ellipsis-overflow">{student.name || ''}</div>
            <div className="bottom-desc ellipsis-overflow">{ThreadUtils.getCommaSeparatedString(student.caregivers)}</div>
          </div>
          <div className="single-row third-width ellipsis-overflow">{
            student.attemptedTrialsNum
          } average daily trials</div>

          <div className="right-block">
            {
              this.props.reportGenerationInProgress == null || this.isReportButtonDisabled('docx', student.id) ? (
                <Button className="download-file-button"
                  color="primary"
                  disabled={this.props.reportGenerationInProgress != null
                    && this.isReportButtonDisabled('docx', student.id)}
                  onClick={this.setDialogStudent.bind(this, student.id)}>
                  <span>Progress Report</span>
                  <AssessmentIcon className="download-file-icon" />
                </Button>
              ) : Spinner
            }
            {
              this.props.reportGenerationInProgress == null || this.isReportButtonDisabled('xlsx', student.id) ? (
                <Button className="download-file-button"
                  color="primary"
                  href={downloadXlsxFileUrl}
                  disabled={this.props.reportGenerationInProgress != null
                    && this.isReportButtonDisabled('xlsx', student.id)}
                  onClick={this.props.downloadFile.bind(null, student.id, {
                    endpoint: Endpoints().downloadXlsx
                  })}>
                  <span>Raw Data</span>
                  <ListAltIcon className="download-file-icon" />
                </Button>
              ) : Spinner
            }
          </div>
        </Link>
      </li>)
    });

    return (
      <div className="content">
        <div className="stand-alone-title">Students</div>
        {this.shouldShowBillingReport() && <div style={{ "display": "flex" }}>
          {
            this.state.currentUserRole === RoleEnum.ADMIN && (this.props.reportGenerationInProgress == null || this.isReportButtonDisabled('xlsx', null)) ? (
              <Button className="session-report-button"
                color="primary"
                disabled={this.props.reportGenerationInProgress != null
                  && this.isReportButtonDisabled('xlsx', null)}
                onClick={this.setSessionReportDialogOpen.bind(this, true)}>
                <span>Billing Report</span>
                <AttachMoneyIcon className="session-report-icon" />
              </Button>
            ) : Spinner
          }
        </div>}
        <ListPanel id="students-list"
          pageHeaderHeight={214}
          header={
            <React.Fragment>
              <div className="list-title students-list-title">
                <a className="sort"
                  onClick={this.sort.bind(this, 'name')}>
                  <span>STUDENT NAME</span>
                  <Icon className="arrow-icon">{Utils.getSortingIconName(this.state.orders, 'name')}</Icon>
                </a>
              </div>
              <div className="list-title">
                <a className="sort"
                  onClick={this.sort.bind(this, 'attemptedTrialsNum')}>
                  <span>AVERAGE ATTEMPTED TRIALS / DAY</span>
                  <Icon className="arrow-icon">{
                    Utils.getSortingIconName(this.state.orders, 'attemptedTrialsNum')
                  }</Icon>
                </a>
              </div>
            </React.Fragment>
          }>
          {students}
          <StudentsListDialog
            open={this.state.dialogStudentId != null}
            onClose={this.setDialogStudent.bind(this, null)}
            studentId={this.state.dialogStudentId}
            downloadFile={this.props.downloadFile}
          />
          <StudentsListDialog
            open={this.state.openSessionReportDialog}
            onClose={this.setSessionReportDialogOpen.bind(this, false)}
            studentId={this.state.dialogStudentId}
            downloadFile={this.props.downloadFile}
          />
        </ListPanel>
      </div>
    );
  }
}
