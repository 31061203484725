import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';

import Page from '../Page/Page';

import PrintUtils from '../Utils';
import Utils from '../../../../Utils/Utils';

import './PhaseList.css';

const BOTTOM_ITEM_PADDING = 30;
const ROW_HEIGHT = 20;

PhaseList.propTypes = {
  chartType: PropTypes.oneOf(['default', 'task analysis', 'frequency', 'duration']).isRequired,
  student: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  schoolYearNode: PropTypes.node,
  phases: PropTypes.arrayOf(PropTypes.shape({
    phaseItems: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.exact({
        type: PropTypes.oneOf(['objective']).isRequired,
        objective: PropTypes.string.isRequired
      }),
      PropTypes.exact({
        type: PropTypes.oneOf(['note']).isRequired,
        noteBody: Utils.nonEmptyStringPropValidator,
        noteDate: PropTypes.instanceOf(Date).isRequired,
        creatorInitials: PropTypes.string.isRequired
      }),
      PropTypes.exact({
        type: PropTypes.oneOf(['additionalInfo']).isRequired,
        additionalInfo: PropTypes.string.isRequired
      }),
    ]).isRequired).isRequired,
    pointsNumber: PropTypes.number.isRequired,
    range: Utils.nonEmptyStringPropValidator,
    titleLetter: Utils.nonEmptyStringPropValidator,
    labelOverride: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  pageSize: PropTypes.exact({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired
};

export default function PhaseList(props) {
  const desiredColumnWidth = 330,
    columnMargin = 15;

  var columnNumber,
    columnWidth,
    counters = {
      block: 0,
      item: 0
    },
    previousCounters,
    pages = [],
    columns,
    column,
    columnCounter;

  var error = PrintUtils.getErrorNode(props.pageSize.height, 'Phase List');

  if (!error) {
    columnNumber = Math.round((props.pageSize.width + 2 * columnMargin) / (desiredColumnWidth + 2 * columnMargin));
    columnWidth = Math.floor((props.pageSize.width + 2 * columnMargin) / columnNumber) - 2 * columnMargin;

    while (counters.block < props.phases.length) {
      columns = [];
      columnCounter = 0;

      while (counters.block < props.phases.length && columnCounter < columnNumber) {
        previousCounters = Object.assign({}, counters);
        counters = getEndCounters(props.phases, previousCounters, props.pageSize.height, columnWidth);
        column = renderColumn(props.chartType, props.phases, previousCounters, counters, columnWidth, columnCounter);
        columns.push(column);
        columnCounter++;
      }

      pages.push(
        <Page key={pages.length}
          student={props.student}
          rightTopNode={props.schoolYearNode}>
          {columns}
        </Page>
      );
    }
  }

  return (
    <div id="student-progress-phase-list-print">
      {error ? (
        <Page student={props.student}
          rightTopNode={props.schoolYearNode}>
          {error}
        </Page>
      ) : pages}
    </div>
  );
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function getEndCounters(phases, counters, pageHeight, columnWidth) {
  const iconWidth = 25,
    topHeight = 38,
    topBordersThickness = 5,
    blockTopMargin = -2,
    totalTopHeight = topHeight + topBordersThickness + blockTopMargin,
    bottomMargin = 5,
    bodyTopPadding = 7,
    bodyBlockBottomMargin = 12,
    noteBlockBorderThickness = 1,
    noteBlockVerticalPadding = 6,
    noteBlockHorizontalPadding = 10;

  counters = Object.assign({}, counters);
  var savedCounters = Object.assign({}, counters),
    testHeight = 0;

  if (counters.item !== 0) {
    testHeight += getBlockHeaderHeight(phases[counters.block].labelOverride, totalTopHeight, bottomMargin, columnWidth)
      + bodyTopPadding;
  }

  while (testHeight <= pageHeight && counters.block < phases.length) {
    savedCounters = Object.assign({}, counters);

    if (counters.item === 0) {
      testHeight += getBlockHeaderHeight(phases[counters.block].labelOverride, totalTopHeight, bottomMargin,
        columnWidth) + bodyTopPadding;
    }

    if (phases[counters.block].phaseItems.length === 0
      || (phases[counters.block].phaseItems[counters.item].type === 'objective'
        && isEmptyString(phases[counters.block].phaseItems[counters.item].objective))) {
      testHeight += bodyBlockBottomMargin + ROW_HEIGHT;
    } else if (phases[counters.block].phaseItems[counters.item].type === 'objective') {
      testHeight += bodyBlockBottomMargin +
        PrintUtils.getRowNumber(phases[counters.block].phaseItems[counters.item].objective,
          columnWidth - iconWidth) * ROW_HEIGHT;
    } else if (phases[counters.block].phaseItems[counters.item].type === 'additionalInfo') {
      testHeight += bodyBlockBottomMargin +
        PrintUtils.getRowNumber(phases[counters.block].phaseItems[counters.item].additionalInfo,
          columnWidth) * ROW_HEIGHT;
    } else if (phases[counters.block].phaseItems[counters.item].type === 'note') {
      var noteString = phases[counters.block].phaseItems[counters.item].noteDate
        .toLocaleDateString('en-US') + ' - ' + phases[counters.block].phaseItems[counters.item].noteBody;
      testHeight += bodyBlockBottomMargin +
        PrintUtils.getRowNumber(noteString, columnWidth - iconWidth - noteBlockBorderThickness * 2
          - noteBlockHorizontalPadding * 2) * ROW_HEIGHT + noteBlockVerticalPadding * 2 + noteBlockBorderThickness * 2;
    }

    if (phases[counters.block].phaseItems.length === 0
      || counters.item === phases[counters.block].phaseItems.length - 1) {
      if (testHeight < pageHeight && testHeight + BOTTOM_ITEM_PADDING > pageHeight) {
        testHeight = pageHeight;
      } else {
        testHeight += BOTTOM_ITEM_PADDING;
      }
    }

    counters.item++;
    if (counters.item >= phases[counters.block].phaseItems.length) {
      counters.block++;
      counters.item = 0;
    }
  }

  if (testHeight <= pageHeight && counters.block === phases.length) {
    savedCounters = Object.assign({}, counters);
  }

  return savedCounters;
}

function getBlockHeaderHeight(blockTitle, totalTopHeight, bottomMargin, columnWidth) {
  const height = totalTopHeight + bottomMargin;

  return isEmptyString(blockTitle) ? height + ROW_HEIGHT
    : height + PrintUtils.getRowNumber(blockTitle, columnWidth) * ROW_HEIGHT;
}

function isEmptyString(string) {
  return string == null || string.trim() === '';
}

function renderColumn(chartType, phases, beginCounters, endCounters, columnWidth, key) {
  var blocks = [],
    bottomItemPadding = 0,
    bottomBlockTopPadding = 0,
    bodyBlocks,
    blockTitle,
    beginItemCounter,
    endItemCounter,
    endBlockCounter = endCounters.item !== 0 ? endCounters.block + 1 : endCounters.block;

  for (var blockCounter = beginCounters.block; blockCounter < endBlockCounter; blockCounter++) {
    bodyBlocks = [];
    beginItemCounter = blockCounter === beginCounters.block ? beginCounters.item : 0;
    endItemCounter = blockCounter === endBlockCounter - 1 && endCounters.item !== 0 ? endCounters.item
      : phases[blockCounter].phaseItems.length;

    for (var itemCounter = beginItemCounter; itemCounter === 0 || itemCounter < endItemCounter; itemCounter++) {
      if (blockCounter !== endBlockCounter - 1 && (endItemCounter === 0 || itemCounter === endItemCounter - 1)) {
        bottomItemPadding = BOTTOM_ITEM_PADDING;
      } else {
        bottomItemPadding = 0;
      }

      var itemBlocks = [];
      if (endItemCounter === 0) {
        itemBlocks.push(
          <div key={0}
            className="no-items">
            {
              chartType === 'task analysis' ? 'No tasks set' : 'No targets set'
            }
          </div>
        );
      } else {
        if (chartType === 'default' && phases[blockCounter].phaseItems[itemCounter].type === 'objective') {
          itemBlocks.push(
            <Icon key={0}
              className="circle-icon">adjust</Icon>
          );
        } else if (phases[blockCounter].phaseItems[itemCounter].type === 'note') {
          itemBlocks.push(
            <div key={0}
              className="note-initials">{
                phases[blockCounter].phaseItems[itemCounter].creatorInitials
              }</div>
          );
        }

        if (phases[blockCounter].phaseItems[itemCounter].type === 'objective') {
          itemBlocks.push(
            <div key={1}>
              {phases[blockCounter].phaseItems[itemCounter].objective}
            </div>
          );
        } else if (phases[blockCounter].phaseItems[itemCounter].type === 'note') {
          itemBlocks.push(
            <div key={1}
              className="note">
              {phases[blockCounter].phaseItems[itemCounter].noteDate.toLocaleDateString('en-US')} - {
                phases[blockCounter].phaseItems[itemCounter].noteBody
              }
            </div>
          )
        } else {
          itemBlocks.push(
            <div key={1}>
              {phases[blockCounter].phaseItems[itemCounter].additionalInfo}
            </div>
          );
        }
      }

      bodyBlocks.push(
        <div key={`${blockCounter}-${itemCounter}`}
          className="body-block"
          style={{ paddingBottom: bottomItemPadding }}>
          {itemBlocks}
        </div>
      );
    }

    bottomBlockTopPadding = isEmptyString(phases[blockCounter].labelOverride) ? ROW_HEIGHT : 0;
    blockTitle = blockCounter === beginCounters.block && beginCounters.item !== 0 ? '(continued)'
      : phases[blockCounter].titleLetter;

    blocks.push(
      <div key={blockCounter}
        className="block">
        <div className="title">
          <div className="top">
            <div className="left">{blockTitle}</div>
            <div className="right">
              <div>{phases[blockCounter].pointsNumber} Data Points</div>
              <div>{phases[blockCounter].range}</div>
            </div>
          </div>
          <div className="bottom"
            style={{ paddingTop: bottomBlockTopPadding }}>{phases[blockCounter].labelOverride}</div>
        </div>
        <div className="body">
          {bodyBlocks}
        </div>
      </div>
    );
  }

  return (
    <div key={key}
      className="column"
      style={{ width: columnWidth }}>
      {blocks}
    </div>
  )
}
