import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { Image } from '@raudabaugh/thread-ui';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Icon,
} from '@material-ui/core';
import {
  Button
} from '@raudabaugh/thread-ui'


import PageNavigation from '../PageNavigation/PageNavigation';
import StudentHeader from '../StudentHeader/StudentHeader';
import ListPanel from '../ListPanel/ListPanel';
import SkillsForm from '../EditProgramDialog/SkillsForm';
import BehaviorForm from '../EditProgramDialog/BehaviorForm';
import CreateDialog from '../CreateProgram/CreateDialog'

import Utils from '../../Utils/Utils';

import './StudentProfile.css';
import { PhasePropType } from '../../Utils/PropTypes';

export default class StudentProfileView extends React.PureComponent {
  static propTypes = {
    studentId: PropTypes.string.isRequired,
    student: PropTypes.exact({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      attemptedTrialsNum: PropTypes.number.isRequired,
      attemptedTrialsAvg: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    programs: PropTypes.arrayOf(PropTypes.exact({
      type: PropTypes.string.isRequired,
      id: Utils.nonEmptyStringPropValidator,
      title: PropTypes.string.isRequired,
      details: PropTypes.string.isRequired,
      lastRun: PropTypes.instanceOf(Date),
      phase: PhasePropType,
      behaviorPhase: PropTypes.exact({
        id: Utils.nonEmptyStringPropValidator,
        procedure: PropTypes.string.isRequired,
        shortName: PropTypes.string,
        target: PropTypes.string,
        baseline: PropTypes.string,
        collectionFrequency: PropTypes.string,
        definition: PropTypes.string,
        criterionForMastery: PropTypes.string,
        additionalInfo: PropTypes.string,
      }),
      archived: PropTypes.bool.isRequired
    })).isRequired,
    initFunc: PropTypes.func.isRequired
  };

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  handleCreateClose = () => {
    this.setState({
      openCreateDialog: false
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      orders: [
        {
          field: 'title',
          order: 'asc'
        },
        {
          field: 'lastRun',
          order: 'desc'
        }
      ],
      dialogProgramId: null,
      isDialogOpen: false,
      showArchivedPrograms: false,
      openCreateDialog: false
    };
  }

  changeShowArchivedPrograms = (event) => {
    this.setState({
      showArchivedPrograms: event.target.checked
    });
  };

  openDialog(id, event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      dialogProgramId: id,
      isDialogOpen: true
    });
  };

  openCreateDialog() {
    this.setState({ openCreateDialog: true })
  }

  sort(field) {
    this.setState((prevState) => {
      return Utils.sortListPanelItems(prevState.orders, field);
    });
  };

  render() {
    var programs = Utils.orderItems(this.props.programs, this.state.orders, this.state.showArchivedPrograms).filter((program) => {
      return this.state.showArchivedPrograms || !program.archived
    })
      .map((program) => {
        return (
          <li key={program.id}>
            <Link className="link"
              to={`/student/${this.props.studentId}/program/${program.id}`}>
              <Image src={Utils.programTypeImage(program.type)} />
              <div className="description">
                <div className="top-desc ellipsis-overflow">{program.title}</div>
                <div className="bottom-desc ellipsis-overflow">{program.details}</div>
              </div>
              <div className="right-block">
                {
                  program.phase != null || program.behaviorPhase != null ? (
                    <Button className="edit-button"
                      onClick={this.openDialog.bind(this, program.id)}>Edit</Button>
                  ) : null
                }
                <div className="right-text-block">{program.lastRun != null ? moment(program.lastRun).fromNow() : ''}</div>
              </div>
            </Link>
          </li>
        )
      });

    const rightBottomNode = (<span>{this.props.student.attemptedTrialsAvg} Avg. Attempted Trials/Day</span>);
    let dialogProgram;
    let dialogPhase
    if (this.state.dialogProgramId != null) {
      dialogProgram = _.find(this.props.programs, ['id', this.state.dialogProgramId])
      dialogPhase = dialogProgram.phase || dialogProgram.behaviorPhase
    }
    return (
      <div id="student-profile" className="content">
        <PageNavigation text="All Students"
          link="/" />
        <StudentHeader student={_.pick(this.props.student, 'name', 'avatar')}
          leftBottomNode={''}
          rightTopNode={`${this.props.student.attemptedTrialsNum} Attempted Trials Today`}
          rightTopFreeText={true}
          rightBottomNode={rightBottomNode} />
        <FormGroup style={{ justifyContent: 'space-between' }} className="checkbox-row"
          row>
          <Button type="primary" icon="plus" onClick={this.openCreateDialog.bind(this)}>New Program</Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showArchivedPrograms}
                onChange={this.changeShowArchivedPrograms}
                color="primary" />
            }
            label={`Show archived programs`} />
        </FormGroup>
        <ListPanel header={
          <React.Fragment>
            <div className="list-title">
              <a className="sort"
                onClick={this.sort.bind(this, 'title')}>
                <span>PROGRAM NAME</span>
                <Icon className="arrow-icon">{Utils.getSortingIconName(this.state.orders, 'title')}</Icon>
              </a>
            </div>
            <div className="list-title list-title-right">
              <a className="sort"
                onClick={this.sort.bind(this, 'lastRun')}>
                <span>LAST RUN</span>
                <Icon className="arrow-icon">{Utils.getSortingIconName(this.state.orders, 'lastRun')}</Icon>
              </a>
            </div>
          </React.Fragment>
        }
          pageHeaderHeight={350}>
          {programs}
        </ListPanel>
        {
          dialogProgram != null ? (
            dialogProgram.phase != null ? (
              <SkillsForm values={{
                errorless: dialogPhase.errorless,
                studentName: this.props.student.name,
                programName: dialogProgram.title,
                procedure: dialogPhase.procedure,
                discriminativeStimulus: dialogPhase.discriminativeStimulus,
                prompt: dialogPhase.prompt,
                currentTargets: dialogPhase.currentTargets,
                masteredTargets: dialogPhase.masteredTargets,
                futureTargets: dialogPhase.futureTargets,
                criterions: dialogPhase.criterionRules,
                reinforcementType: dialogPhase.reinforcementType,
                lto: dialogPhase.lto,
                sto: dialogPhase.sto,
                additionalInfo: dialogPhase.additionalInfo,
                reinforcementSchedule: dialogPhase.reinforcementSchedule,
                criterionForMastery: dialogPhase.criterionForMastery,
                target: dialogPhase.target,
                shortName: dialogPhase.shortName,
                definition: dialogPhase.definition,
                defaultResult: dialogPhase.defaultResult,
                variableITCount: dialogPhase.variableITCount,
                totalITCount: dialogPhase.totalITCount,
                tasks: dialogPhase.tasks,
                baseline: dialogPhase.baseline,
                sampleTime: dialogPhase.sampleTime,
                collectionFrequency: dialogPhase.collectionFrequency,
                reinforcement: dialogPhase.reinforcement,
              }}
                studentName={this.props.student.name}
                studentId={this.props.student.id}
                formType={dialogProgram.type}
                programId={this.state.dialogProgramId}
                phaseId={dialogPhase.id}
                isLastPhase={true}
                open={this.state.isDialogOpen}
                updateParent={this.props.initFunc}
                onClose={this.handleDialogClose} />
            ) : dialogProgram.behaviorPhase != null ? (
              <BehaviorForm values={{
                ...dialogProgram.behaviorPhase,
                programName: dialogProgram.title,
              }}
                programId={this.state.dialogProgramId}
                studentName={this.props.student.name}
                studentId={this.props.student.id}
                formType={dialogProgram.type}
                phaseId={dialogPhase.id}
                isLastPhase={true}
                open={this.state.isDialogOpen}
                updateParent={this.props.initFunc}
                onClose={this.handleDialogClose} />
            ) : null
          ) : null
        }
        <CreateDialog studentName={this.props.student.name} updateParent={this.props.initFunc} studentId={this.props.studentId} {...this.props} onClose={this.handleCreateClose} open={this.state.openCreateDialog} />
      </div>
    );
  }
}