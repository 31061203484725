import { Col, Grid, Image, Row } from '@raudabaugh/thread-ui';
import * as React from 'react';
import styled from 'styled-components';

const BarBackground = styled.div`
    height: 56px;
    background-color: #002140;
`

export class SideMenuHeader extends React.PureComponent {
    public render() {
        return (
            <BarBackground>
                <Grid>
                    <Row type="flex">
                        <Col grow={1} margin="12px 0 0 12px">
                            <Image src="/images/thread_logo.svg" alt="Thread Logo" />
                        </Col>
                    </Row>
                </Grid>
            </BarBackground>
        );
    }
}

