import {
  setAccessToken,
  setAuthTokens
} from '../actions';
import { IAuthDispatchProps, IAuthStateProps } from './ReduxProps'

export default {
  mapAuthStateToProps: (state) => {
    return {
      accessToken: state.accessToken,
      realmPath: state.realmPath,
      userName: state.userName,
      userAvatar: state.userAvatar,
      userId: state.userId
    } as IAuthStateProps;
  },

  mapAuthDispatchToProps: (dispatch) => {
    return {
      removeAccessToken: () => {
        dispatch(setAccessToken(null))
      },

      removeAuthTokens: () => {
        dispatch(setAuthTokens(null, null, null, null, null, null))
      }
    } as IAuthDispatchProps;
  }
}