import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import ChartistGraph from 'react-chartist';

import Page from '../Page/Page';

import Constants from '../../../../Utils/Constants';
import Utils from '../../../../Utils/Utils';
import StudentProgressUtils from '../../Utils';
import PrintUtils from '../Utils';

import './Chart.css';

import Chartist from 'chartist';

/**
 * @note To control size of the whole progress chart it is enough to change the value of the `CHART_LINES_SPACE` const.
 * [aLuk 18.Dec.17]
 */
const CHART_LINES_SPACE = 9; // Number of pixels between sibling vertical lines on the chart
const TOTAL_CHART_MARGIN = 60; // Sum of left and right margins of the chart. The additional space that we want to add to the total chart width.
const NATIVE_CHART_OFFSET = 10; // NATIVE_CHART_OFFSET + CUSTOM_CHART_OFFSET = total left margin of the chart that contains y-axis labels
const CUSTOM_CHART_OFFSET = 30;
const Y_AXIS_TITLE_HEIGHT = 14;
const MIN_FIRST_BLOCK_WIDTH = 30; // Minimal width of the first block of the chart header
const FOOTER_PADDING = 2;

export default class Chart extends React.PureComponent {
  static propTypes = {
    chartType: PropTypes.oneOf(['default', 'task analysis', 'frequency', 'duration']).isRequired,
    student: PropTypes.exact({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired,
    schoolYearNode: PropTypes.node,
    chartData: PropTypes.shape({
      stepBarName: PropTypes.oneOf(_.concat(_.keys(Constants.frequencyChartLabels),
        _.keys(Constants.durationChartLabels))),
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      series: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired).isRequired,
      info: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['pretest', 'posttest', 'probe', 'standard']).isRequired,
        note: PropTypes.exact({
          body: Utils.nonEmptyStringPropValidator,
          date: PropTypes.instanceOf(Date).isRequired
        }),
        creatorName: PropTypes.string
      })).isRequired
    }).isRequired,
    phases: PropTypes.arrayOf(PropTypes.exact({
      pointsNumber: PropTypes.number.isRequired,
      labelOverride: PropTypes.string.isRequired,
      additionalInfo: PropTypes.string.isRequired
    }).isRequired).isRequired,
    pageSize: PropTypes.exact({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired
  };

  componentDidMount() {
    truncateLongText();
  }

  componentDidUpdate() {
    truncateLongText();
  }

  chartDrawHandler(info, data) {
    if (data.type === 'point') {
      var isEvenIndex = data.index % 2 === 0;
      if (!isEvenIndex) {
        data.element.addClass('ct-empty');
      } else {
        var pointShape = StudentProgressUtils.getPointShape(info[data.index], data, 'print');
        data.element.replace(pointShape);
      }
    }
  }

  renderChartSections(phases) {
    return phases.map((phase, index) => {
      var style = StudentProgressUtils.getPhaseTitleStyles(index, phases.length, phase.pointsNumber,
        NATIVE_CHART_OFFSET, CUSTOM_CHART_OFFSET, CHART_LINES_SPACE, 0, false, MIN_FIRST_BLOCK_WIDTH);

      var bottomText = phase.labelOverride ? (phase.pointsNumber < 3 ? '...' : phase.labelOverride) : '';

      return (
        <div key={index}
          className="header"
          style={style}>
          <div className="top-title ellipsis-overflow">{phase.titleLetter}</div>
          <div className="bottom-title">{bottomText}</div>
        </div>
      );
    });
  }

  renderOverlay(phases) {
    return phases.map((phase, index) => {
      if (_.endsWith(phase.titleLetter, '...')) {
        return null;
      }

      var style = StudentProgressUtils.getPhaseTitleStyles(index, phases.length, phase.pointsNumber,
        NATIVE_CHART_OFFSET, CUSTOM_CHART_OFFSET, CHART_LINES_SPACE, 0);

      if (index === phases.length - 1 || _.endsWith(phases[index + 1].titleLetter, '...')) {
        style.width += 1;
      }

      return (
        <div key={index}
          className="separator"
          style={style}>
        </div>
      );
    });
  }

  renderChartFooter(chartDataInfo) {
    return chartDataInfo.filter((info) => !_.isEmpty(info)).map((info, index) => {
      var style = {};
      if (index === 0) {
        style.marginLeft = NATIVE_CHART_OFFSET + CUSTOM_CHART_OFFSET - 3;
      }
      style.width = CHART_LINES_SPACE * 2 - FOOTER_PADDING + 2;

      return (
        <div key={index}
          className="chart-footer"
          style={style}>{Utils.getInitials(info.creatorName)}</div>
      );
    });
  }

  labelInterpolationFunction(value, index) {
    if (this.props.chartType === 'frequency') {
      return Constants.frequencyChartLabels[this.props.chartData.stepBarName][index];
    } else if (this.props.chartType === 'duration') {
      return Constants.durationChartLabels[this.props.chartData.stepBarName][index];
    }

    return index % 2 === 0 ? value + '%' : '';
  }

  render() {
    var totalMaxPointsNumberRaw = Math.floor((this.props.pageSize.width - TOTAL_CHART_MARGIN - CUSTOM_CHART_OFFSET
      - NATIVE_CHART_OFFSET + CHART_LINES_SPACE - StudentProgressUtils.NATIVE_RIGHT_CHART_MARGIN - Y_AXIS_TITLE_HEIGHT)
      / CHART_LINES_SPACE);
    var totalMaxPointsNumber = totalMaxPointsNumberRaw % 2 === 0 ? totalMaxPointsNumberRaw - 1
      : totalMaxPointsNumberRaw;
    var maxPointsNumber = (totalMaxPointsNumber + 1) / 2;
    var pageData = {
      chartDataList: getChartDataList(this.props.chartData, totalMaxPointsNumber),
      phases: getPagePhases(this.props.phases, maxPointsNumber)
    };

    const yAxisTitle = StudentProgressUtils.getYAxisTitle(this.props.chartType, this.props.chartData.stepBarName);
    const chartWidth = StudentProgressUtils.getChartWidth(totalMaxPointsNumber, CHART_LINES_SPACE, NATIVE_CHART_OFFSET,
      CUSTOM_CHART_OFFSET) - CHART_LINES_SPACE;
    const isBehaviorChart = this.props.chartType === 'frequency' || this.props.chartType === 'duration';

    const error = isBehaviorChart ? PrintUtils.getErrorNode(this.props.pageSize.height, 'Student Progress Chart', 573,
      23) : PrintUtils.getErrorNode(this.props.pageSize.height, 'Student Progress Chart', 625, 25);
    const noPointsError = this.props.chartData.labels[0] == null ? (
      <div className="error">There are no points to display.</div>
    ) : null;
    const className = isBehaviorChart ? 'screen-hidden behavior-chart' : 'screen-hidden';

    const ticks = isBehaviorChart ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      : [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
    const options = {
      fullWidth: true,
      width: chartWidth,
      height: 20 * CHART_LINES_SPACE * 2 + 50,
      axisY: {
        type: Chartist.FixedScaleAxis,
        high: 100,
        low: 0,
        offset: CUSTOM_CHART_OFFSET,
        ticks: ticks,
        labelInterpolationFnc: this.labelInterpolationFunction.bind(this)
      },
      lineSmooth: Chartist.Interpolation.none({
        fillHoles: false
      })
    };

    var pages = [];

    if (error || noPointsError) {
      pages.push(
        <Page key={0}
          className="student-progress"
          student={this.props.student}
          rightTopNode={this.props.schoolYearNode}>
          {noPointsError}
          {!noPointsError ? error : null}
        </Page>
      )
    } else {
      pageData.chartDataList.forEach((chartData, index) => {
        var listener = {
          draw: this.chartDrawHandler.bind(this, chartData.info)
        };

        pages.push(
          <Page key={index}
            className="student-progress"
            student={this.props.student}
            rightTopNode={this.props.schoolYearNode}>
            <div className="chart-block-wrapper">
              <div className="y-axis-title">{yAxisTitle}</div>
              <div className="chart-block">
                <div className="overlay">
                  {this.renderOverlay(pageData.phases[index])}
                </div>
                <div>
                  <div className="headers"
                    style={{ width: chartWidth }}>
                    {this.renderChartSections(pageData.phases[index])}
                  </div>
                  <ChartistGraph className="progress-chart"
                    type="Line"
                    data={_.omit(chartData, 'info')}
                    listener={listener}
                    options={options}
                    forceRecreation={true} />
                  {
                    chartData.info.some(info => info != null && info.creatorName != null) ? (
                      <div className="chart-footers"
                        style={{ width: chartWidth }}>
                        {this.renderChartFooter(chartData.info)}
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
            {
              !error && !noPointsError ? (
                <div className="x-axis-title">Date and Instructor Initials</div>
              ) : null
            }
            {
              !isBehaviorChart ? (
                <footer>
                  <div className="wrapper">
                    <div className="label">Legend:</div>
                    <div className="label-block">
                      {StudentProgressUtils.getPagePointShape('pretest')}
                      <div>Pre Test</div>
                    </div>
                    <div className="label-block">
                      {StudentProgressUtils.getPagePointShape('standard')}
                      <div>Data Point</div>
                    </div>
                    <div className="label-block">
                      {StudentProgressUtils.getPagePointShape('probe')}
                      <div className="label">Probe</div>
                    </div>
                    <div className="label-block">
                      {StudentProgressUtils.getPagePointShape('posttest')}
                      <div className="label">Post Test</div>
                    </div>
                  </div>
                </footer>
              ) : null
            }
          </Page>
        );
      })
    }

    return (
      <div id="student-progress-chart-print"
        className={className}>
        {pages}
      </div>
    );
  }
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function getChartDataList(chartData, totalMaxPointsNumber) {
  var chartDataList = [],
    remainingChartPointsNumber = chartData.labels.length,
    chartDataIndex = 0,
    filledChartData,
    emptyLabels,
    emptySeries,
    emptyInfo;

  while (remainingChartPointsNumber > 0) {
    if (remainingChartPointsNumber >= totalMaxPointsNumber) {
      chartDataList.push({
        labels: chartData.labels.slice(chartDataIndex, chartDataIndex + totalMaxPointsNumber),
        series: [chartData.series[0].slice(chartDataIndex, chartDataIndex + totalMaxPointsNumber)],
        info: chartData.info.slice(chartDataIndex, chartDataIndex + totalMaxPointsNumber)
      });

      chartDataIndex += totalMaxPointsNumber - 1;
      remainingChartPointsNumber -= totalMaxPointsNumber - 1;
    } else {
      filledChartData = {
        labels: chartData.labels.slice(chartDataIndex, chartDataIndex + remainingChartPointsNumber),
        series: [chartData.series[0].slice(chartDataIndex, chartDataIndex + remainingChartPointsNumber)],
        info: chartData.info.slice(chartDataIndex, chartDataIndex + remainingChartPointsNumber)
      };

      remainingChartPointsNumber = totalMaxPointsNumber - remainingChartPointsNumber;

      emptyInfo = new Array(remainingChartPointsNumber).fill(null);
      emptySeries = new Array(remainingChartPointsNumber).fill(null);
      emptyLabels = new Array(remainingChartPointsNumber).fill(null)
        .map((item, index) => index % 2 === 0 ? null : '');

      chartDataList.push({
        labels: filledChartData.labels.concat(emptyLabels),
        series: [filledChartData.series[0].concat(emptySeries)],
        info: filledChartData.info.concat(emptyInfo)
      });

      remainingChartPointsNumber = 0;
    }
  }

  return chartDataList;
}

function getPagePhases(phases, maxPointsNumber) {
  var pagePhases = [[]],
    totalPointsNumber = 0,
    remainingPhasePointsNumber;

  phases.forEach((phase, index) => {
    if (totalPointsNumber + phase.pointsNumber < maxPointsNumber) {
      totalPointsNumber += phase.pointsNumber;
      _.last(pagePhases).push(Object.assign({}, phase, {
        titleLetter: Utils.numberToLetter(index)
      }));
    } else {
      _.last(pagePhases).push(Object.assign({}, phase, {
        pointsNumber: maxPointsNumber - totalPointsNumber,
        totalPointsNumber: phase.pointsNumber,
        titleLetter: `${Utils.numberToLetter(index)}...`
      }));
      pagePhases.push([]);

      remainingPhasePointsNumber = phase.pointsNumber - (maxPointsNumber - totalPointsNumber) + 1;
      while (remainingPhasePointsNumber > 0) {
        if (remainingPhasePointsNumber < maxPointsNumber) {
          _.last(pagePhases).push(Object.assign({}, phase, {
            pointsNumber: remainingPhasePointsNumber,
            totalPointsNumber: phase.pointsNumber,
            titleLetter: `...${Utils.numberToLetter(index)}`
          }));
          totalPointsNumber = remainingPhasePointsNumber;
          remainingPhasePointsNumber = 0;
        } else {
          _.last(pagePhases).push(Object.assign({}, phase, {
            pointsNumber: maxPointsNumber,
            totalPointsNumber: phase.pointsNumber,
            titleLetter: `...${Utils.numberToLetter(index)}...`
          }));
          pagePhases.push([]);
          remainingPhasePointsNumber -= maxPointsNumber - 1;
        }
      }
    }
  });

  return pagePhases;
}

function truncateLongText() {
  require('trunk8');
  var selector = $('#student-progress-print').find('.header .bottom-title');
  selector.trunk8({
    lines: 4
  });
}