import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';

import Chart from './Chart/Chart';
import PhaseList from './PhaseList/PhaseList';
import TargetsList from './TargetsList/TargetsList';

import Constants from '../../../Utils/Constants';
import Utils from '../../../Utils/Utils';

export default class Print extends React.PureComponent {
  static propTypes = {
    chartType: PropTypes.oneOf(['default', 'task analysis', 'frequency', 'duration']).isRequired,
    student: PropTypes.exact({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired,
    chartData: PropTypes.shape({
      stepBarName: PropTypes.oneOf(_.concat(_.keys(Constants.frequencyChartLabels),
        _.keys(Constants.durationChartLabels))),
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      series: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired).isRequired,
      info: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.instanceOf(Date).isRequired,
        type: PropTypes.oneOf(['pretest', 'posttest', 'probe', 'standard']).isRequired,
        note: PropTypes.exact({
          body: Utils.nonEmptyStringPropValidator,
          date: PropTypes.instanceOf(Date).isRequired
        }),
        creatorName: PropTypes.string
      })).isRequired
    }).isRequired,
    targets: PropTypes.exact({
      criterionRules: PropTypes.arrayOf(PropTypes.exact({
        pointsAnalyzed: PropTypes.number.isRequired,
        minPercentage: PropTypes.number.isRequired
      }).isRequired).isRequired,
      shortTermObjective: PropTypes.string.isRequired,
      longTermObjective: PropTypes.string.isRequired,
      targetsList: PropTypes.arrayOf(PropTypes.exact({
        targetDescription: Utils.nonEmptyStringPropValidator,
        date: PropTypes.instanceOf(Date)
      }).isRequired).isRequired
    }),
    phases: PropTypes.arrayOf(PropTypes.exact({
      objectives: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      pointsNumber: PropTypes.number.isRequired,
      range: Utils.nonEmptyStringPropValidator,
      labelOverride: PropTypes.string.isRequired,
      additionalInfo: PropTypes.string.isRequired
    }).isRequired).isRequired
  };

  updateDimensions = (event) => {
    var $component,
      $chart;

    if (event.matches) {
      $component = $('.student-progress-print-body');

      /**
       * @note @hack We need to do this hack because when user decreases page width by changing print margins on a large
       * value in one drag, a paper is resized improperly. [aLuk 15.Feb.18]
       */
      $chart = $('.chart-block');
      $chart.css('width', '1px');
      var width = $component.width();
      var height = $component.height();
      $chart.css('width', '');

      /**
       * @note If user trigger browser print mode by clicking "Print chart" link in the page header, component's
       * `render()` method is not called after `setState()`. This is because React doesn't guarantee to flush `render()`
       * synchronously right after `setState()`. In case of browser print preview, that means that `render()` method is
       * never called. We need to use here experimental `flushSync()` API introduced in React 16.
       * See for more details [aLuk 13.Jan.18]
       * https://github.com/facebook/react/issues/11876
       */
      ReactDOM.flushSync(() => {
        this.setState({
          width: width,
          height: height
        });
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 980, // US Letter paper size
      height: 625
    };
  }

  componentDidMount() {
    /**
     * @note We need to use this workaround to handle page resize in Google Chrome Print preview because of this bug in
     * Google Chrome [aLuk 8.Jan.18]
     * https://bugs.chromium.org/p/chromium/issues/detail?id=697233
     *
     * @note `updateDimensions()` callback isn't removed properly in `componentWillUnmount()` hook if we use
     * `window.matchMedia('print').removeListener()` directly. So, we need to cache `window.matchMedia('print')` in
     * React component instance and use the cached version in `componentWillUnmount()`. [aLuk 20.Jan.18]
     */
    this.matchMedia = window.matchMedia('print');
    this.matchMedia.addListener(this.updateDimensions);
  }

  componentWillUnmount() {
    this.matchMedia.removeListener(this.updateDimensions);
  }

  render() {
    var lastChartPoint = _.last(this.props.chartData.info),
      completedAtMonth,
      completedAtYear,
      schoolYear,
      schoolYearNode;

    if (lastChartPoint) {
      completedAtMonth = lastChartPoint.x.getMonth();
      completedAtYear = lastChartPoint.x.getFullYear();
      schoolYear = completedAtMonth < 6 ? `${completedAtYear - 1}-${completedAtYear}`
        : `${completedAtYear}-${completedAtYear + 1}`;
    }

    schoolYearNode = schoolYear ? (
      <span>
        <span className="header-node-title">School Year: </span>
        <span>{schoolYear}</span>
      </span>
    ) : null;

    var chartData = Object.assign({}, this.props.chartData, {
      info: this.props.chartData.info.map((info) => {
        return info != null ? _.omit(info, 'x') : info;
      })
    });

    var chartPhases = this.props.phases.map((phase) => {
      return _.omit(phase, 'objectives', 'range');
    });

    var totalPointsNum = 0;
    var phaseListPhases = this.props.phases.map((phase, index) => {
      var notes = this.props.chartData.info.slice(totalPointsNum, totalPointsNum + phase.pointsNumber * 2 - 1)
        .filter(info => info != null && info.note != null)
        .map(info => {
          return {
            type: 'note',
            noteBody: info.note.body,
            noteDate: info.note.date,
            creatorInitials: Utils.getInitials(info.creatorName)
          }
        });
      totalPointsNum += phase.pointsNumber * 2;

      var phaseItems = phase.objectives.map((objective) => {
        return {
          type: 'objective',
          objective: objective
        }
      });
      if (phase.additionalInfo) {
        phaseItems.push({
          type: 'additionalInfo',
          additionalInfo: phase.additionalInfo
        })
      }
      phaseItems.push(...notes);

      var newPhase = _.omit(phase, 'objectives');
      return Object.assign(newPhase, {
        phaseItems: phaseItems,
        titleLetter: Utils.numberToLetter(index)
      });
    });

    return (
      <div id="student-progress-print">
        <Chart chartType={this.props.chartType}
          student={this.props.student}
          schoolYearNode={schoolYearNode}
          chartData={chartData}
          phases={chartPhases}
          pageSize={this.state} />
        {
          !_.isEmpty(chartData.labels) ? (
            <PhaseList chartType={this.props.chartType}
              student={this.props.student}
              schoolYearNode={schoolYearNode}
              phases={phaseListPhases}
              pageSize={{
                width: this.state.width,
                height: this.state.height - 75
              }} />
          ) : null
        }
        {
          this.props.targets != null ? (
            <TargetsList student={this.props.student}
              schoolYearNode={schoolYearNode}
              targets={this.props.targets}
              pageSize={this.state} />
          ) : null
        }
      </div>
    );
  }
}
