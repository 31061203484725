import * as React from 'react'
import { AuthenticationService } from './AuthenticationService'

export interface ISigninCallbackProps {
  onSuccess?: () => void
  onError?: (err: any) => void
}

export class SigninCallback extends React.Component<ISigninCallbackProps> {
  public componentDidMount() {
    const { onSuccess, onError } = this.props
    const authService = AuthenticationService.getInstance();

    authService.userManager.signinRedirectCallback()
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(err => {
        if (onError) {
          onError(err)
        }
      })
  }
  public render() {
    return null;
  }
}
