import axios from 'axios';
import _ from 'lodash';

class Utils {
  static handleAxiosError(componentName, error, removeAccessTokenFunc, removeAuthTokensFunc) {
    return new Promise((resolve, reject) => {
      if (!axios.isCancel(error)) {
        if (!_.isEmpty(error.response)) {
          var responseData = error.response.data;

          if (_.isObject(error.config) && error.config.responseType === 'blob') {
            /**
             * @note This solution was inspired by the following code. [aLuk 3.Sep.18]
             * https://github.com/axios/axios/issues/815#issuecomment-340972365
             */
            new Promise((fileReaderResolve, fileReaderReject) => {
              var reader = new FileReader();
              reader.addEventListener('abort', fileReaderReject, { once: true });
              reader.addEventListener('error', fileReaderReject, { once: true });
              reader.addEventListener('loadend', () => {
                fileReaderResolve(reader.result);
              }, { once: true });
              reader.readAsText(responseData)
            }).then((responseText) => {
              try {
                responseData = JSON.parse(responseText);
                this.handleUnauthorizedError(componentName, error.message, responseData, removeAccessTokenFunc,
                  removeAuthTokensFunc, resolve, reject);
              } catch (jsonParseError) {
                Utils.printErrorMessage(componentName, error.message);
                resolve(error.message);
              }
            }).catch(() => {
              Utils.printErrorMessage(componentName, error.message);
              resolve(error.message);
            });

            return;
          }

          return this.handleUnauthorizedError(componentName, error.message, responseData, removeAccessTokenFunc,
            removeAuthTokensFunc, resolve, reject);
        }

        Utils.printErrorMessage(componentName, error.message);
        resolve(error.message);
        return;
      }

      reject(error.message);
    });
  }

  static handleUnauthorizedError(componentName, errorMessage, responseData, removeAccessTokenFunc, removeAuthTokensFunc,
    resolve, reject) {
    if (!_.isEmpty(responseData) && _.isObject(responseData)) {
      /**
       * @note Condition that checks equivalence of the value of `data.detail` to the "The token has expired" string
       * is important because malicious user can manually truncate value of "authToken" key in local storage to
       * something like `eyJhcHBfaWQiOiJpby5y` and in this case server will return the error with 401 status and 611
       * code, but different `detail` message. If we don't check that `detail` string actually report about token
       * expiration, the app falls to infinite redirect cycle. [aLuk 5.Feb.18]
       */
      if (responseData.status === 401 && responseData.code === 611 && responseData.detail === 'The token has expired'
        && removeAccessTokenFunc) {
        reject(responseData.title);
        removeAccessTokenFunc();
        return;
      }

      if (responseData.status === 401 && responseData.code === 611 && removeAuthTokensFunc) {
        reject(responseData.title);
        removeAuthTokensFunc();
        return;
      }

      Utils.printErrorMessage(componentName, errorMessage, responseData);
      resolve(responseData.title);
      return;
    }

    Utils.printErrorMessage(componentName, errorMessage);
    resolve(errorMessage);
  }

  static printErrorMessage(componentName, errorMessage, details) {
    details = details || '';
    console.error(`Server connection error in "${componentName}" component:`, errorMessage, details);
  }

  static axios(endpoint, options) {
    var headers = {};
    if (endpoint.headers != null) {
      Object.assign(headers, endpoint.headers);
    } else {
      Object.assign(headers, {
        'Content-Type': 'application/json',
      });
    }
    if (endpoint.isSecure && options.accessToken != null) {
      Object.assign(headers, {
        'Authorization': options.accessToken
      });
    }

    var urlParams = options.urlParams;

    var requestBody = {
      url: !_.isEmpty(urlParams) ? Utils.replaceUrlParams(endpoint.url, urlParams) : endpoint.url,
      method: endpoint.method,
      params: Object.assign({}, endpoint.params, options.params),
      headers: headers,
      responseType: endpoint.responseType != null ? endpoint.responseType : 'json'
    };
    Object.assign(requestBody, _.pick(options, 'data', 'cancelToken'));

    return axios.request(requestBody);
  }

  static replaceUrlParams(url, urlParams, params) {
    if (urlParams != null) {
      _.forEach(urlParams, (value, key) => {
        url = url.replace(`:${key}`, value);
      });
    }

    if (params != null) {
      _.forEach(params, (value, key) => {
        url += `&${key}=${value}`;
      });
    }

    return url;
  }

  static getInitials(name) {
    name = name || '';
    name = name.trim();

    var initials = '',
      splitName;

    if (name !== '') {
      splitName = name.split(' ').filter((word) => word !== '');
      initials = splitName[0][0].toUpperCase();

      if (splitName.length > 1) {
        initials += _.last(splitName)[0].toUpperCase();
      }
    }

    return initials;
  }

  static numberToLetter(number) {
    var alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
      'U', 'V', 'W', 'X', 'Y', 'Z'];

    var quotient = Math.floor(number / 26);
    var remainder = number % 26;
    return quotient > 0 ? Utils.numberToLetter(quotient - 1) + alphabet[remainder] : alphabet[remainder];
  }

  static secondsToTimeString(seconds) {
    var hours,
      minutes,
      result = '';

    seconds = Math.round(seconds);

    if (seconds >= 3600) {
      hours = Math.floor(seconds / 3600);
      seconds = seconds % 3600;
      result += hours + 'h';
    }
    if (seconds >= 60) {
      minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      result += ` ${minutes}m`;
    }
    if (seconds !== 0) {
      result += ` ${seconds}s`;
    }

    return result;
  }

  static nonEmptyStringPropValidator(props, propName, componentName) {
    if (props[propName] == null || !_.isString(props[propName]) || props[propName].trim() === '') {
      return new Error(
        'Invalid prop `' + propName + '` supplied to `' + componentName
        + '`. The prop should be a non-empty string. Validation failed.'
      );
    }
  }

  static sortListPanelItems(orders, field) {
    var previousOrderItemIndex = _.findIndex(orders, ['field', field]);
    var newOrders = _.cloneDeep(orders);
    var previousOrderItem = newOrders.splice(previousOrderItemIndex, 1);
    newOrders.unshift({
      field: field,
      order: previousOrderItem[0].order === 'asc' ? 'desc' : 'asc'
    });

    return {
      orders: newOrders
    };
  }

  static orderItems(list, orders) {
    return _.orderBy(list, orders.map((orderItem) => orderItem.field), orders.map((orderItem) => orderItem.order));
  }

  static getSortingIconName(orders, fieldName) {
    return _.find(orders, ['field', fieldName]).order === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down';
  }

  static programTypeImage(programType) {
    switch (programType) {
      case "DTT":
        return "/images/dtt.svg";

      case "TASK_ANALYSIS":
        return "/images/taskAnalysis.svg";

      case "INTERVAL":
        return "/images/interval.svg";

      case "DURATION":
        return "/images/duration.svg";

      default:
        return "/images/frequency.svg";
    }
  }


}

export default Utils;
