import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';

import Error from '../Error/Error';
import StudentsListView from './StudentsListView';

import ReduxHelpers from '../../Utils/ReduxHelpers';
import Constants from '../../Utils/Constants';
import Utils from '../../Utils/Utils';
import { Endpoints } from '../../Utils/Endpoints';
import { AuthenticationService } from '../Security/AuthenticationService';

class StudentsList extends React.PureComponent {
  static propTypes = Constants.secureComponentPropTypes;

  constructor(props) {
    super(props);
    this.state = {
      studentList: null,
      inProgress: true,
      reportGenerationInProgress: null,
      errorMessage: null
    };
    this.cancelTokenSource = axios.CancelToken.source();
  }

  componentDidMount() {
    Utils.axios(Endpoints().students, {
      accessToken: localStorage['accessToken'],
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      var studentList = response.data.students.map((student) => {
        return Object.assign(_.omit(student, 'avatarIndex'), {
          avatar: student.avatarIndex != null ? response.data.avatars[student.avatarIndex] : null
        })
      });

      this.setState({
        studentList: studentList,
        inProgress: false,
        errorMessage: null
      });
    }).catch((error) => {
      Utils.handleAxiosError('StudentsList', error, this.props.removeAccessToken, this.props.removeAuthTokens)
        .then((errorMessage) => {
          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            if (localStorage['authProvider'] === 'stitch') {
              const authService = AuthenticationService.getInstance();
              authService.logout();
            }
            localStorage.removeItem('authProvider');
            window.location.href = '/'
          }
          this.setState({
            inProgress: false,
            errorMessage: errorMessage
          })
        }).catch(() => {
        });
    });
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel();
  }

  downloadFile = (studentId, endpointInfo, event) => {
    if (event != null) {
      event.preventDefault();
      event.stopPropagation();
    }

    Utils.axios(endpointInfo.endpoint, {
      urlParams: {
        studentId: studentId
      },
      params: {
        startDate: endpointInfo.startDate,
        endDate: endpointInfo.endDate
      },
      accessToken: localStorage['accessToken'],
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      var fileNameString = response.headers['content-disposition'].split(';')[1];
      var equalityIndex = fileNameString.indexOf('=');
      var fileName = fileNameString.substring(equalityIndex + 1);

      download(response.data, fileName, response.headers['content-type']);

      this.setState({
        reportGenerationInProgress: null
      });
    }).catch((error) => {
      Utils.handleAxiosError('StudentsList', error, this.props.removeAccessToken, this.props.removeAuthTokens)
        .then(() => {
          this.setState({
            reportGenerationInProgress: null
          })
        }).catch(() => {
        });
    });

    this.setState({
      reportGenerationInProgress: {
        type: endpointInfo.endpoint === Endpoints().downloadDocx ? 'docx' : 'xlsx',
        studentId: studentId
      }
    });
  };

  render() {
    return this.state.inProgress ? (
      <CircularProgress className="progress-bar"
        color="primary"
        size={80} />
    ) : this.state.errorMessage ? (
      <Error message={this.state.errorMessage} />
    ) : (
          <StudentsListView studentList={this.state.studentList}
            downloadFile={this.downloadFile}
            reportGenerationInProgress={this.state.reportGenerationInProgress} />
        );
  }
}

export default connect(ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(StudentsList);
