import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';

import Error from '../Error/Error';
import StudentProgressView from './StudentProgressView';

import ReduxHelpers from '../../Utils/ReduxHelpers';
import Constants from '../../Utils/Constants';
import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';
import StudentProgressUtils from './Utils';

class StudentProgress extends React.PureComponent {
  static propTypes = Constants.secureComponentPropTypes;

  constructor(props) {
    super(props);
    this.state = {
      student: {},
      programs: [],
      chartData: {
        labels: [],
        series: [[]],
        info: []
      },
      phases: [],
      isFullUpdate: false,
      inProgress: true,
      errorMessage: null
    };
  }

  componentDidMount() {
    this.init(true);
  }

  componentDidUpdate(prevProps) {
    /**
     * @todo Try to use `withRouter()` from `react-router` to reduce this checking. [aLuk 26.Oct.17]
     */
    if (prevProps.match.params.programId !== this.props.match.params.programId) {
      this.cancelTokenSource.cancel();
      this.init(true);
    }

    this.setState({
      isFullUpdate: false
    });
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel();
  }

  init(isFullUpdate) {
    this.cancelTokenSource = axios.CancelToken.source();

    Utils.axios(Endpoints().studentProgress, {
      urlParams: {
        programId: this.props.match.params.programId,
        studentId: this.props.match.params.studentId
      },
      accessToken: localStorage['accessToken'],
      cancelToken: this.cancelTokenSource.token
    }).then((response) => {
      var labels = [],
        series = [],
        info = [],
        phases = [],
        state,
        isBehaviorChart = response.data.chartType === 'frequency' || response.data.chartType === 'duration',
        steps = {};

      if (isBehaviorChart) {
        steps = StudentProgressUtils.getBehaviorSteps(response.data.phases, response.data.chartType);
      }

      response.data.phases.forEach((phase) => {
        var firstXLabel = StudentProgressUtils.getPrintChartLabel(new Date(_.first(phase.points).x));
        var lastXLabel = StudentProgressUtils.getPrintChartLabel(new Date(_.last(phase.points).x));
        var range = firstXLabel !== lastXLabel ? `${firstXLabel}-${lastXLabel}` : firstXLabel;

        phases.push({
          id: phase.id,
          pointsNumber: phase.points.length,
          range: range,
          labelOverride: phase.labelOverride,
          additionalInfo: phase.additionalInfo
        });

        if (isBehaviorChart) {
          Object.assign(_.last(phases), {
            info: phase.info,
            target: phase.target
          });
        } else {
          Object.assign(_.last(phases), {
            title: phase.title,
            trialsNum: phase.trialsNum,
            criterionRules: phase.criterionRules,
            procedure: phase.procedure,
            prompt: phase.prompt,
            reinforcementSchedule: phase.reinforcementSchedule,
            reinforcementType: phase.reinforcementType
          });

          if (response.data.chartType === 'task analysis') {
            Object.assign(_.last(phases), {
              tasks: phase.tasks
            });
          } else {
            Object.assign(_.last(phases), {
              discriminativeStimulus: phase.discriminativeStimulus,
              lto: phase.lto,
              currentTargets: phase.currentTargets,
              masteredTargets: phase.masteredTargets,
              futureTargets: phase.futureTargets,
              isLastPhase: phase.isLastPhase
            });
          }
        }

        phase.points.forEach((point, index) => {
          labels.push(new Date(point.x));
          series.push(adjustY(point.y, response.data.chartType, steps.divisor));

          if (index < phase.points.length - 1) {
            labels.push(null);
            series.push((adjustY(phase.points[index + 1].y, response.data.chartType, steps.divisor)
              + adjustY(point.y, response.data.chartType, steps.divisor)) / 2);
          } else {
            labels.push('');
            series.push(null);
          }

          var infoItem = {
            x: new Date(point.x),
            y: point.y,
            note: point.note != null ? {
              body: point.note.body,
              date: new Date(point.note.date)
            } : null
          };

          if (isBehaviorChart) {
            Object.assign(infoItem, {
              id: phase.id,
              type: 'standard'
            });
          } else {
            Object.assign(infoItem, {
              id: point.id,
              type: point.type,
              creatorName: point.creatorName,
              recType: point.recType,
            });
          }
          info.push(infoItem);
          info.push(null);
        })
      });
      labels.pop();
      series.pop();
      info.pop();

      state = {
        chartType: response.data.chartType,
        student: response.data.student,
        programs: response.data.programs,
        chartData: {
          labels: labels,
          series: [series],
          info: info
        },
        phases: phases,
        inProgress: false,
        errorMessage: null
      };

      if (isBehaviorChart) {
        state.chartData.stepBarName = steps.stepBarName;
      }

      if (response.data.targets != null) {
        response.data.targets.targetsList = response.data.targets.targetsList.map((target) => {
          if (target.date != null) {
            return Object.assign({}, target, {
              date: new Date(target.date)
            });
          }

          return target;
        });
        Object.assign(state, {
          targets: response.data.targets
        });
      }

      if (isFullUpdate) {
        Object.assign(state, {
          isFullUpdate: true
        });
      } else {
        Object.assign(state, {
          isFullUpdate: false
        });
      }

      this.setState(state);
    }).catch((error) => {
      Utils.handleAxiosError('StudentProgress', error, this.props.removeAccessToken, this.props.removeAuthTokens)
        .then((errorMessage) => {
          this.setState({
            inProgress: false,
            errorMessage: errorMessage
          });
        }).catch(() => {
        });
    });
  }

  render() {
    return this.state.inProgress ? (
      <CircularProgress className="progress-bar"
        color="primary"
        size={80} />
    ) : this.state.errorMessage ? (
      <Error message={this.state.errorMessage} />
    ) : (
          <StudentProgressView programId={this.props.match.params.programId}
            chartType={this.state.chartType}
            student={this.state.student}
            programs={this.state.programs}
            chartData={this.state.chartData}
            targets={this.state.targets}
            phases={this.state.phases}
            isFullUpdate={this.state.isFullUpdate}
            initFunc={this.init.bind(this)} />
        );
  }
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function adjustY(y, chartType, divisor) {
  if (chartType === 'duration' || chartType === 'frequency') {
    return y / divisor;
  }

  return y.numerator / y.denominator * 100;
}

export default connect(ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(StudentProgress);