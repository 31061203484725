import React from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    FormGroup,
    TextField
} from '@material-ui/core';
import _ from 'lodash';

import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';
import moment from 'moment';

export default class StudentsListDialog extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            useDefaultDateRange: true,
            invalidDateRange: false,
            startDate: this.defaultDateRange().startDate,
            endDate: this.defaultDateRange().endDate,
        }
    }

    changeDefaultDateRangeUsage = (event) => {
        this.setState({
            useDefaultDateRange: !event.target.checked,
            startDate: this.defaultDateRange().startDate,
            endDate: this.defaultDateRange().endDate
        });
    };

    defaultDateRange() {
        return getDefaultDateRange(this.props.studentId ? null : moment().subtract(1, 'weeks').toDate())
    }

    handleDateChange = (stateName, event) => {
        if (!_.isEmpty(event.target.value)) {
            var year = parseInt(event.target.value.slice(0, 4), 10);
            var month = parseInt(event.target.value.slice(5, 7), 10) - 1;
            var day = parseInt(event.target.value.slice(8, 10), 10);
            var date = new Date(year, month, day);

            this.setState((prevState) => {
                var newState = stateName === 'startDate' ? {
                    startDate: date,
                    endDate: prevState.endDate
                } : {
                        startDate: prevState.startDate,
                        endDate: date
                    };

                if (newState.startDate < newState.endDate) {
                    return Object.assign(newState, {
                        invalidDateRange: false
                    });
                }

                return Object.assign(newState, {
                    invalidDateRange: true
                });
            });
        }
    };

    handleDownloadButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState((prevState) => {
            var dateRange = prevState.useDefaultDateRange ? this.defaultDateRange() : {
                startDate: prevState.startDate,
                endDate: prevState.endDate
            };

            this.props.downloadFile(this.props.studentId, {
                endpoint: this.props.studentId ? Endpoints().downloadDocx : Endpoints().downloadSessionXlsx,
                startDate: dateRange.startDate.valueOf(),
                endDate: dateRange.endDate.valueOf()
            });

            this.props.onClose()
        });
    };

    render() {
        const defaultDateRange = this.defaultDateRange();
        const dateRangeErrorText = this.state.invalidDateRange ? 'End date must be greater than start date' : undefined;

        let fileParams = (this.state.useDefaultDateRange ? _.mapValues(this.defaultDateRange(), (date) => date.valueOf()) : {
            startDate: this.state.startDate.valueOf(),
            endDate: this.state.endDate.valueOf()
        });
        if (fileParams != null) {
            Object.assign(fileParams, {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
        }

        const fileUrl = Utils.replaceUrlParams(this.props.studentId ? Endpoints().downloadDocx.url : Endpoints().downloadSessionXlsx.url, {
            studentId: this.props.studentId
        }, fileParams);

        return (
            <Dialog open={this.props.open}
                PaperProps={{
                    id: 'students-list__date-range-dialog'
                }}
                onClose={this.props.onClose}>
                <FormGroup className="checkbox-row"
                    row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!this.state.useDefaultDateRange}
                                onChange={this.changeDefaultDateRangeUsage}
                                color="primary" />
                        }
                        label={`Set a date range (default is ${
                            defaultDateRange.startDate.toLocaleDateString('en-US')
                            } - ${defaultDateRange.endDate.toLocaleDateString('en-US')})`} />
                </FormGroup>
                <div className={this.state.useDefaultDateRange ? 'date-range-row hidden' : 'date-range-row'}>
                    <TextField className="date-range-input"
                        type="date"
                        error={this.state.invalidDateRange}
                        label="Start date"
                        helperText={dateRangeErrorText}
                        defaultValue={getISODateString(this.state.startDate)}
                        onChange={this.handleDateChange.bind(this, 'startDate')} />
                    <TextField className="date-range-input"
                        type="date"
                        error={this.state.invalidDateRange}
                        label="End date"
                        helperText={dateRangeErrorText}
                        defaultValue={getISODateString(this.state.endDate)}
                        onChange={this.handleDateChange.bind(this, 'endDate')} />
                </div>
                <Button href={fileUrl}
                    variant="contained"
                    color="primary"
                    onClick={this.handleDownloadButtonClick}>Download Report</Button>
            </Dialog>
        )
    }
}

function getDefaultDateRange(startDateOverride) {
    var now = new Date();
    var startDate = new Date(now.getFullYear(), now.getMonth());
    startDate.setMonth(now.getMonth() - 2);

    return {
        startDate: startDateOverride || startDate,
        endDate: now
    }
}

function getISODateString(date) {
    var month = date.getMonth() + 1;
    var monthString = month < 10 ? `0${month}` : month;
    var day = date.getDate();
    var dayString = day < 10 ? `0${day}` : day;

    return `${date.getFullYear()}-${monthString}-${dayString}`;
}