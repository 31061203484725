import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import {
    Input, Card, Row, Col, Form, FormItem, Button, Alert,
} from '@raudabaugh/thread-ui';
import ReduxHelpers from '../../Utils/ReduxHelpers';
import { IAuthDispatchProps, IAuthStateProps } from '../../Utils/ReduxProps'
import Utils from '../../Utils/Utils';
import { Endpoints } from '../../Utils/Endpoints';

interface IChangePasswordPropsBase {
}

interface IChangePasswordState {
    newPassword: string
    confirmPassword: string
    result: string
};

type IChangePasswordProps = IChangePasswordPropsBase & IAuthStateProps & IAuthDispatchProps;

class ChangePassword extends React.PureComponent<IChangePasswordProps, IChangePasswordState> {
    private cancelTokenSource: CancelTokenSource;

    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            result: ''
        };
        this.handleSave = this.handleSave.bind(this);
        this.cancelTokenSource = axios.CancelToken.source();
    }

    private handleInputChange(field: string, event) {
        this.setState({ [field]: event.target.value } as IChangePasswordState);
    }

    private isValid(): boolean {
        return this.state.newPassword === this.state.confirmPassword
            && this.state.newPassword.length >= 8;
    }

    private handleSave() {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = axios.CancelToken.source();

        const body = {
            new_password: this.state.newPassword
        };
        let request = Utils.axios(Endpoints().changePassword, {
            data: body,
            accessToken: localStorage['accessToken'],
            cancelToken: this.cancelTokenSource.token
        });

        request.then((response) => {
            if (response.data.success) {
                this.setState({ result: 'success' });
            } else {
                this.setState({ result: 'error' });
            }
        }).catch(() => {
            this.setState({ result: 'error' });
        });
    }

    render() {
        const disableSave = !this.isValid();
        return (
            <div className="content">
                {this.state.result === 'success' ? <Alert message="Password changed successfully." type="success" /> : null}
                {this.state.result === 'error' ? <Alert message="The password could not be changed. Please chat with support for assistance." type="error" /> : null}
                <div className="stand-alone-title">Change Password</div>
                <Card padding={'4px'} color='default' >
                    <Row>
                        <Col margin='8px' span={6}>
                            <Form>
                                <FormItem label='New password'>
                                    <Row>
                                        <Input placeholder='' type='password' value={this.state.newPassword} onChange={this.handleInputChange.bind(this, 'newPassword')} />
                                    </Row>
                                </FormItem>
                                <FormItem label='Confirm new password'>
                                    <Row>
                                        <Input placeholder='' type='password' value={this.state.confirmPassword} onChange={this.handleInputChange.bind(this, 'confirmPassword')} />
                                    </Row>
                                </FormItem>
                                <Button
                                    type="primary"
                                    onClick={this.handleSave}
                                    disabled={disableSave}
                                >
                                    Save
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default connect<IAuthStateProps, IAuthDispatchProps, IChangePasswordProps>(
    ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(ChangePassword);