import { Card, Grid, Icon, ISelectParam, Menu, MenuItem, Row } from '@raudabaugh/thread-ui';
import * as React from 'react';
import { SideMenuHeader } from './SideMenuHeader';
import { AuthenticationService } from '../Security/AuthenticationService';
import { RoleEnum } from './RoleEnum';

interface ISideMenuProps {
  onCloseMenu: () => void;
  onSelect: (option: SideMenuOptionEnum | string) => void;
}

interface ISideMenuState {
  currentUserRole: RoleEnum,
  orgId: string
}

export enum SideMenuOptionEnum {
  DATA_COLLECTION = "/data/:studentId",
  SESSION_NOTES = "/notes/:studentId",
  PEOPLE_MANAGEMENT = "/people",
}

export class SideMenu extends React.Component<ISideMenuProps, ISideMenuState> {
  constructor(props: ISideMenuProps) {
    super(props);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.state = { currentUserRole: RoleEnum.INSTRUCTOR, orgId: '1234' };
  }

  public componentWillMount() {
    const authService = AuthenticationService.getInstance();
    authService.getUser().then((user) => {
      if (user) {
        this.setState({ currentUserRole: user.profile!.role!, orgId: user.profile!.default_group! })
      }
    });
  }

  public render() {
    return (
      <Grid color="navbar" variation={9} type="flex" height="100vh" onClick={this.props.onCloseMenu} >
        <Row>
          <Card width="100%" padding="0" margin="0" bordered={false}>
            <SideMenuHeader />
          </Card>
        </Row>
        <Row>
          <Menu
            mode="inline"
            theme="dark"
            onSelect={this.handleMenuSelect}
          >
            <MenuItem key="1"><Icon type="fa-bullseye" margin="0 8px 0 0" width="16px" />Data Collection</MenuItem>
            {this.shouldShowSessionNotes() && <MenuItem key="2"><Icon type="fa-file-contract" margin="0 8px 0 0" width="16px" />Session Notes</MenuItem>}
            {this.state && this.state.currentUserRole === RoleEnum.ADMIN &&
              <MenuItem key="3"><Icon type="fa-address-book" margin="0 8px 0 0" width="16px" />Students &amp; Staff</MenuItem>}
            <MenuItem key="4"><Icon type="fa-chalkboard-teacher" margin="0 8px 0 0" width="16px" />Program Portal</MenuItem>
          </Menu>
        </Row>
      </Grid>
    )
  }

  private shouldShowSessionNotes() {
    const orgsList = process.env.REACT_APP_SHOW_MOBILE_SESSION_NOTES!.split(" ");
    if (orgsList.indexOf(this.state.orgId) >= 0) {
      return true
    } else {
      return false
    }
  }

  private handleMenuSelect(param: ISelectParam) {
    if (this.props.onSelect) {
      switch (param.key) {
        case "1": // Data Collection
          this.props.onSelect(process.env.REACT_APP_THREADWEB_URL! + '/data');
          break;
        case "2": // Session Notes
          this.props.onSelect(process.env.REACT_APP_THREADWEB_URL! + '/notes');
          break;
        case "3": // People Management
          this.props.onSelect(process.env.REACT_APP_THREADWEB_URL! + '/people');
          break;
        case "4": // Program Portal
          this.props.onSelect('/');
          break;
      }
    }
  }
}


