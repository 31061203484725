import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const propTypes = {
  className: PropTypes.string
};

SummaryIcon.propTypes = propTypes;

export function SummaryIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 1024" {...props}>
      <path
        d="M651.636 275.2h-446.836c-25.6 0-25.6 38.4 0 38.4h446.836c25.6 0 25.6-38.4 0-38.4zM819.2 428.8h-614.4c-25.6
        0-25.6 38.4 0 38.4h614.4c25.6 0 25.6-38.4 0-38.4zM819.2 582.4h-614.4c-25.6 0-25.6 38.4 0 38.4h614.4c25.6 0
        25.6-38.4 0-38.4z"/>
    </SvgIcon>
  );
}

SDIcon.propTypes = propTypes;

export function SDIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 1024" {...props}>
      <path
        d="M424.728 313.812c67.483 67.483 67.483 176.894 0 244.376-7.498 7.498-19.655 7.498-27.153 0s-7.498-19.655
        0-27.153c52.486-52.486 52.486-137.584 0-190.070-7.498-7.498-7.498-19.655 0-27.153s19.655-7.498 27.153 0zM605.747
        132.793c167.457 167.457 167.457 438.958 0 606.415-7.498 7.498-19.655 7.498-27.153 0s-7.498-19.655
        0-27.153c152.461-152.461 152.461-399.648 0-552.109-7.498-7.498-7.498-19.655 0-27.153s19.655-7.498 27.153
        0zM515.237 223.302c117.47 117.47 117.47 307.926 0 425.395-7.498 7.498-19.655 7.498-27.153 0s-7.498-19.655
        0-27.153c102.474-102.474 102.474-268.616 0-371.090-7.498-7.498-7.498-19.655 0-27.153s19.655-7.498 27.153
        0zM336.481 404.322c-17.495-17.495-45.861-17.495-63.357 0s-17.495 45.861 0 63.357c17.495 17.495 45.861 17.495
        63.357 0s17.495-45.861 0-63.357z"/>
    </SvgIcon>
  );
}

TargetIcon.propTypes = propTypes;

export function TargetIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 1024" {...props}>
      <path
        d="M512 147.2c-166.127 0-300.8 134.673-300.8 300.8s134.673 300.8 300.8 300.8c166.127 0 300.8-134.673
        300.8-300.8s-134.673-300.8-300.8-300.8zM512 185.6c144.92 0 262.4 117.48 262.4 262.4s-117.48 262.4-262.4
        262.4c-144.92 0-262.4-117.48-262.4-262.4s117.48-262.4 262.4-262.4zM512 275.2c-95.435 0-172.8 77.365-172.8
        172.8s77.365 172.8 172.8 172.8c95.435 0 172.8-77.365 172.8-172.8s-77.365-172.8-172.8-172.8zM512 313.6c74.227 0
        134.4 60.173 134.4 134.4s-60.173 134.4-134.4 134.4c-74.227 0-134.4-60.173-134.4-134.4s60.173-134.4
        134.4-134.4zM513.6 401.6c-24.742 0-44.8 20.058-44.8 44.8s20.058 44.8 44.8 44.8c24.742 0 44.8-20.058
        44.8-44.8s-20.058-44.8-44.8-44.8z"/>
    </SvgIcon>
  );
}

PromptIcon.propTypes = propTypes;

export function PromptIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 900"
             style={{transform: 'scaleY(-1)'}} {...props}>
      <path
        d="M554.508 257.575l56.686-56.696c9.438-9.439 27.973-8.902 36.702-0.171 8.879 8.881 10.293 22.677 2.085
        33.394l-143.050 143.076c-7.497 7.498-7.497 19.655 0 27.153s19.651 7.498 27.148 0l140.265-140.29 0.181 0.181
        40.755-40.767c9.792-9.434 26.157-9.273 35.634 0.206 9.829 9.831 10.109 26.14 0.769 35.797l-40.961 40.968 0.181
        0.181-140.265 140.29c-7.497 7.498-7.497 19.655 0 27.153s19.651 7.498 27.148 0l142.79-142.815c10.502-7.851
        25.644-6.18 34.116 2.293 9.098 9.099 10.041 25.587-0.625 36.255l-195.846 195.88c-39.395 39.402-18.041 92.817
        37.746 92.345l70.664-0.599c14.142-0.12 24.877 10.557 24.76 24.425-0.121 14.319-11.935 26.038-26.591
        26.162l-2.874 0.032c-5.585 0.061-5.585 0.061-26.804 0.286-23.867 0.249-47.654 0.486-70.575 0.699-12.175
        0.112-12.175 0.112-19.548 0.179-7.567 0.067-7.567 0.067-20.294 0.177-40.45 0.343-72.81 0.556-96.258 0.631-34.96
        0.111-46.638-5.182-78.187-35.351l-92.583-92.599c-46.556-46.564-45.941-123.23 1.62-170.8 11.943-11.869
        19.104-18.99 31.839-31.658 24.979-24.85 48.622-48.4 70.041-69.765 57.852-57.709 91.657-91.672 100.526-101.037
        7.566-7.99 26.615-8.208 34.92 0.098 2.628 2.628 4.551 5.679 5.739 9.055l6.37 18.111c-1.185 0.748-2.307
        1.639-3.339 2.672l-99.543 99.561c-7.497 7.498-7.497 19.655 0 27.153s19.651 7.498 27.148
        0l99.543-99.561c0.726-0.726 1.382-1.496 1.968-2.302zM543.771 175.732c-23.56-23.564-67.489-23.063-89.943
        0.649-8.484 8.959-42.361 42.995-99.763 100.254-21.406 21.353-45.038 44.89-70.005 69.73-12.729 12.663-19.888
        19.782-31.866 31.686-62.504 62.515-63.313 163.493-1.666 225.151l92.889 92.899c38.636 36.954 59.572 46.444
        105.151 46.298 23.528-0.075 55.95-0.289 96.465-0.632 12.732-0.11 12.732-0.11 20.3-0.177 7.391-0.067 7.391-0.067
        19.569-0.179 22.938-0.214 46.74-0.45 70.624-0.7 21.222-0.225 21.222-0.225 26.821-0.286l2.83-0.031c35.485-0.301
        64.308-28.892 64.607-64.235 0.3-35.378-27.926-63.45-63.477-63.149l-70.664 0.599c-21.019
        0.178-25.652-11.411-10.272-26.793l195.846-195.88c26.397-26.402 24.067-67.114
        0.625-90.561-6.374-6.375-14.065-11.224-22.414-14.379 23.861-25.082
        23.051-65.069-1.364-89.488-23.972-23.977-63.573-24.654-88.751-1.33-3.167-7.936-7.961-15.315-14.268-21.623-23.382-23.386-66.204-24.628-90.998
        0.171l-25.467 25.472c-3.098-8.808-8.124-16.78-14.808-23.465z"/>
    </SvgIcon>
  );
}

MasteryIcon.propTypes = propTypes;

export function MasteryIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 1024" {...props}>
      <path
        d="M651.838 554.353l108.044 211.265c4.533 8.864 2.961 11.516-6.599 11.662l-162.032 2.48c-16.638
        0.255-38.543-12.713-45.979-26.732l-15.348-31.585 71.621-134.872c0.704-1.325 1.236-2.695 1.606-4.085 17.402-7.45
        33.731-16.926 48.688-28.133zM681.491 528c40.397-42.435 65.194-99.859 65.194-163.074
        0-130.641-105.906-236.547-236.547-236.547s-236.547 105.906-236.547 236.547c0 61.384 23.381 117.307
        61.723 159.348l-110.89 216.83c-19.464 38.059 2.032 74.041 44.814 74.696l145.614 2.229c35.5 0.543 76.199-23.387
        92.636-54.34l0.146-0.275 3.4 6.991c14.491 27.364 49.73 48.226 80.804 47.75l162.032-2.48c38.126-0.584
        57.625-33.469 40.199-67.542l-112.579-220.134zM364.574 551.396c40.138 31.378 90.667 50.078 145.564 50.078 14.329
        0 28.36-1.274 41.987-3.715l-78.551 147.921c-9.661 18.192-37.018 34.278-58.133
        33.955l-145.614-2.229c-14.284-0.219-17.754-6.027-11.213-18.816l105.961-207.194zM510.138 166.779c109.434 0
        198.147 88.714 198.147 198.147s-88.714 198.147-198.147 198.147c-109.434
        0-198.147-88.714-198.147-198.147s88.714-198.147 198.147-198.147z"/>
    </SvgIcon>
  );
}

FixedRatioIcon.propTypes = propTypes;

export function FixedRatioIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 900"
             style={{transform: 'scaleY(-1)'}} {...props}>
      <path
        d="M38.4 755.616v-564.031c0-35.073 28.481-63.584 63.529-63.584h820.143c34.87 0 63.529 28.605 63.529
        63.584v564.031c0 35.073-28.481 63.584-63.529 63.584h-820.143c-34.87 0-63.529-28.605-63.529-63.584zM0 755.616c0
        56.324 45.988 101.984 101.929 101.984h820.143c56.294 0 101.929-45.742
        101.929-101.984v-564.031c0-56.324-45.988-101.984-101.929-101.984h-820.143c-56.294 0-101.929 45.742-101.929
        101.984v564.031zM170.982 308.787v310.886c0 24.218 19.149 43.366 43.366 43.366h220.774c21.965 0 39.424-17.459
        39.424-39.424s-17.459-39.424-39.424-39.424h-177.408v-83.917h152.064c21.965 0 39.424-17.459
        39.424-39.424s-17.459-39.424-39.424-39.424h-152.064v-112.64c0-24.218-19.149-43.366-43.366-43.366s-43.366
        19.149-43.366 43.366zM540.442 308.787v310.886c0 24.218 19.149 43.366 43.366 43.366h136.858c50.125 0
        88.986-14.080 114.893-39.987 21.965-21.965 33.792-52.941
        33.792-90.112v-1.126c0-61.952-30.976-100.25-78.848-119.962l60.262-73.216c8.448-10.701 14.080-19.712
        14.080-33.229 0-24.218-20.275-39.987-41.114-39.987-19.712 0-32.666 9.574-42.803 22.528l-84.48
        107.008h-69.274v-86.17c0-24.218-19.149-43.366-43.366-43.366s-43.366 19.149-43.366 43.366zM627.174
        471.501h87.859c42.24 0 66.458 22.528 66.458 55.757v1.126c0 37.171-25.907 56.32-68.147 56.32h-86.17v-113.203z"/>
    </SvgIcon>
  );
}

export function ChartIcon(props) {
  return (
    <SvgIcon viewBox="0 0 1024 900"
             style={{transform: 'scaleY(-1)'}} {...props}>
      <path
        d="M128 38.4c-56.554 0-102.4 45.846-102.4 102.4s45.846 102.4 102.4 102.4c56.554 0 102.4-45.846
        102.4-102.4s-45.846-102.4-102.4-102.4zM303.77 435.2c12.244-7.069 16.439-22.726
        9.37-34.97l-76.8-133.022c-7.069-12.244-22.726-16.439-34.97-9.37s-16.439 22.726-9.37 34.97l76.8 133.022c7.069
        12.244 22.726 16.439 34.97 9.37zM384 448c-56.554 0-102.4 45.846-102.4 102.4s45.846 102.4 102.4 102.4c56.554 0
        102.4-45.846 102.4-102.4s-45.846-102.4-102.4-102.4zM530.102 466.102c9.997-9.997 9.997-26.206
        0-36.204s-26.206-9.997-36.204 0c-9.997 9.997-9.997 26.206 0 36.204s26.206 9.997 36.204 0zM640 243.2c-56.554
        0-102.4 45.846-102.4 102.4s45.846 102.4 102.4 102.4c56.554 0 102.4-45.846
        102.4-102.4s-45.846-102.4-102.4-102.4zM815.77 640c12.244-7.069 16.439-22.726
        9.37-34.97l-76.8-133.022c-7.069-12.244-22.726-16.439-34.97-9.37s-16.439 22.726-9.37 34.97l76.8 133.022c7.069
        12.244 22.726 16.439 34.97 9.37zM896 652.8c-56.554 0-102.4 45.846-102.4 102.4s45.846 102.4 102.4 102.4c56.554 0
        102.4-45.846 102.4-102.4s-45.846-102.4-102.4-102.4z"/>
    </SvgIcon>
  );
}