import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Modal,
    Menu,
    MenuItem,
} from '@raudabaugh/thread-ui';
import { ProgramTypeEnum } from './../ApiTypes'
import BehaviorForm from '../EditProgramDialog/BehaviorForm';
import SkillsForm from '../EditProgramDialog/SkillsForm';
import ReduxHelpers from '../../Utils/ReduxHelpers';
import { IAuthDispatchProps, IAuthStateProps } from '../../Utils/ReduxProps'

interface defaultCreateState {
    createBehavior: boolean,
    saveAlertOpen: boolean,
    cancelAlertOpen: boolean,
    selectOpen: boolean,
    formSelected: ProgramTypeEnum,
};

interface ICreateDialogPropsBase {
    onClose: any
    open: boolean
    updateParent: (boolean: boolean) => void
    values: any
    studentName: string,
    studentId: string,
}
type ICreateDialogProps = ICreateDialogPropsBase & IAuthStateProps & IAuthDispatchProps;

class CreateDialog extends React.PureComponent<ICreateDialogProps, defaultCreateState> {

    constructor(props) {
        super(props);
        this.state = {
            saveAlertOpen: false,
            selectOpen: false,
            cancelAlertOpen: false,
            createBehavior: true,
            formSelected: ProgramTypeEnum.NONE,
        };
        this.handleSelectClose = this.handleSelectClose.bind(this);
        this.handleSelectOpen = this.handleSelectOpen.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.closeForm = this.closeForm.bind(this);
    }

    handleSelectClose() {
        this.setState({ selectOpen: false })
    }

    handleSelectOpen() {
        this.setState({ selectOpen: true })
    }

    handleSelectChange(value) {
        this.setState({ formSelected: value.key }, this.props.onClose)
    }

    closeForm() {
        this.setState({ formSelected: ProgramTypeEnum.NONE })
    }

    render() {
        const isBehavior: boolean = this.state.formSelected == ProgramTypeEnum.DURATION || this.state.formSelected == ProgramTypeEnum.FREQUENCY;
        const displayName = (program) => {
            if (program === ProgramTypeEnum.DTT) {
                return 'Discrete Trial';
            } else {
                return program.toLocaleLowerCase().replace(/_/g, " ").replace(/\b./g, function (m) { return m.toUpperCase(); });
            }
        }
        return (
            <>
                <Modal
                    visible={this.props.open}
                    onCancel={this.props.onClose}
                    footer={null}
                    title={'Select Program Type'}
                >
                    <Menu
                        onSelect={this.handleSelectChange}
                        selectedKeys={[this.state.formSelected]}
                    >
                        {Object.keys(ProgramTypeEnum).map((program) =>
                            program !== ProgramTypeEnum.NONE && program !== ProgramTypeEnum.NOT_YET_IMPLEMENTED ?
                                <MenuItem key={program}>{displayName(program)}</MenuItem> :
                                null
                        )}
                    </Menu>
                </Modal>
                {isBehavior === true ?
                    <BehaviorForm
                        removeAccessToken={this.props.removeAccessToken}
                        studentName={this.props.studentName}
                        removeAuthTokens={this.props.removeAuthTokens}
                        accessToken={this.props.accessToken}
                        userName={this.props.userName}
                        userAvatar={this.props.userAvatar}
                        userId={this.props.userId}
                        updateParent={this.props.updateParent}
                        open={this.state.formSelected !== ProgramTypeEnum.NONE}
                        onClose={this.closeForm}
                        formType={this.state.formSelected}
                        studentId={this.props.studentId}
                        isLastPhase={false}
                    />
                    :
                    <SkillsForm
                        removeAccessToken={this.props.removeAccessToken}
                        studentName={this.props.studentName}
                        removeAuthTokens={this.props.removeAuthTokens}
                        accessToken={this.props.accessToken}
                        userName={this.props.userName}
                        userAvatar={this.props.userAvatar}
                        userId={this.props.userId}
                        updateParent={this.props.updateParent}
                        open={this.state.formSelected !== ProgramTypeEnum.NONE}
                        onClose={this.closeForm}
                        formType={this.state.formSelected}
                        studentId={this.props.studentId}
                        isLastPhase={false}
                    />
                }
            </>
        );
    }
}

export default connect<IAuthStateProps, IAuthDispatchProps, ICreateDialogProps>(
    ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(CreateDialog);