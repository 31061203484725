import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
  Menu,
  MenuItem
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import Utils from '../../../Utils/Utils';

import './NavProgramsList.css';

class NavProgramsList extends React.PureComponent {
  static propTypes = {
    studentId: PropTypes.string.isRequired,
    programList: PropTypes.arrayOf(PropTypes.exact({
      id: Utils.nonEmptyStringPropValidator,
      title: PropTypes.string.isRequired
    }).isRequired).isRequired,
    program: PropTypes.exact({
      id: Utils.nonEmptyStringPropValidator,
      title: PropTypes.string.isRequired
    }).isRequired
  };

  openMenu = () => {
    this.setState({
      open: true
    })
  };

  closeMenu = () => {
    this.setState({
      open: false
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    var options = this.props.programList.map((program) => {
      return (
        <MenuItem className="list-item"
          key={program.id}
          selected={this.props.program.id === program.id}
          onClick={this.closeMenu}>
          <Link to={`/student/${this.props.studentId}/program/${program.id}`}
            className="link ellipsis-overflow">{program.title}</Link>
        </MenuItem>
      )
    });

    return (
      <div id="nav-programs-list"
        ref={(el) => {
          this.menuElement = el;
        }}>
        <Button color="primary"
          onClick={this.openMenu}>
          <KeyboardArrowDownIcon className="icon" />
          <span className="menu-button-label ellipsis-overflow">{this.props.program.title}</span>
        </Button>
        <Menu id="nav-programs-list-menu"
          anchorEl={this.menuElement}
          open={this.state.open}
          onClose={this.closeMenu}>
          {options}
        </Menu>
      </div>
    )
  }
}

export default NavProgramsList;
