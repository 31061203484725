import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './ThreadUI.css';

import App from './Components/App/App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
unregister()
