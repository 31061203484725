import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ThreadUtils from 'thread-web-utils';

import {
  Paper
} from '@material-ui/core';

import Utils from '../../../Utils/Utils';
import { PhaseReinforcementScheduleEnumLabel } from '../../ApiTypes';

import './ChartPhasePopup.css';

ChartPhasePopup.propTypes = {
  position: PropTypes.exact({
    display: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trialsNum: PropTypes.number,
    criterionRules: PropTypes.arrayOf(PropTypes.exact({
      pointsAnalyzed: PropTypes.number.isRequired,
      minPercentage: PropTypes.number.isRequired
    }).isRequired).isRequired,
    procedure: PropTypes.string.isRequired,
    prompt: PropTypes.string.isRequired,
    reinforcementSchedule: PropTypes.string.isRequired,
    reinforcementType: PropTypes.string.isRequired,
    currentTargets: PropTypes.arrayOf(Utils.nonEmptyStringPropValidator),
    masteredTargets: PropTypes.arrayOf(Utils.nonEmptyStringPropValidator),
    futureTargets: PropTypes.arrayOf(Utils.nonEmptyStringPropValidator),
    isLastPhase: PropTypes.bool
  }).isRequired,
  openDialog: PropTypes.func.isRequired
};

export default function ChartPhasePopup(props) {
  var masteryCriterion = props.data.criterionRules.map((criterion, index) => {
    return (
      <li key={index}>{criterion.pointsAnalyzed} score of {Math.round(criterion.minPercentage * 100)}%{
        index !== props.data.criterionRules.length - 1 ? ' or' : null
      }</li>
    );
  });

  var currentTargetsString = ThreadUtils.getCommaSeparatedString(props.data.currentTargets);
  var masteredTargetsString = ThreadUtils.getCommaSeparatedString(props.data.masteredTargets);
  var futureTargetsString = ThreadUtils.getCommaSeparatedString(props.data.futureTargets);

  return (
    <Paper id="progress-chart-phase-popup"
      style={props.position}
      onClick={onPopupClick}>
      {
        isNotEmptyOrWhitespace(props.data.title) ? (
          <div className="phase-popup__header">{props.data.title}</div>
        ) : null
      }

      <div className="item">
        <strong>Number of Trials</strong><br />
        <span>{props.data.trialsNum != null ? props.data.trialsNum : 'varies'}</span>
      </div>

      {
        !_.isEmpty(masteryCriterion) ? (
          <div className="item">
            <strong>Criterion for Mastery</strong><br />
            <ul>
              {masteryCriterion}
            </ul>
          </div>
        ) : null
      }

      {
        isNotEmptyOrWhitespace(props.data.procedure) ? (
          <div className="item">
            <strong>Procedure</strong><br />
            <span>{props.data.procedure}</span>
          </div>
        ) : null
      }

      {
        isNotEmptyOrWhitespace(props.data.prompt) ? (
          <div className="item">
            <strong>Prompt</strong><br />
            <span>{props.data.prompt}</span>
          </div>
        ) : null
      }

      {
        isNotEmptyOrWhitespace(props.data.reinforcementSchedule) ? (
          <div className="item">
            <strong>Reinforcement Schedule</strong><br />
            <span>{`${PhaseReinforcementScheduleEnumLabel(props.data.reinforcementSchedule.split(' ')[0])} ${props.data.reinforcementSchedule.split(' ')[1] || ''}`}</span>
          </div>
        ) : null
      }

      {
        isNotEmptyOrWhitespace(props.data.reinforcementType) ? (
          <div className="item">
            <strong>Reinforcement Type</strong><br />
            <span>{props.data.reinforcementType}</span>
          </div>
        ) : null
      }

      {
        !_.isEmpty(currentTargetsString) ? (
          <div className="item">
            <strong>Current Targets ({props.data.currentTargets.length})</strong><br />
            <span>{currentTargetsString}</span>
          </div>
        ) : null
      }

      {
        !_.isEmpty(masteredTargetsString) ? (
          <div className="item">
            <strong>All Mastered Targets ({props.data.masteredTargets.length})</strong><br />
            <span>{masteredTargetsString}</span>
          </div>
        ) : null
      }

      {
        !_.isEmpty(futureTargetsString) ? (
          <div className="item">
            <strong>All Future Targets ({props.data.futureTargets.length})</strong><br />
            <span>{futureTargetsString}</span>
          </div>
        ) : null
      }

      {/* {
        props.data.isLastPhase ? (
          <Button color="primary"
            fullWidth
            variant="raised"
            onClick={props.openDialog}>Edit</Button>
        ) : null
      } */}
    </Paper>
  );
}

// ====================================================================================================================
// PRIVATE FUNCTIONS
// ====================================================================================================================
function onPopupClick(event) {
  event.stopPropagation();
}

function isNotEmptyOrWhitespace(value) {
  return !_.isEmpty(value) && value.trim() !== '';
}
