import PropTypes from 'prop-types';
import Utils from './Utils';

export const PhasePropType = PropTypes.exact({
  id: Utils.nonEmptyStringPropValidator,
  procedure: PropTypes.string.isRequired,
  discriminativeStimulus: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  currentTargets: PropTypes.arrayOf(PropTypes.exact({
    id: Utils.nonEmptyStringPropValidator,
    description: Utils.nonEmptyStringPropValidator,
    type: PropTypes.string
  })),
  masteredTargets: PropTypes.arrayOf(PropTypes.exact({
    id: Utils.nonEmptyStringPropValidator,
    description: Utils.nonEmptyStringPropValidator,
    type: PropTypes.string
  }).isRequired).isRequired,
  futureTargets: PropTypes.arrayOf(PropTypes.exact({
    id: Utils.nonEmptyStringPropValidator,
    description: Utils.nonEmptyStringPropValidator,
    type: PropTypes.string
  }).isRequired).isRequired,
  criterionRules: PropTypes.arrayOf(PropTypes.exact({
    pointsAnalyzed: PropTypes.number.isRequired,
    minPercentage: PropTypes.number.isRequired
  }).isRequired).isRequired,
  reinforcementType: PropTypes.string.isRequired,
  lto: PropTypes.string.isRequired,
  sto: PropTypes.string,
  additionalInfo: PropTypes.string,
  reinforcementSchedule: PropTypes.string,
  criterionForMastery: PropTypes.string,
  target: PropTypes.string,
  shortName: PropTypes.string,
  definition: PropTypes.string,
  defaultResult: PropTypes.string,
  variableITCount: PropTypes.bool,
  totalITCount: PropTypes.number,
  studentName: PropTypes.string,
  errorless: PropTypes.bool,
  baseline: PropTypes.string,
  collectionFrequency: PropTypes.string,
  programName: PropTypes.string,
  sampleTime: PropTypes.number,
  reinforcement: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.exact({
    taskDescription: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
  })),
})