import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { Button, Card, Col, Form, FormItem, Heading, Image, Input, InputTextArea, Row, Tooltip, Modal } from '@raudabaugh/thread-ui';
import { ProgramTypeEnum } from './../ApiTypes'
import ReduxHelpers from '../../Utils/ReduxHelpers';
import { IAuthDispatchProps, IAuthStateProps } from '../../Utils/ReduxProps'
import { Endpoints } from '../../Utils/Endpoints';
import Utils from '../../Utils/Utils';

interface IBehaviorFormPropsBase {
    updateParent: (boolean) => void,
    formType: ProgramTypeEnum,
    onClose: () => void
    open: boolean
    isLastPhase: boolean
    studentId: string,
    studentName: string,
    phaseId?: string
    programId?: string
    values?: any
}
type IBehaviorFormProps = IBehaviorFormPropsBase & IAuthStateProps & IAuthDispatchProps;

interface IBehaviorFormState {
    values: any
    saveAlertOpen: boolean
    cancelAlertOpen: boolean
    editName: boolean
}

class BehaviorForm extends React.PureComponent<IBehaviorFormProps, IBehaviorFormState> {
    private fieldRefs: any
    private cancelTokenSource: CancelTokenSource;
    private inputRef: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.state = {
            saveAlertOpen: false,
            editName: false,
            cancelAlertOpen: false,
            values: this.buildStateFromProps(props)
        };
        this.fieldRefs = {
            programName: null,
            procedure: null,
            discriminativeStimulus: null,
            targetsList: {},
            reinforcement: null,
            longTermObjective: null,
            additionalInfo: null,
            baseline: null,
            collectionFrequency: null,
            criterionForMastery: null,
            definition: null,
            id: null,
            shortName: null,
            target: null
        }
        this.cancelTokenSource = axios.CancelToken.source();
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.closeSaveAlert = this.closeSaveAlert.bind(this);
        this.closeCancelAlert = this.closeCancelAlert.bind(this);
        this.closeCancelAlertAndDialog = this.closeCancelAlertAndDialog.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.save = this.save.bind(this);
        this.handleClickOutsideField = this.handleClickOutsideField.bind(this);
        this.inputRef = React.createRef();
    }

    public inputFocus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }
    }

    public componentWillReceiveProps(nextProps: IBehaviorFormProps) {
        this.setState({ values: this.buildStateFromProps(nextProps) });
    }

    public render() {
        const disableSave = !this.isValid();
        return (
            <Modal
                visible={this.props.open}
                onCancel={this.handleDialogClose}
                title={
                    <Card variation={1} onClick={() => this.setState({ editName: !this.state.editName }, this.inputFocus)} bordered={false} padding='0'>
                        <Row type='flex' justify='space-between'>
                            <Col grow={0} margin="0 16px 0 0">
                                <Row type='flex' justify='center'>
                                    <Image src={Utils.programTypeImage(this.props.formType)} />
                                </Row>
                                <Row type='flex' justify='center'>
                                    <Heading level={6}>{this.formatProgramType()}</Heading>
                                </Row>
                            </Col>
                            <Col grow={1}>
                                <Row type='flex' justify='center'>
                                    <Heading level={3} margin="8px 0 0 0">{this.props.studentName}</Heading>
                                </Row>
                                <Row type='flex' justify='center'>
                                    {this.state.editName ?
                                        <Input innerRef={this.inputRef} placeholder='' onPressEnter={() => this.setState({ editName: !this.state.editName })} onChange={this.handleInputChange.bind(this, 'programName')} value={this.state.values.programName} />
                                        :
                                        <Heading level={1} color={this.state.values.programName === '' ? 'error' : undefined} margin='-8px 0 0 0'>{this.state.values.programName === '' ? 'Enter Program Name Here' : this.state.values.programName}</Heading>}
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                }
                footer={
                    <Row type='flex' justify='space-between'>
                        <Col margin='8px 0'>
                            <Button
                                type="danger"
                                onClick={this.handleCancel}
                            >
                                Cancel
                                </Button>
                        </Col>
                        <Col margin='8px 0'>
                            <Button
                                type="primary"
                                onClick={this.handleSave}
                                disabled={disableSave}
                            >
                                Save
                                </Button>
                        </Col>
                    </Row>
                }
                width={'80%'}
                afterClose={() => this.setState({ editName: false })}>
                <Card onClick={() => this.setState({ editName: false })}>
                    <Row>
                        <Col margin='8px'>
                            <Form>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Short Name</Heading>
                                    <Input value={this.state.values.shortName} placeholder="" onChange={this.handleInputChange.bind(this, 'shortName')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Current Target</Heading>
                                    <Input value={this.state.values.target} placeholder="" onChange={this.handleInputChange.bind(this, 'target')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Baseline Date/Score</Heading>
                                    <Input value={this.state.values.baseline} placeholder="" onChange={this.handleInputChange.bind(this, 'baseline')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Frequency of Data Collection</Heading>
                                    <Input value={this.state.values.collectionFrequency} placeholder="" onChange={this.handleInputChange.bind(this, 'collectionFrequency')} />
                                </FormItem>
                                <FormItem required={true}>

                                    <Tooltip title={this.validateDefinition()}>
                                        <Heading level={5} weight="medium" margin="6px" color={this.validateDefinition() === "" ? undefined : 'error'}>Definition: </Heading>
                                    </Tooltip>
                                    <InputTextArea value={this.state.values.definition} onChange={this.handleInputChange.bind(this, 'definition')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Procedure</Heading>
                                    <InputTextArea value={this.state.values.procedure} onChange={this.handleInputChange.bind(this, 'procedure')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Criterion for Mastery</Heading>
                                    <InputTextArea value={this.state.values.criterionForMastery} onChange={this.handleInputChange.bind(this, 'criterionForMastery')} />
                                </FormItem>
                                <FormItem>
                                    <Heading level={5} weight="medium" margin='4px 0 0 0'>Additional Condition Information</Heading>
                                    <InputTextArea value={this.state.values.additionalInfo} onChange={this.handleInputChange.bind(this, 'additionalInfo')} />
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                </Card>
                <Modal
                    visible={this.state.saveAlertOpen}
                    onCancel={this.closeSaveAlert}
                    title={'Would you like to create a Phase Change Line?'}
                    footer={
                        <>
                            <Button type="primary"
                                onClick={this.save.bind(this, true)}>Yes</Button>
                            <Button type="primary"
                                onClick={this.save.bind(this, false)}>No</Button>
                            <Button type="ghost"
                                onClick={this.closeSaveAlert}>Cancel</Button>
                        </>
                    }
                >
                </Modal>
                <Modal visible={this.state.cancelAlertOpen}
                    onCancel={this.closeCancelAlert}
                    title={'Discard all changes and close Edit Program dialog?'}
                    footer={<>
                        <Button type="primary"
                            onClick={this.closeCancelAlertAndDialog}>Yes</Button>
                        <Button type="ghost"
                            onClick={this.closeCancelAlert}>No</Button>
                    </>}>
                </Modal>
            </Modal>
        );
    }

    private formatProgramType() {
        return this.props.formType.substring(0, 1) + this.props.formType.substring(1).toLowerCase();
    }

    private isValid() {
        if (this.state.values.programName === '') {
            // Name is required
            return false;
        }
        if (this.validateDefinition() !== '') {
            // Definition is required
            return false;
        }
        return true;
    }

    private validateDefinition() {
        if (this.state.values.definition === '') {
            return "Must have a definition";
        }
        return "";
    }

    private buildStateFromProps(props: IBehaviorFormProps) {
        if (props.phaseId) {
            return {
                additionalInfo: this.props.values.additionalInfo,
                baseline: this.props.values.baseline,
                collectionFrequency: this.props.values.collectionFrequency,
                criterionForMastery: this.props.values.criterionForMastery,
                definition: this.props.values.definition,
                id: this.props.values.id,
                shortName: this.props.values.shortName,
                procedure: this.props.values.procedure,
                target: this.props.values.target,
                programName: this.props.values.programName,
            };
        }
        else {
            return {
                additionalInfo: '',
                baseline: '',
                collectionFrequency: '',
                criterionForMastery: '',
                definition: '',
                id: '',
                shortName: '',
                procedure: '',
                target: '',
                programName: '',
            };
        }
    }

    private handleDialogClose(event) {
        this.stopPropagation(event);
        this.handleClickOutsideField(undefined);

        this.setState({
            cancelAlertOpen: true
        });
    };

    private handleCancel() {
        this.setState({
            cancelAlertOpen: true
        });
    };

    private handleSave() {
        if (this.props.isLastPhase) {
            this.setState({
                saveAlertOpen: true
            });
        } else {
            this.save(this.props.phaseId === undefined, undefined);
        }
    };

    private closeSaveAlert(event) {
        this.stopPropagation(event);

        this.setState({
            saveAlertOpen: false
        });
    };

    private closeCancelAlert(event) {
        this.stopPropagation(event);

        this.setState({
            cancelAlertOpen: false
        });
    };

    private closeCancelAlertAndDialog(event) {
        this.stopPropagation(event);

        this.closeCancelAlert(undefined);
        this.props.onClose();
    };

    private handleInputChange(field, event) {
        var value = event.target.value;

        this.setState((prevState) => {
            var values,
                targetsList;

            if (_.isObject(field)) {
                targetsList = _.cloneDeep(prevState.values.targetsList);
                targetsList[field.index].description = value;

                values = Object.assign({}, prevState.values, {
                    targetsList: targetsList
                })
            } else {
                values = Object.assign({}, prevState.values, {
                    [field]: value
                });
            }

            return {
                values: values
            };
        });
    }

    private save(shouldCreateNewPhase, event) {
        this.stopPropagation(event);

        this.cancelTokenSource.cancel();
        this.cancelTokenSource = axios.CancelToken.source();

        var body = {
            additionalInfo: this.state.values.additionalInfo || null,
            baseline: this.state.values.baseline || null,
            collectionFrequency: this.state.values.collectionFrequency || null,
            criterionForMastery: this.state.values.criterionForMastery || null,
            definition: this.state.values.definition || null,
            shortName: this.state.values.shortName || null,
            procedure: this.state.values.procedure || null,
            target: this.state.values.target || null,
            programName: this.state.values.programName || null,
            studentId: this.props.studentId || null,
            type: this.props.formType || null,
        };
        var request = shouldCreateNewPhase ? Utils.axios(Endpoints().createPhase, {
            data: body,
            urlParams: {
                programId: this.props.programId,
                studentId: this.props.studentId
            },
            accessToken: localStorage['accessToken'],
            cancelToken: this.cancelTokenSource.token
        }) : Utils.axios(Endpoints().updateBehavior, {
            data: body,
            urlParams: {
                behaviorPhaseId: this.props.phaseId,
                studentId: this.props.studentId
            },
            accessToken: localStorage['accessToken'],
            cancelToken: this.cancelTokenSource.token
        });

        request.then((response) => {
            if (response.data.success) {
                this.props.updateParent(false);
            }
        }).catch((error) => {
            Utils.handleAxiosError('EditBehaviorDialog', error, this.props.removeAccessToken,
                this.props.removeAuthTokens).catch(() => {
                });
        });

        this.closeSaveAlert(undefined);
        this.props.onClose();
    }

    private handleClickOutsideField(event) {
        this.stopPropagation(event);

        this.setState((prevState) => {
            var values = _.mapValues(_.omit(prevState.values, 'prompt', 'targetsList', 'criterion', 'criterionsList'))
            // (value) => value.trim() === '' ? '' : value);
            // var targetsList = prevState.values.targetsList.filter((target) => target.description.trim() !== '');

            Object.assign(values, {
                prompt: prevState.values.prompt,
                criterion: Object.assign({}, prevState.values.criterion),
                criterionsList: _.clone(prevState.values.criterionsList)
            });

            return {
                cancelAlertOpen: prevState.cancelAlertOpen,
                values: values
            }
        });
    };

    private stopPropagation(event) {
        if (event) {
            event.stopPropagation();
        }
    }
}

export default connect<IAuthStateProps, IAuthDispatchProps, IBehaviorFormProps>(
    ReduxHelpers.mapAuthStateToProps, ReduxHelpers.mapAuthDispatchToProps)(BehaviorForm);